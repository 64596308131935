import React, { Component } from "react";
import PageHeader from "../TextBlocks/PageHeader";
import ReactMarkdown from "react-markdown";
import AppMarkdown from "../../data/markdowns/ComingSoon.md";
import "../../stylesheets/TextBlocks/SectionText.css";
import "../../stylesheets/ContentPage.css";

class ComingSoon extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;

    return (
      <div>
        <div>
          <PageHeader text={"Coming Soon"} />
          <ReactMarkdown children={markdown} className="theSectionTextLeft" />
        </div>
      </div>
    );
  }
}

export default ComingSoon;
