/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Outlet } from "react-router-dom";

function ContentArea() {
  return (
    <div id="primary" className="ContentArea content-area">
      <main style={{ my: 30, mr: 30 }} id="main" className="site-main">
        <Outlet />
      </main>
    </div>
  );
}

export default ContentArea;
