import PostBox from "../../components/insightsComponents/PostBox";

function PostsList({ data }) {
  return (
    <div className="generate-columns-container">
      {data.map((post, idx) => {
        return <PostBox data={post} key={idx} />;
      })}
    </div>
  );
}

export default PostsList;
