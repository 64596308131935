/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Themed, Image } from "theme-ui";
import { Link } from "theme-ui";
import { useEffect } from "react";
import { useParams, Link as routerLink } from "react-router-dom";
import nest from "../../assets/pensionImages/nest.jpg";
import tpp from "../../assets/pensionImages/tpp.jpg";
import aviva from "../../assets/pensionImages/aviva.svg";
import standardLife from "../../assets/pensionImages/sl.jpg";
import now from "../../assets/pensionImages/now.jpg";
import legalAndGeneral from "../../assets/pensionImages/LegalGeneral.png";
import prudential from "../../assets/pensionImages/prudential.jpg";
import royalLondon from "../../assets/pensionImages/Royal-London.png";
import scottishWidows from "../../assets/pensionImages/scottish-widows.png";
import RatingGraphic from "../../components/workplacePensions/RatingGraphic";
import heroImage from "../../assets/workplacePensions/forest-growth.jpg";
import AdditionalGuidance from "../../components/workplacePensions/AdditionalGuidance";

export default function ThatPensionPage({ pensionData, PensionSelector }) {
  const { pension } = useParams();

  const vLookup = {
    now: now,
    nest: nest,
    tpp: tpp,
    aviva: aviva,
    standardLife: standardLife,
    legalAndGeneral: legalAndGeneral,
    prudential: prudential,
    royalLondon: royalLondon,
    scottishWidows: scottishWidows,
  };

  useEffect(() => {
    PensionSelector(pensionData[pension].pensionName);
  }, [PensionSelector, pensionData.pensionName, pension, pensionData]);

  return (
    <div
      sx={{
        position: "relative",
        top: 0,
      }}
    >
      {/* Hero */}
      <div sx={{ variant: "components.hero" }}>
        <div
          sx={{
            variant: "components.hero.background",
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div
            sx={{
              pt: ["16%", "none"],
              margin: "0 10%",
              textAlign: "center",
            }}
          >
            <h1
              sx={{
                variant: "components.hero.h1Secondary",
              }}
            >
              Your Workplace Pension
            </h1>
          </div>
        </div>
      </div>

      {/* Results */}
      <div sx={{ variant: "components.sectionPrimary" }}>
        <div
          sx={{
            variant: "components.overlayContainer",
          }}
        >
          <div
            sx={{
              variant: "components.plainCompactCard",
              display: "flex",
              alignItems: "center",
              borderLeft: "10px solid #01E9A3",
            }}
          >
            <Image
              src={vLookup[pensionData[pension].pensionName]}
              alt={pensionData[pension].pensionName}
              variant="provider"
            />

            <div
              sx={{
                ml: ["1%", "3%", "5%"],
              }}
            >
              <Themed.h2
                sx={{
                  fontWeight: "bold",
                  mb: 10,
                  mt: 0,
                }}
              >
                {pensionData[pension].title}
              </Themed.h2>

              <Themed.p
                sx={{
                  my: 0,
                }}
              >
                {pensionData[pension].headlineText}
              </Themed.p>
            </div>
          </div>

          <div
            sx={{
              variant: "components.plainCompactCard",
            }}
          >
            <Themed.h2>
              Transparency Rating:
              <span
                sx={{
                  display: "inline-block",
                  pl: "0.6em",
                }}
              >
                <RatingGraphic
                  rating={pensionData[pension].ratings.transparency}
                  ratingTypeText={"Transparency Rating:"}
                  ratingType="transparency"
                />
              </span>
            </Themed.h2>
            <Themed.p sx={{ mb: 4 }}>
              {pensionData[pension].rationaleTransparency}
            </Themed.p>

            <Themed.h2>
              Green Rating:
              <span
                sx={{
                  display: "inline-block",
                  pl: "0.6em",
                }}
              >
                <RatingGraphic
                  rating={pensionData[pension].ratings.esr}
                  ratingTypeText={"Green Rating:"}
                  ratingType="esr"
                />
              </span>
            </Themed.h2>
            <Themed.p sx={{ mb: 4 }}>
              {pensionData[pension].rationaleGreen}
            </Themed.p>
          </div>
          <div
            sx={{
              variant: "components.plainCompactCard",
            }}
          >
            <Themed.h4 sx={{ textAlign: "center" }}>What Next?</Themed.h4>
            <Themed.p>{pensionData.whatsNext1}</Themed.p>
            <Themed.p sx={{ textAlign: "center" }}>
              <Link
                as={routerLink}
                sx={{ variant: "links.ctaPrimary" }}
                to={`next-steps`}
                onClick={() => window.scrollTo({ top: 180 })}
              >
                Find out more
              </Link>
            </Themed.p>
          </div>
        </div>
      </div>
      <AdditionalGuidance />
    </div>
  );
}
