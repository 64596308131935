import React, { Component } from 'react';
import "../../stylesheets/TextBlocks/SectionHeader.css"

class SectionHeader extends Component {
    render() {
        return (
            <h2 className="sectionHeader" style={this.props.style}>
                {this.props.text}
            </h2>
        );
    }
}

export default SectionHeader;