import React, { Component } from 'react';
import "../../stylesheets/TextBlocks/PageHeader.css"

class PageHeader extends Component {
    render() {
        return (
            <h1 className="pageHeader" style={this.props.style}>
                {this.props.text}
            </h1>
        );
    }
}

export default PageHeader;
