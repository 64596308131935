import st_marys_axe_leaves from "../../assets/insights/posts-imgs/st_marys_axe_leaves.jpg";
import leaf_sunshine from "../../assets/insights/posts-imgs/leaf_sunshine.jpg";
import young_people_group from "../../assets/insights/posts-imgs/young_people_group.jpg";
import stock_chart_on_mobile from "../../assets/insights/posts-imgs/stock_chart_on_mobile.jpg";
import road_crossing from "../../assets/insights/posts-imgs/road_crossing.jpg";
import young_person_in_cafe from "../../assets/insights/posts-imgs/young_person_in_cafe.jpg";
import irongate_flowers from "../../assets/insights/posts-imgs/irongate_flowers.jpg";
import money_plant from "../../assets/insights/posts-imgs/money_plant.jpg";
import cloud_and_tower from "../../assets/insights/posts-imgs/cloud_and_tower.jpg";

//inner images //
import aviva_screenshot_1_1500x1089 from "../../assets/insights/posts-imgs/inner_imgs/aviva_screenshot_1_1500x1089.jpg";
import aviva_screenshot_2_1500x985 from "../../assets/insights/posts-imgs/inner_imgs/aviva_screenshot_2_1500x985.jpg";

export const images = [
  [st_marys_axe_leaves],
  [leaf_sunshine],
  [young_people_group],
  [stock_chart_on_mobile],
  [road_crossing, aviva_screenshot_1_1500x1089, aviva_screenshot_2_1500x985],
  [young_person_in_cafe],
  [irongate_flowers],
  [money_plant],
  [leaf_sunshine],
  [cloud_and_tower],
  [stock_chart_on_mobile],
  [young_people_group],
];
