import React from "react";

function PostDate({ date }) {
  return (
    <time
      className="entry-date published"
      dateTime={date && date.postDate}
      itemProp="datePublished"
    >
      {`${date ? date.toLocaleString("en-gb", { month: "long" }) : ""}
                ${date ? date.getDate() + "," : ""}
                ${date ? date.getFullYear() : ""}
                `}
    </time>
  );
}

export default PostDate;
