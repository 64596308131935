const pensionData = {
  tpp: {
    whatsNext1:
      "So please tell them you want you to see a faster transition to Net Zero in the Default plan or at least a distinctly greener ‘ethical’ option. Keep up to date to make sure they keep making the transition to net zero - and follow GO INVEST GREEN for other good things to do with your money.",
    whatsNext2: "Be an Activist - Demand Better.",
    overallRating: "IN THE PACK",
    pensionName: "tpp",
    headlineText: "The People’s Pension has set course for Net Zero but should pick up the pace.",
    rationaleGreen:
      "Their Climate Change Policy is clear and they are taking it seriously. However based on this we don’t think their Default Plan is yet adequately addressing the risks of climate change. They offer an Ethical Plan but it is not any greener than the Default and so there is no strong reason to switch.",
    rationaleTransparency: "They communicate quite well on where they are investing your money.",
    ratings: {
      transparency: "Green",
      esr: "Amber",
    },
    title: "The People's Pension",
  },
  scottishWidows: {
    whatsNext1: "Let them know you want a faster transition to a sustainable default plan aligned to Net Zero.",
    whatsNext2: "Be an Activist - Demand Better",
    overallRating: "IN THE PACK",
    pensionName: "scottishWidows",
    headlineText:
      "Scottish Widows are quickly moving towards a Net Zero approach to investing your money. Keep going guys!",
    rationaleGreen:
      "The new Responsible Investing framework at Scottish Widows sounds serious and the new Climate Transition Fund with Black Rock is a step in the right direction, But it will be a relatively small proportion of your money in the default plan. We would like to see Scottish Widows quickly transition the rest of the portfolio. You may be comfortable choosing your own investments but without a decent range of green or ethical funds, your best option is to stay in the default.",
    rationaleTransparency:
      "There is a lot of documentation but it takes a lot of digging to figure out where your money is invested.",
    ratings: {
      transparency: "Amber",
      esr: "Amber",
    },
    title: "Scottish Widows",
  },
  prudential: {
    whatsNext1: "We want to see a commitment to Net Zero implemented in the default investment approach.",
    whatsNext2: "Be an Activist - Demand Better",
    overallRating: "IN THE PACK",
    pensionName: "prudential",
    headlineText: "Time for the Prudential to play catch up with Pension leaders NEST, Aviva and L&G.",
    rationaleGreen:
      "We could not find any documentation on their approach to sustainable investing for UK customers. Based on the information we could find, the Default Plan is not managed sustainably and we would like to see them offer more green funds for those comfortable to manage their own investments.",
    rationaleTransparency:
      "Prudential lag behind in terms of accessible, good information on how they invest your money.",
    ratings: {
      transparency: "Red",
      esr: "Amber",
    },
    title: "Prudential",
  },
  nest: {
    whatsNext1:
      "So stay in your NEST but keep up to date to make sure they keep making the transition to net zero - and follow GO INVEST GREEN for other good things to do with your money.",
    whatsNext2: "You're Good - You now can find other ways to GO INVEST GREEN",
    overallRating: "ON THE PODIUM",
    pensionName: "nest",
    headlineText: "Your NEST pension is leading the way in becoming a Net Zero pension.",
    rationaleGreen:
      "They are also leaders in taking the risks of climate change seriously when it comes to managing your money. They are progressively decarbonising the Default Plan that nearly all their customers are in.",
    rationaleTransparency: "NEST are leaders in the quality of information they provide.",
    ratings: {
      transparency: "Green",
      esr: "Green",
    },
    title: "Nest",
  },
  royalLondon: {
    whatsNext1:
      "You may be comfortable to switch to a suitable multi-asset fund in the Royal London’s excellent Sustainable Fund range. But for most people, what you want is a Sustainable Default Plan",
    whatsNext2: "Become an Activist - Demand Better",
    overallRating: "IN THE PACK",
    pensionName: "royalLondon",
    headlineText: "It's time for their default plan to be as good as the Royal London Sustainability fund range.",
    rationaleGreen:
      "Based on what we can see, their default pension plan is not yet driven by a net zero investment strategy. This is a shame given their strong capability in green investing, as demonstrated by Royal London’s Sustainable Fund range.",
    rationaleTransparency:
      "They deserve kudos for the most easy to use and understand site of the big Pension Companies. However as with all of them, we would like to see information on specific holdings. ",
    ratings: {
      transparency: "Green",
      esr: "Amber",
    },
    title: "Royal London",
  },
  now: {
    whatsNext1:
      "Require that they implement a Net Zero based investment strategy to minimise your exposure to climate risks.",
    whatsNext2: "Be an Activist - Demand Better",
    overallRating: "AT THE BACK",
    pensionName: "now",
    headlineText: "It's time for NOW to work harder at investing for your FUTURE.",
    rationaleGreen:
      "NOW are also proud of their own risk based approach to investment management. They believe offering alternative investment options is not good for their members. That could be OK if they offered a default plan managed sustainably. Their use of green bonds is encouraging but you need all your retirement savings to be supporting the green economy.",
    rationaleTransparency:
      "They make a virtue out of simplicity. The information for members is well designed but very high level. There is very little specific information on where they invest and how they do so responsibly. ",
    ratings: {
      transparency: "Red",
      esr: "Amber",
    },
    title: "Now",
  },
  aviva: {
    whatsNext1:
      "If you are in their Auto Enrollment Default Plan, relax and watch them green your pension. But, keep following how they invest your money. If not, why not look at the other investment options you have in your plan",
    whatsNext2: "You're Good - You now can find other ways to GO INVEST GREEN",
    overallRating: "ON THE PODIUM",
    pensionName: "aviva",
    headlineText:
      "Aviva have a strong team in responsible investing and now they will be gearing up your pension for Net Zero.",
    rationaleGreen:
      "Along with L&G, Aviva is leading the mainstream pension providers in their commitment to move their auto enrollment Default Plans to Net Zero. Last year they launched the Stewardship Lifestyle Strategy; a mix of their own sustainable funds range. It's a good green option for those wanting a simple low maintenance choice. They also have a good range of self-select funds from other sustainable investment firms.",
    rationaleTransparency:
      "Aviva provides some good information but we would like to see something that clearly explains which options are available for each pension type, including what are the holdings.",
    ratings: {
      transparency: "Amber",
      esr: "Green",
    },
    title: "Aviva",
  },
  standardLife: {
    whatsNext1:
      "This pension may be as good as the other leading Pension firms - we just can’t tell based on what they are communicating.",
    whatsNext2: "Be an Activist - Demand Better",
    overallRating: "AT THE BACK",
    pensionName: "standardLife",
    headlineText: "Hopefully Standard Life are doing some good with your pension but it's really hard to tell.",
    rationaleGreen:
      "In the absence specific investment information, we are not confident that their Default plan is sufficiently sustainable for a Green rating. As we can only find six green funds in their Self Select range, it is not worth trying to go DIY with your Standard Life pension.",
    rationaleTransparency:
      "According to Standard Life’s documentation they support responsible investing and ESG, but it is very difficult to find anything specific on how Standard Life pensions are invested.",
    ratings: {
      transparency: "Red",
      esr: "Amber",
    },
    title: "Standard Life",
  },
  legalAndGeneral: {
    whatsNext1:
      "If you are in their Master Trust or another Defined Contribution pension, stay put, but keep following how they invest your money. If not, why not look at the other investment options you have in your plan",
    whatsNext2: "You're Good - You now can find other ways to GO INVEST GREEN",
    overallRating: "ON THE PODIUM",
    pensionName: "legalAndGeneral",
    headlineText:
      "L&G are leaders in actively engaging with companies. Now they are raising their game by pushing them to meet Net Zero. Or else.",
    rationaleGreen:
      "L&G are now leading the pack in terms of alignment with Net Zero. Their recent decision to implement their climate impact pledge across all their ‘defined contribution’ schemes (which probably includes you) is a major step forward and leverages their leadership in actively engaging with companies that are not doing good enough.",
    rationaleTransparency:
      "L&G produce good quality communications on investment options. They are also very good at explaining their climate impact pledge. However more info on actual holdings should be provided.",
    ratings: {
      transparency: "Green",
      esr: "Green",
    },
    title: "Legal & General",
  },
};

module.exports = { pensionData };
