import React from "react";

export default function PostImageHeader({ data }) {
  return (
    <div className="featured-image page-header-image-single">
      <img
        width="1200"
        height="600"
        src={data && data.imgUrls[0]}
        className="attachment-full size-full"
        alt=""
        loading="lazy"
        itemProp="image"
        srcSet={`${data && data.imgUrls[0]} 1200w, ${
          data && data.imgUrls[0]
        } 480w, ${data && data.imgUrls[0]} 768w`}
        sizes="(max-width: 1200px) 100vw, 1200px"
      />
    </div>
  );
}
