import React, { Component } from "react";
import "../../stylesheets/NSTable.css";
import SectionText from "../TextBlocks/SectionText";
import megaphone from "../../assets/gigImages/Other Icons/megaphone-hand-drawn-tool-outline.svg";
import envelope from "../../assets/gigImages/Other Icons/mail-envelope-back-hand-drawn-outline.svg";
import lightbulb from "../../assets/gigImages/Other Icons/idea-hand-drawn-symbol-of-a-side-head-with-a-lightbulb-inside.svg";

class NSTable extends Component {
  nextStepsInfo = [
    {
      src: megaphone,
      key: "megaphone",
      text:
        "Talk to someone in HR or Finance to find out who is the internal person that is responsible for your pension scheme",
      style: {
        width: "84px",
        height: "56px",
      },
    },
    {
      src: envelope,
      key: "envelope1",
      text:
        "Send an email to that individual. You can adapt the Contact Your Employer template email from Make My Money Matter",
      style: {
        width: "64px",
        height: "37px",
      },
    },
    {
      src: envelope,
      key: "envelope2",
      text:
        "Send an email directly to your pension provider. You are one of their customers! You can adapt the Contact Your Pension Fund template email from Make My Money Matter",
      style: {
        width: "64px",
        height: "37px",
      },
    },
    {
      src: lightbulb,
      key: "lightbulb",
      text:
        "Brainstorm with friends and colleagues on other ideas for how you can push for better pensions. A staff survey? Present to managers or a relevant committee? You know the organisation, the people and what will work. Have fun doing it!",
      style: {
        width: "53px",
        height: "50px",
      },
    },
  ];

  render() {
    return (
      <div>
        <div className="nextStepsBox">
          {this.nextStepsInfo.map((info) => {
            return (
              <div className="nextStepsRow" key={info.key}>
                <div className="nextStepsImageContainer">
                  <img
                    src={info.src}
                    className="nsImage"
                    alt="unneccessary"
                    style={info.style}
                  />
                </div>
                <div className="nextStepsTextContainer">
                  <SectionText text={info.text} style={{margin: "0px", width: "100%"}} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default NSTable;
