import { Link } from "react-router-dom";
import PostDate from "./PostDate";

function PostBox({ data }) {
  return (
    <div className="PostBox post type-post status-publish format-standard has-post-thumbnail hentry category-pensions generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 no-featured-image-padding">
      <div className="inside-article">
        <div className="featured-image post-image page-header-image-single ">
          <Link to={`blog/${data.id}`}>
            <img
              width="1200"
              height="600"
              src={data.imgUrls[0]}
              className="attachment-full size-full wp-post-image"
              alt={data.imgUrls[0]}
              loading="lazy"
              itemProp="image"
              srcSet={`${data.imgUrls[0]} 1200w, ${data.imgUrls[0]} 480w, ${data.imgUrls[0]} 768w`}
              sizes="(max-width: 1200px) 100vw, 1200px"
            />
          </Link>
        </div>
        <header className="entry-header">
          <h2 className="entry-title" itemProp="headline">
            <Link to={`blog/${data.id}`}>{data.title}</Link>
          </h2>
          <div
            className="entry-meta"
            style={{
              width: "100%",
              display: "flex",
              marginTop: 0,
              flexDirection: "column",
            }}
          >
            <span className="posted-on" style={{ wdith: "120px" }}>
              <PostDate date={data.postDate} />
            </span>
            <span className="byline">
              <span
                className="author vcard"
                itemProp="author"
                itemType="https://schema.org/Person"
                itemScope=""
              >
                by{" "}
                <a
                  className="url fn n"
                  href="https://insights.goinvestgreen.com/author/justin/"
                  title="View all posts by Justin Reynolds"
                  rel="author"
                  itemProp="url"
                >
                  <span className="author-name" itemProp="name">
                    {data.author}
                  </span>
                </a>
              </span>
            </span>
          </div>
        </header>
        <div className="entry-summary">
          <p>
            {`${data.summary} `}

            <Link
              title="Can I design my own pension?"
              className="read-more"
              to={`blog/${data.id}`}
              aria-label="More on Can I design my own pension?"
            >
              ... <span style={{ display: "block" }}>Read more</span>
            </Link>
            {/* </span> */}
          </p>
        </div>
        <footer
          className="entry-meta"
          style={{ display: "block", marginTop: "2em" }}
        >
          <span className="cat-links">
            <span className="gp-icon icon-categories">
              <svg
                viewBox="0 0 512 512"
                aria-hidden="true"
                role="img"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="1em"
                height="1em"
              >
                <path
                  d="M0 112c0-26.51 21.49-48 48-48h110.014a48 48 0 0 1 43.592 27.907l12.349 26.791A16 16 0 0 0 228.486 128H464c26.51 0 48 21.49 48 48v224c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112z"
                  fillRule="nonzero"
                ></path>
              </svg>
            </span>
            <span className="screen-reader-text">Categories </span>
            <a
              href="https://insights.goinvestgreen.com/category/pensions/"
              rel="category tag"
            >
              Pensions
            </a>
          </span>
        </footer>
      </div>
    </div>
  );
}

export default PostBox;
