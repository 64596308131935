import { postsContent } from "./postsContent";
import { images } from "./imgURls";

const authors = {
  justin_reynolds: "Justin Reynolds",
  lydia_morfydd: "Lydia Morfydd",
  matt_robinson: "Matt Robinson",
  henry_goode: "Henry Goode",
};
export const blogData = () => {
  const data = [
    {
      id: 0,
      postDate: new Date(2020, /*  */ 12 - 1 /*  */, 8),
      title:
        "Investing to Save the Planet: a review of a new guide to the world of green finance",
      author: authors.justin_reynolds,
      imgUrls: images[0] || "",
      summary:
        "As readers will know, the Go Invest Green team is developing an app to help users channel their pension towards funding the emerging green economy. But pensions are just one kind of",
    },
    {
      id: 1,
      postDate: new Date(2020, /*  */ 12 - 1 /*  */, 20),
      title:
        "Go Invest Green: how we can make our savings work for a sustainable world",
      author: authors.justin_reynolds,
      imgUrls: images[1] || "",
      summary:
        "There are two broad paths to forcing the transition to an ecologically sustainable global economy. One is to rewire the system. It’s happening — gradually. We’ve known since the 1960s",
    },
    {
      id: 2,
      postDate: new Date(2021, /*  */ 1 - 1 /*  */, 8),
      title: "A new year, a greener you",
      author: authors.lydia_morfydd,
      imgUrls: images[2] || "",
      summary:
        "Have you made a New Year’s resolution to invest in yourself? To get fitter, change your diet, or pursue some other goal? Are you keeping it? Here’s something else you ",
    },
    {
      id: 3,
      postDate: new Date(2021, /*  */ 1 - 1 /*  */, 15),
      title: "Designing your own green pension",
      author: authors.justin_reynolds,
      imgUrls: images[3] || "",
      summary:
        "Perhaps I’ve read too much science fiction, but I have long been fascinated by the future, and impatient for its arrival. I want to see the advanced, ecologically sustainable world ",
    },
    {
      id: 4,
      postDate: new Date(2021, /*  */ 1 - 1 /*  */, 21),
      title: "Greening a workplace pension",
      author: authors.matt_robinson,
      imgUrls: images[4] || "",
      summary:
        "Our pension assessments app will make it as easy as we can for you to check whether your current pension is green or not. So if it tells you your",
    },
    {
      id: 5,
      postDate: new Date(2021, /*  */ 1 - 1 /*  */, 29),
      title: "Millenial money – investing in yourself and the planet",
      author: authors.matt_robinson,
      imgUrls: images[5] || "",
      summary:
        "Have you ever thought of yourself as an investor? You may not realise that every time you make a pension contribution you are actually investing in the stock market. A",
    },
    {
      id: 6,
      postDate: new Date(2021, /*  */ 2 - 1 /*  */, 9),
      title: "Try our workplace pensions app today",
      author: authors.matt_robinson,
      imgUrls: images[6] || "",
      summary:
        "We’re delighted to announce that the first version of our first app is now available. Our workplace pensions assessment tool takes a careful look at the UK’s most common pension",
    },
    {
      id: 7,
      postDate: new Date(2021, /*  */ 3 - 1 /*  */, 30),
      title:
        "Looking beyond green hype: why you should do your own pensions research",
      author: authors.henry_goode,
      imgUrls: images[7] || "",
      summary:
        "As a new member of the Go Invest Green team I was intrigued and eager to take a deep dive into the world of pensions. As a recent graduate I ",
    },
    {
      id: 8,
      postDate: new Date(2021, /*  */ 5 - 1 /*  */, 2),
      title: "Light and dark green pensions – and everything in between",
      author: authors.matt_robinson,
      imgUrls: images[8] || "",
      summary:
        "Just what is a ‘green’ pension? Simply put it is a pension that follows a green or sustainable investment strategy. In other words, your money is being put to productive",
    },
    {
      id: 9,
      postDate: new Date(2021, /*  */ 5 - 1 /*  */, 2),
      title: "Working out what kind of workplace pension you have",
      author: authors.matt_robinson,
      imgUrls: images[9] || "",
      summary:
        "It’s important to know exactly what kind of pension you have if you want to change it. Here we look at the different types of workplace pensions, putting industry jargon ",
    },
    {
      id: 10,
      postDate: new Date(2021, /*  */ 5 - 1 /*  */, 2),
      title: "Could I lose out by switching to a greener pension?",
      author: authors.matt_robinson,
      imgUrls: images[10] || "",
      summary:
        "If you’re not happy with your workplace pension you can start your own: but make sure you understand that you’ll be giving up the benefit of employer contributions. If having",
    },
    {
      id: 11,
      postDate: new Date(2021, /*  */ 5 - 1 /*  */, 2),
      title: "Can I design my own pension?",
      author: authors.justin_reynolds,
      imgUrls: images[11] || "",
      summary:
        "Some workplace pensions give you some choice over the funds that comprise it: but you can also open up a pension of your own if you want complete freedom. Greening",
    },
  ];

  const dataCopy = data.map((elm, idx) => ({
    ...elm,
    content: postsContent(data, idx)[idx],
    imgUrls: images[idx] || null,
  }));
  return dataCopy;
};

// function summary(idx) {
//   // const x = Object.entries(postsText[idx].props.children);
//   let str = "";
//   postsText[idx].props.children.forEach(elm => {
//     if (typeof elm.props.children === "string") {
//       str += elm.props.children;
//     } else if (typeof elm.props.children !== "string") {
//       if (elm.props.children)
//         elm.props.children.forEach(e => {
//           if (typeof e === "string") {
//             str += e;
//           } else {
//             if (e.props.children === "string") {
//               str += e.props.children;
//             }
//           }
//         });
//     }
//   });
//   const strToArr = str.split(" ");

//   let newStr = "";
//   for (let i = 0; i < 30; i++) {
//     if (i < 29) {
//       newStr += strToArr[i] + " ";
//     } else {
//       newStr += strToArr[i];
//     }
//   }
//   return newStr;
// }

// else {
//  if (typeof elm === "string") {
//   console.log(elm);
// }
// elm[1].props.children.forEach(elm => {
//   console.log(elm.type);
//   if (typeof elm === "string") {
//     str += elm;
//   } else {
//     str += elm.props.children;
//   }
// });
// }
// console.log(str);

// let newStr = "";
// for (let i = 0; i < 30; i++) {
//   if (i < 29) {
//     newStr += strToArr[i] + " ";
//   } else {
//     newStr += strToArr[i];
//   }
// }
// newStr += "...";
// return newStr;

export const defaultHeroTitle =
  "Use the power of your savings to build the green economy of the future";
