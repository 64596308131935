/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Themed } from "theme-ui";
import { Component } from "react";
import SectionText from "../components/TextBlocks/SectionText";
import { whyGoGreener } from "../data/whyGoGreener";

class WhyGoGreener extends Component {
  render() {
    return (
      <div>
        <div>
          <p sx={{ textAlign: "center" }}>
            <Themed.h3 as="span">Why go greener?</Themed.h3>
          </p>
          {whyGoGreener.map((string, i) => {
            return <SectionText text={string} key={i} left={true} />;
          })}
        </div>
      </div>
    );
  }
}

export default WhyGoGreener;
