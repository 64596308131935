/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
//eslint-disable-next-line
import React from "react";
import { defaultHeroTitle } from "../../data/insights/blogData";
import PostDate from "./PostDate";

function HeroComponent({ params, pathname, data }) {
  const post = params && data[params];

  const header1Render = () => {
    if (!params || pathname === "/insights") return defaultHeroTitle;
    if (pathname === "/insights/resources") return "Resources";
    if (pathname === "/about") return data.title;
    return <> {(post && post.title) || "404 NOT FOUND"}</>;
  };

  return (
    <div className="page-hero">
      <div className="inside-page-hero grid-container grid-parent">
        <h1
          sx={{
            fontWeight: "300 !important",
            fontSize: "60px",
            marginBottom: "20px",
            lineHeight: " 1.2em",
            textAlign: "left",
          }}
        >
          {header1Render()}
        </h1>
        {pathname !== "/insights" ? (
          <h2 sx={{ marginTop: "1.5rem", fontWeight: "lighter" }}>
            {params && <PostDate date={post && post.postDate} />}
          </h2>
        ) : null}
      </div>
    </div>
  );
}

export default HeroComponent;
