import aviva from "../../assets/pensionImages/aviva.svg";
import standardLife from "../../assets/pensionImages/sl.jpg";
import legalAndGeneral from "../../assets/pensionImages/LegalGeneral.png";
import ajBell from "../../assets/pensionImages/aj-bell-logo.png";
import barclays from "../../assets/pensionImages/barclays-logo.png";
import bestInvest from "../../assets/pensionImages/best-invest-logo.png";
import charlesStanley from "../../assets/pensionImages/charles-stanley-logo.png";
import interactiveInvestor from "../../assets/pensionImages/interactive-investor-logo.png";
import nutmeg from "../../assets/pensionImages/nutmeg-logo.png";
import pensionBee from "../../assets/pensionImages/pension-bee-logo.png";
import vanguard from "../../assets/pensionImages/vanguard-logo.png";
import wealthify from "../../assets/pensionImages/wealthify-logo.png";
import wealthsimple from "../../assets/pensionImages/wealthsimple-logo.png";


const PensionsData = {
  aviva: {
    style: 'pensionProvider',
    title: "AVIVA SIPP",
    logo: aviva,
    summary: `A huge insurance firm and long standing
  pension provider evolving its products to
  meet net zero commitments using
  sustainable assets and pushing
  companies they hold to change.`,
    feeSummary: '',
    fees: {
      '10k': 40,
      '100k': 375
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`

  },
  standardLife: {
    style: 'pensionProvider',
    title: "Standard Life",
    logo: standardLife,
    summary: `A large traditional insurer and pensions provider has a sufficient range of sustainable 
  funds to select from but good luck trying to figure out how much it costs.`,
    feeSummary: `Due to their complex charging structure and as this is based on 
  the funds you choose, we can’t provide meaningful numbers here.`,
    fees: {}
  },

  legalAndGeneral: {
    style: 'pensionProvider',
    title: "Legal & General",
    logo: legalAndGeneral,
    summary: `For a SIPP, has a limited fund range. But it is a good cheap 
  alternative to the robo-advisors, for a select and leave option, if you like their Future World multi-asset plan.`,
    fees: {
      '10k': 25,
      '100k': 250
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`
  },
  
  interactiveInvestor: {
    style: 'diy',
    title: "Interactive Investor",
    logo: interactiveInvestor,
    summary: `ii provides a good service as the number one flat fee investment provider. 
  Low-cost with a clear focus on helping you find green investments.`,
    fees: {
      '10k': 239.88,
      '100k': 239.88
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`

  },

  vanguard: {
    style: 'diy',
    title: 'Vanguard',
    logo: vanguard,
    summary: `Limited range of self select exchange tracking funds but such a low total cost it is worth
  considering to get started, if you are OK with the lighter green of ESG index funds.`,
    fees: {
      '10k': 15,
      '100k': 150
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`

  },

  nutmeg: {
    style: 'robo',
    title: 'Nutmeg',
    logo: nutmeg,
    summary: `The UK’s pioneer Robo-advisor provides a simple 
  ‘set up and leave’ option to having a sustainable pension.`,
    fees: {
      '10k': 75,
      '100k': 748
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`

  },


  charlesStanley: {
    style: 'diy',
    title: 'Charles Stanley Direct Pension',
    logo: charlesStanley,
    summary: `A mid-sized investment platform with good service and a nicely
  curated selection of socially responsible funds. `,
    fees: {
      '10k': 155,
      '100k': 350
    },
    feeNotes: `This does not include the cost of your underlying funds/ investments which vary according to your choices.`,
  },

  ajBell: {
    style: 'diy',
    title: 'AJ Bell',
    logo: ajBell,
    summary: `A comprehensive offering of funds to allow DIY investors to build their 
  own green portfolio, with some sustainable fund suggestions made for you. However it's the low fees that will take to AJ. `,
    feeSummary: `Platform charge of 0.25% per annum, up to £250,000 and only 0.10% from there to a million. `,
    fees: {
      '10k': 25,
      '100k': 250
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices.`
  },

  bestInvest: {
    style: 'diy',
    title: 'Best Invest',
    logo: bestInvest,
    summary: `Long established UK DIY platform with a decent list of fund picks to help 
  you choose and a good telephone service to give some guidance.`,
    fees: {
      '10k': 150,
      '100k': 420
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices.`
  },

  barclaysSmartInvestor: {
    style: 'diy',
    title: 'Barclays Smart Investor',
    logo: barclays,
    summary: `Big Bank Barclays created their Smart Investor pension using AJ Bell. You get a 
  big investment universe but compare fees with going with AJ directly.`,
    feeSummary: `We can’t calculate the fees at 10 and 100k. Barclays 
  provide a good Investment Cost Calculator to estimate your total cost.`
  },

  pensionBee: {
    style: 'pensionProvider',
    title: 'Pension Bee',
    logo: pensionBee,
    summary: `Great option for savers looking for an easy to use, fossil fuel free (FFF) pension plan or who wish to drive
  companies to move to a net zero future, with their Future World plan `,
    fees: {
      '10k': '75 or £95 depending on the plan',
      '100k': '750 or £950 depending on the plan'
    },
    feeNotes: `This does include the cost of your underlying funds!`
  },

  wealthify: {
    style: 'robo',
    title: 'Wealthify',
    logo: wealthify,
    summary: `Simple to use digital pension with a portfolio of very known green funds selected and managed for you. 
  If you are happy to pay a bit more for active funds, an easy ‘select and leave’ option.`,
    fees: {
      '10k': 60,
      '100k': 600
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`

  },


  wealthsimple: {
    style: 'robo',
    title: 'Wealthsimple',
    logo: wealthsimple,
    summary: `Simple to use interface across mobile and web, relatively low cost and flexible investment options 
  make Wealthsimple an appealing choice for those start investing for themselves`,
    fees: {
      '10k': 90,
      '100k': 700
    },
    feeNotes: `This does not include the cost of your underlying funds or other investments which vary according to your choices`
  },
};

export default PensionsData;