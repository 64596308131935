/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";
import "../../stylesheets/RatingGraphic.css";
import greenLightImg from "../../assets/gigImages/Indicator/GreenLight.png";
import amberLightImg from "../../assets/gigImages/Indicator/Amber Light.png";
import redLightImg from "../../assets/gigImages/Indicator/Red light.png";

class EsrRating extends Component {
  RatingP = (rating) => {
    if (rating === "Green") {
      return (
        <span
          sx={{
            variant: "components.ratingIndicator",
            backgroundColor: "ratingGreen",
            color: "#333",
          }}
        >
          {this.props.rating}
        </span>
      );
    }
    if (rating === "Amber") {
      return (
        <span
          sx={{
            variant: "components.ratingIndicator",
            backgroundColor: "ratingAmber",
            color: "#333",
          }}
        >
          {this.props.rating}
        </span>
      );
    }
    if (rating === "Red") {
      return (
        <span
          sx={{
            variant: "components.ratingIndicator",
            backgroundColor: "ratingRed",
            color: "#333",
          }}
        >
          {this.props.rating}
        </span>
      );
    }
  };

  TextSelector = (ratingT) => {
    if (ratingT === "esr") {
      return (
        <div className="esrTextSelector">
          This rating reflects how environmentally sustainable the provider's
          default pension plan is.
        </div>
      );
    }
    if (ratingT === "transparency") {
      return (
        <div className="esrTextSelector">
          This rating reflects how transparent the provider is in providing
          information on what their pensions are invested in.
        </div>
      );
    }
  };

  ESRlightSelector = (rating) => {
    if (rating === "Green") {
      return (
        <img
          src={greenLightImg}
          alt="greenLightImg"
          className="esrTrafficLight"
        />
      );
    }
    if (rating === "Amber") {
      return (
        <img
          src={amberLightImg}
          alt="amberLightImg"
          className="esrTrafficLight"
        />
      );
    }
    if (rating === "Red") {
      return (
        <img src={redLightImg} alt="redLightImg" className="esrTrafficLight" />
      );
    }
  };

  render() {
    return <span>{this.RatingP(this.props.rating)}</span>;
  }
}

export default EsrRating;
