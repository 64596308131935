import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PostAuthorHeader from "../../components/insightsComponents/postContentsPartials/PostAuthorHeader";
import PostImageHeader from "../../components/insightsComponents/postContentsPartials/PostImageHeader";

function PostContent({ data }) {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    if (!(id >= 0 && id < data.length) || !id) return navigate("/insights");
  }, [data.length, id, navigate]);
  return (
    <div className="PostContent">
      <article
        className="post type-post status-publish format-standard has-post-thumbnail category-pensions category-reviews category-stocks-and-shares no-featured-image-padding"
        itemType="https://schema.org/CreativeWork"
        itemScope=""
      >
        <div className="inside-article">
          <PostImageHeader data={data[id]} />
          <PostAuthorHeader data={data[id]} />

          <div className="entry-content" itemProp="text">
            {data[id] && data[id].content}
          </div>
        </div>
      </article>
    </div>
  );
}

export default PostContent;
