/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "react-router-dom";
import linkedin from "../assets/socialMediaIcons/linkedin-white.png";
import twitter from "../assets/twitter_36px.png";
import youtube from "../assets/socialMediaIcons/youtube-white.png";
import wordpress from "../assets/wordpress_36px.png";

const Footer = () => {
  return (
    <footer sx={{ variant: "components.footer" }}>
      <p>
        Copyright 2021 Go Invest Green
        <Link sx={{ color: "white", pl: 1 }} to={"/privacy-policy"}>
          Privacy Policy
        </Link>
        <Link sx={{ color: "white", pl: 1 }} to={"/disclaimer"}>
          Disclaimer
        </Link>
        <Link
          sx={{ color: "white", pl: 1 }}
          to={
            "//us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079"
          }
        >
          Contact Us
        </Link>
      </p>
      <div sx={{ variant: "components.footer.social" }}>
        <a
          href="https://insights.goinvestgreen.com/"
          style={{ textDecoration: "none" }}
        >
          <img
            src={wordpress}
            alt="go invest green blog"
            sx={{ variant: "components.footer.social.images" }}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/go-invest-green/?viewAsMember=true"
          style={{ textDecoration: "none" }}
        >
          <img
            src={linkedin}
            alt="linkedin"
            sx={{ variant: "components.footer.social.images" }}
          />
        </a>
        <a
          href="https://twitter.com/goinvestgreen"
          style={{ textDecoration: "none" }}
        >
          <img
            src={twitter}
            alt="twitter"
            sx={{ variant: "components.footer.social.images" }}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCRNhtgVtHSAHNlsq_VS8Mag"
          style={{ textDecoration: "none" }}
        >
          <img
            src={youtube}
            alt="youtube"
            sx={{ variant: "components.footer.social.images" }}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
