/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscribeFormInner from "./SubscribeFormInner";

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
    };
  }

  // Use prop origreqValue to pass a hidden field value representing the submitter's reason for signing up
  // This will be one of:
  // "ask for change", "news and info",
  // "provider - aviva", "provider - legalAndGeneral", "provider - nest",
  // "provider - now", "provider - prudential", "provider - royalLondon",
  // "provider - scottishWidows", "provider - tpp", "provider - standardLife"
  // "provider - not listed"

  // Use prop otherprovValue to pass a hidden field with the name of another provider, entered by the user

  render() {
    const url =
      "https://goinvestgreen.us7.list-manage.com/subscribe/post?u=629a9a0beb810d2f41477d1fd&id=c1647fcbf1";
    return (
      <div sx={{ variant: "components.subscribeForm" }}>
        <Themed.h1>
          {this.props.header != null ? this.props.header : "Get In Touch"}
        </Themed.h1>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <SubscribeFormInner
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
              origreqValue={this.props.origreqValue}
              otherprovValue={this.props.otherprovValue}
              subscribingTo={this.props.subscribingTo}
            />
          )}
        />
      </div>
    );
  }
}

export default SubscribeForm;
