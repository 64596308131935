/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button } from "theme-ui";

// className SubscribeFormInner extends React.Component {
const SubscribeFormInner = ({
  status,
  message,
  onValidated,
  origreqValue,
  subscribingTo,
  otherprovValue,
}) => {
  // Use prop subscribingTo to pass a block with the text describing what a person is subscribing to

  // Use prop origreqValue to pass a hidden field value representing the submitter's reason for signing up
  // This will be one of:
  // "ask for change", "news and info",
  // "provider - aviva", "provider - legal and general", "provider - nest",
  // "provider - now", "provider - prudential", "provider - royal london",
  // "provider - scottish widows", "provider - the peoples pension",
  // "provider - not listed"

  let email, fname, lname;

  const ready = () => {
    return email && fname && lname && email.value.indexOf("@") > -1;
  };

  const submit = () => {
    if (ready()) {
      onValidated({
        EMAIL: email.value,
        FNAME: fname.value,
        LNAME: lname.value,
        ORIGREQ: origreqValue,
        OTHERPROV: otherprovValue,
      });
    }
  };

  subscribingTo = subscribingTo || (
    <span>
      Subscribe to receive updates from <strong>Go Invest Green</strong>.
    </span>
  );

  return (
    <div sx={{ variant: "components.subscribeForm.fieldSet" }}>
      <div>
        <div
          sx={{
            variant: "components.subscribeForm.field",
            width: ["auto", "60%", "40%"],
            mx: "auto",
          }}
        >
          <input
            placeholder="email"
            type="email"
            name="EMAIL"
            id="MERGE0"
            ref={node => (email = node)}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </div>
        <div sx={{ display: ["block", "flex", "flex"] }}>
          <div
            sx={{
              variant: "components.subscribeForm.field",
              width: ["auto", "60%", "40%"],
              mx: "auto",
            }}
          >
            <input
              placeholder="first name"
              type="text"
              name="FNAME"
              id="MERGE1"
              ref={node => (fname = node)}
              autoCapitalize="on"
              autoCorrect="off"
            />
          </div>
          <div
            sx={{
              variant: "components.subscribeForm.field",
              width: ["auto", "60%", "40%"],
              mx: "auto",
            }}
          >
            <input
              placeholder="surname"
              type="text"
              name="LNAME"
              id="MERGE2"
              ref={node => (lname = node)}
              autoCapitalize="on"
              autoCorrect="off"
            />
          </div>
        </div>

        <div sx={{ variant: "components.subscribeForm.legal" }}>
          {subscribingTo}
        </div>

        <div sx={{ variant: "components.subscribeForm.submit" }}>
          <Button
            sx={{
              transition: "font-size 0.2s !important",
              "&:hover": {
                boxSizing: "border-box",
                fontSize: " 1.4rem !important",
              },
              width: "120px !important",
              height: "50px !important",
              p: "0 !important",
              mx: "0 !important",
              mb: "0 !important",

              fontSize: "18px",
            }}
            id="subscribe-form-submit"
            onClick={submit}
          >
            submit
          </Button>
        </div>
      </div>

      <div sx={{ variant: "components.subscribeForm.status" }}>
        {status === "sending" && (
          <div className="subscribeFormSending">please wait...</div>
        )}
        {status === "error" && (
          <div
            className="subscribeFormError"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="subscribeFormSuccess"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </div>
  );
};

export default SubscribeFormInner;
