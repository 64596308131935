/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from "react";
import { jsx } from "theme-ui";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "theme-ui";
import Logo from "../assets/logo-white-text.png";
import Burger from "./burgerComponents/Burger";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../stylesheets/Navbar.css";

function Navbar({ scrollValue, minScrollValue, minScrollStringVal }) {
  const location = useLocation().pathname;
  const theme = createTheme();
  const styleBg = () => {
    if (minScrollStringVal === "bg-only" && minScrollValue === null) {
      return "#04854f";
    }
    if (
      minScrollStringVal === "bg-only" &&
      minScrollValue &&
      scrollValue === 0
    ) {
      return "#04854f";
    }
    if (scrollValue >= minScrollValue) {
      return "#04854fcc";
    }
  };

  const navPosition = () => {
    if (
      scrollValue >= minScrollValue &&
      minScrollValue >= 0 &&
      minScrollStringVal !== "bg-only"
    ) {
      return "fixed";
    }
    if (
      minScrollValue &&
      minScrollStringVal === "bg-only" &&
      scrollValue >= minScrollValue
    ) {
      return "fixed";
    }
    return "absolute";
  };

  const navPadding = () => {
    if (minScrollValue !== "none" && scrollValue >= minScrollValue) {
      return 0;
    }
  };

  return (
    <Fragment>
      <nav
        sx={{
          transition: scrollValue === 0 ? "none" : "0.5s",
          backgroundColor: styleBg(),
          position: navPosition(),
          pt: navPadding(),
          display: ["none", "flex", "flex"],
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          px: 5,
          top: 0,
          zIndex: 10001,
          color: "white",
          fontFamily: "Ubuntu",
          fontSize: "21px",
          fontWeight: 500,
          letterSpacing: "0.03em",
        }}
        className="Navbar"
      >
        <NavLink as={Link} to="/" sx={{ py: 3, pl: "3em" }}>
          <img src={Logo} alt="logo" sx={{ height: ["50px", "70px"] }} />
        </NavLink>
        <div className="Navbar-links-container" sx={{ pr: "3em" }}>
          <NavLink
            as={Link}
            to="/"
            className={location === "/" ? "nav-active" : ""}
          >
            <span variant="primary">Home</span>
          </NavLink>
          <NavLink
            className={location === "/investment-tools" ? "nav-active" : ""}
            as={Link}
            to="/investment-tools"
          >
            <span>Investment Tools</span>
          </NavLink>
          <NavLink
            className={location === "/insights" ? "nav-active" : ""}
            as={Link}
            to="/insights"
          >
            <span>Insights</span>
          </NavLink>
          <NavLink
            className={location === "/about" ? "nav-active" : ""}
            as={Link}
            to="/about"
          >
            About Us
          </NavLink>
          <NavLink href="https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079">
            <span>Contact</span>
          </NavLink>
        </div>
      </nav>
      <div
        sx={{
          backgroundColor: styleBg(),
          position: navPosition(),
          display: ["flex", "none", "none"],
          justifyContent: "space-between",
          top: 0,
          zIndex: 10001,
          width: "100%",
        }}
      >
        <Link to="/" sx={{ p: 2 }}>
          <img src={Logo} alt="logo" sx={{ height: "50px" }} />
        </Link>
        <div sx={{ p: 2, position: "absolute", right: 0 }}>
          <ThemeProvider theme={theme}>
            <Burger minScrollValue={minScrollValue} />
          </ThemeProvider>
        </div>
      </div>
    </Fragment>
  );
}

export default Navbar;
