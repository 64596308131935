/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Link } from "theme-ui";
import { Component } from "react";

class AdditionalGuidance extends Component {
  render() {
    return (
      <div
        sx={{ variant: `components.section${this.props.block || "Primary"}` }}
      >
        <div sx={{ textAlign: "center" }}>
          <Themed.h1 sx={{ marginTop: "0" }}>Additional Guidance</Themed.h1>
          <Themed.p>
            Our website contains various information and resources that you can
            have a look and read if you do not understand the information you
            see here.
          </Themed.p>
          <div sx={{ lineHeight: 4.2 }}>
            <Link
              sx={{ variant: "links.ctaSecondary" }}
              href="/workplace-pensions/methodology"
            >
              Our Methodology
            </Link>
            <Link
              sx={{ variant: "links.ctaTerciary" }}
              href="https://insights.goinvestgreen.com/resources/"
            >
              Useful Resources
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalGuidance;
