/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";
import "../../stylesheets/PickYourPension.css";
import nest from "../../assets/pensionImages/nest.jpg";
import tpp from "../../assets/pensionImages/tpp.jpg";
import aviva from "../../assets/pensionImages/aviva.svg";
import standardLife from "../../assets/pensionImages/sl.jpg";
import now from "../../assets/pensionImages/now.jpg";
import legalAndGeneral from "../../assets/pensionImages/LegalGeneral.png";
import prudential from "../../assets/pensionImages/prudential.jpg";
import royalLondon from "../../assets/pensionImages/Royal-London.png";
import scottishWidows from "../../assets/pensionImages/scottish-widows.png";
import { Link } from "react-router-dom";

class PickYourPensionComponent extends Component {
  pensionArray = [
    { pensionName: "nest", pensionImage: nest },
    { pensionName: "tpp", pensionImage: tpp },
    { pensionName: "aviva", pensionImage: aviva },
    { pensionName: "standardLife", pensionImage: standardLife },
    { pensionName: "now", pensionImage: now },
    { pensionName: "legalAndGeneral", pensionImage: legalAndGeneral },
    { pensionName: "prudential", pensionImage: prudential },
    { pensionName: "royalLondon", pensionImage: royalLondon },
    { pensionName: "scottishWidows", pensionImage: scottishWidows },
  ];

  otherOptionsArray = [
    { text: "My pension provider is not here", link: "provider-not-listed" },
    { text: "I don't know my pension provider", link: "provider-unknown" },
  ];

  render() {
    return (
      <div
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {this.pensionArray.map(pension => {
          return (
            <Link
              to={{
                pathname: `${pension.pensionName}`,
              }}
              sx={{
                variant: "components.logoBox",
              }}
              key={pension.pensionName}
            >
              <img src={pension.pensionImage} alt={pension.pensionName} />
            </Link>
          );
        })}

        <div
          sx={{
            width: "100%",
            textAlign: "center",
            mt: "2em",
          }}
        >
          {this.otherOptionsArray.map(otherOption => {
            return (
              <div
                key={otherOption.text}
                sx={{
                  my: "1em",
                }}
              >
                <Link
                  to={`${otherOption.link}`}
                  key={otherOption.text}
                  sx={{
                    color: "#363636",
                    fontSize: [1, 2, 2],
                  }}
                >
                  <div>{otherOption.text}</div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PickYourPensionComponent;
