/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";

class LeafNumber extends Component {
  render() {
    return (
      <div sx={{
        width: ['2em'],
        height: ['2em'],
        lineHeight: ['2em'],
        verticalAlign: 'middle',
        textAlign: 'center',
        backgroundColor: '#00874f',
        marginRight: '3%',
        borderRadius: '0 50% 0 50%' ,
        padding: '0',
        color: 'white',
        position: 'relative',
        fontSize: '50px',
        fontFamily: 'Ubuntu',
        flexShrink: 0
    }}>
      {this.props.text}
    </div>


    );
  }
}

export default LeafNumber;
