const Theme = {
  breakpoints: ["768px", "1200px"],
  fonts: {
    body: '"Nunito Sans"',
    heading: '"Ubuntu"',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
  },
  lineHeights: {
    body: 1,
    heading: 1.24,
    hero: 1.5,
  },
  fontSizes: [18, 20, 26, 30, 36, 54, 72, 112],
  colors: {
    text: "#313131",
    background: "#fff",
    primary: "#5CDB95",
    secondary: "rgba(60, 163, 121, 1)",
    green: "#00854f",
    muted: "#d2f6c5",
    yellow: "rgba(244, 211, 94, 0.3)",
    primaryCta: "#ffc700",
    secondaryBg: "#F7F8F9",
    ratingGreen: "#01e9a3",
    ratingAmber: "#f5db81",
    ratingRed: "#f58383",
  },
  space: [0, 4, 8, 16, 24, 32, 64, 96, 112],
  letterSpacings: {
    primary: "0.1em",
  },
  sizes: {
    container: {
      maxWidth: "1200px",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },
    h1: {
      fontSize: [3, 4, 5],
      fontWeight: "heading",
      lineHeight: "heading",
      fontFamily: "heading",
      color: "#444",
    },
    h2: {
      fontSize: [2, 3, 4],
      fontWeight: "body",
      lineHeight: "heading",
      fontFamily: "heading",
    },
    h3: {
      fontSize: [2, 3],
      fontWeight: "heading",
      lineHeight: "heading",
      fontFamily: "heading",
    },
    h4: {
      fontSize: [2, 3],
      fontWeight: "heading",
      lineHeight: "heading",
      margin: 0,
      fontFamily: "heading",
    },
    p: {
      fontSize: [1, 2, 2],
    },
    a: {
      color: "#363636",
      textDecoration: 'underline'
    }
  },
  // variants
  buttons: {
    primary: {
      mt: [2, 3],
      textTransform: "uppercase",
      color: "white",
      bg: "primary",
      fontSize: [1, 2],
      letterSpacing: "primary",
      p: [2, 3],
      border: "1px solid",
      borderColor: "primary",
      "&:hover": {
        bg: "white",
        color: "primary",
        cursor: "pointer",
      },
    },
    menu: {
      color: "white",
      width: "50px",
      height: "50px",
      ">svg": {
        margin: "0px",
      },
    },
  },
  links: {
    block: {
      textDecoration: "none",
      color: "inherit",
    },
    ctaPrimary: {
      color: "black",
      fontSize: "21px",
      fontWeight: 500,
      display: "inline-block",
      px: "1.9em",
      py: "0.5em",
      backgroundColor: "primaryCta",
      borderRadius: "30px",
      opacity: 1,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },

    ctaSecondary: {
      backgroundColor: "#00e8a1",
      borderRadius: "50px",
      height: "36px",
      border: "3px solid #00e8a1",
      textAlign: "center",
      fontSize: 1,
      fontWeight: "bold",
      lineHeight: 0,
      padding: "1.2em 1.8em",
      display: "inline-block",
      textDecoration: "none",
      color: "black",
      mr: "30px",
    },
    ctaTerciary: {
      border: "3px solid #35AD7B",

      backgroundColor: "white",
      borderRadius: "50px",
      height: "36px",
      textAlign: "center",
      fontSize: 1,
      fontWeight: "bold",
      lineHeight: 0,
      padding: "1.2em 1.8em",
      display: "inline-block",
      textDecoration: "none",
      color: "#35AD7B",
      ml: "30px",
    },

    nav: {
      px: 2,
      py: 2,

      color: "white",
      fontFamily: "Ubuntu",
      fontSize: ["20px", "18px", "21px"],
      fontWeight: 500,
      letterSpacing: "0.03em",
    },
  },
  images: {
    provider: {
      maxHeight: "100px",
      display: ["none", "block"],
      marginRight: 3,
    },
    activist: {
      borderRadius: "5px",
      display: "block",
      mx: "auto",
      marginBottom: 5,
    },
  },
  components: {
    columnedCards: {
      display: "flex",
      flexDirection: ["column", "column", "row"],
      pt: 0,
      width: "100%",
      mx: "0",
      justifyContent: "space-between",
      alignItems: "stretch",
    },
    columnedSection: {
      display: "flex",
      flexDirection: ["column", "column", "row"],
      pt: 0,
      width: "100%",
      mx: "0",
      justifyContent: "space-between",
      alignItems: "center",
    },


    simpleSection: {
      width: ["90vw", "85vw", "78vw"],
      margin: "10% auto",
    },

    footer: {
      padding: [0, 2, 3],
      backgroundColor: "green",
      color: "white",
      height: ["60px", "80px"],
      position: "absolute",
      bottom: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      fontSize: ["10px", 0, 1],
      social: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: ["80%", "20%"],
        images: {
          maxWidth: ["20px", "30px"],
        },
      },
    },
    guideMeInfo: {
      width: ["auto", "auto", "50%"],
      padding: "6% 6%",

      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "white",

      h1: {
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },
      p: {
        fontSize: [2, 2, 2],
      },
    },
    guideMeQuestion: {
      alignSelf: "center",
      textAlign: "center",
      width: ["auto", "auto", "50%"],
      padding: "6% 6%",

      color: "body",

      h1: {
        fontSize: [2, 2, 3],
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },
      p: {
        fontSize: [1, 1, 1],
      },
    },
    guideMeButtonNo: {
      mt: [2, 3],
      textTransform: "uppercase",
      color: "white",
      background: "#FF5252",
      fontSize: [1, 2],
      letterSpacing: "primary",
      px: [4, 6],
      py: [2, 3],
      border: "1px solid",
      borderColor: "red",
      "&:hover": {
        bg: "white",
        color: "red",
        cursor: "pointer",
      },
      mx: "0.5em",
    },
    guideMeButtonYes: {
      mt: [2, 3],
      textTransform: "uppercase",
      color: "white",
      background: "#01E9A3",
      fontSize: [1, 2],
      letterSpacing: "primary",
      px: [4, 6],
      py: [2, 3],
      border: "1px solid",
      borderColor: "green",
      "&:hover": {
        bg: "white",
        color: "green",
        cursor: "pointer",
      },
      mx: "0.5em",
    },

    guideMeStart: {
      color: "black",
      fontSize: [1, 2],
      fontWeight: 500,
      display: "inline-block",
      px: "2.4em",
      py: "0.5em",
      backgroundColor: "primaryCta",
      borderRadius: "30px",
      borderColor: "primaryCta",
      opacity: 1,
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "primaryCta",
      },
    },

    guideMeContinue: {
      color: "green",
      fontSize: [1, 2],
      fontWeight: 500,
      display: "inline-block",
      px: "2.4em",
      py: "0.5em",
      backgroundColor: "white",
      borderRadius: "30px",
      opacity: 1,
      textDecoration: "none",
      textTransform: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },

    hero: {
      display: "flex",
      flexDirection: "column",
      pt: 0,
      width: "100%",
      mx: "0",
      background: {
        textAlign: ["left", "left", "left"],
        width: "100%",
        mx: "auto",
        minHeight: "100vh",
        backgroundRepeat: "no-repeat no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      h1Primary: {
        fontWeight: "heading",
        lineHeight: "hero",
        fontFamily: "heading",
        color: "white",
        // textShadow: "2px 3px 1px #909090",
        fontSize: ["32px", "48px", "56px"],
        marginBottom: "0.08em",
      },
      h2Primary: {
        fontWeight: "body",
        lineHeight: "hero",
        fontFamily: "heading",
        color: "white",
        // textShadow: "2px 3px 1px #909090",
        fontSize: ["24px", "28px", "32px"],
        marginTop: "0em",
      },
      h1Secondary: {
        fontWeight: "heading",
        lineHeight: "hero",
        fontFamily: "heading",
        color: "white",
        textShadow: "none",
        fontSize: ["40px", "56px", "60px"],
        mb: "0.4em",
        mt: 0,
        mx: [0, "5%", "10%"],
      },
      h2Secondary: {
        fontWeight: "body",
        lineHeight: "hero",
        fontFamily: "heading",
        color: "white",
        textShadow: "none",
        fontSize: ["22px", "26px", "30px"],
        mt: "0",
        mx: [0, "7.5%", "15%"],
      },
    },

    iconBlock: {
      backgroundColor: "rgba(100, 146, 127, 0.15)",
      borderRadius: "10px",
      width: [60, 90, 120],
      minWidth: [60, 90, 120],
      height: [60, 90, 120],
      lineHeight: ["60px", "90px", "120px"],
      padding: 0,
      margin: 0,
      verticalAlign: "middle",
      textAlign: "center",
      display: "block",
      "> img": {
        verticalAlign: "middle",
        height: [32, 48, 65],
        width: [32, 48, 65],
        maxHeight: [50, 84, 100],
        maxWidth: [50, 84, 100],
        display: "inline",
      },
    },

    indicator: {
      backgroundColor: "ratingGreen",
      color: "gray",
      borderRadius: "10px",
      height: "55px",
      lineHeight: "55px",
      verticalAlign: "middle",
      display: "inline-block",
      width: "24%",
      fontSize: [18, 20, 22],
      fontWeight: 600,
      letterSpacing: "primary",
      textAlign: "center",
      minWidth: "6em",
      mb: "0.6em",
    },

    logoBox: {
      background: "#FFFFFF",
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
      borderRadius: "15px",
      display: "block",
      width: [100, 120, 150],
      height: [100, 120, 150],
      position: "relative",
      overflow: "hidden",
      padding: [10, 15, 20],
      maxWidth: [100, 120, 150],
      minWidth: [100, 120, 150],
      margin: ["5px", "10px", "20px"],
      "> img": {
        width: [80, 90, 110],
        height: "auto",
      },
    },
    main: {
      position: "relative",
      top: 0,
    },
    nextSteps: {
      maxWidth: ["98%", "90%", "60%"],
      mx: "auto",
      "> h2": {
        textAlign: "center",
        fontWeight: "bold",
      },
      "> h3 > img": {
        verticalAlign: "middle",
        marginRight: "0.6em",
      },
      a: {
        color: "secondary",
      },
    },
    nextStepsContainer: {
      background: "#F7F8F9",
      boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      zIndex: 10000,
      position: "relative",
      width: ["90vw", "85vw", "78vw"],

      marginTop: ["-65vh", "-57vh", "-53vh"],
      marginBottom: "2vh",
      mx: "auto",
      padding: "13vh 5vw",

      p: {
        backgroundColor: "white",
        padding: "40px 40px",
        borderRadius: "15px",
        marginTop: "-60px",
        fontSize: ["18px", "20px", "20px"],
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",

        a: {
          color: "green",
        },
      },

      h2: {
        marginBottom: "10px",
        marginTop: "37px",
        padding: "28px 40px",
        backgroundColor: "white",
        borderRadius: "15px",
        fontSize: [3, 4, 4],
      },

      h3: {
        marginBottom: "10px",
        marginTop: "37px",
        padding: "28px 40px",
        backgroundColor: "white",
        borderRadius: "15px",
        fontSize: [2, 3, 3],
      },

      img: {
        verticalAlign: "bottom",
        marginRight: "0.6em",
      },
    },
    overlayContainer: {
      background: "#F7F8F9",
      boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      zIndex: 10000,
      position: "relative",
      width: ["90vw", "85vw", "78vw"],

      marginTop: ["-55vh", "-47vh", "-45vh"],
      marginBottom: "2vh",
      mx: "auto",
      padding: ["3vw", "3vw", "5vw"],
    },

    personalPensionInfo: {
      width: ["auto", "auto", "50%"],
      padding: "6% 6%",

      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "white",

      h1: {
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },
      p: {
        fontSize: [2, 2, 2],
      },
    },
    personalPensionInfo2: {
      width: ["auto", "auto", "50%"],
      padding: "6% 6%",

      color: "body",

      h1: {
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },
      p: {
        fontSize: [2, 2, 2],
      },
    },

    personalPensionLogo: {
      background: "#FFFFFF",
      // boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
      // borderRadius: "15px",
      display: "block",
      width: [100, 120, 150],
      height: [100, 120, 150],
      position: "relative",
      overflow: "hidden",
      padding: 0,
      maxWidth: [100, 120, 150],
      minWidth: [100, 120, 150],
      margin: 0,
      "> img": {
        width: [80, 90, 110],
        height: "auto",
      },
    },

    pensionProviders: {
      display: "flex",
      flexWrap: "wrap",
    },

    plainCard: {
      backgroundColor: "white",
      borderRadius: "10px",
      padding: ["15px", "30px", "50px"],
      marginBottom: "4.5%",
      filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
    },
    plainCompactCard: {
      backgroundColor: "white",
      borderRadius: "10px",
      marginBottom: "4.5%",
      filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
      py: "1em",
      px: ["1.6em", "3em", "4em"],
    },

    plainHalfCard: {
      backgroundColor: "white",
      borderRadius: "10px",
      mb: "4.5%",
      mx: "1%",
      filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
      py: ["1.6em", "2em", "2em"],
      px: ["1.6em", "2em", "2em"],
      width: ["98%", "48%", "48%"],
    },

    plainThirdCard: {
      backgroundColor: "white",
      borderRadius: "10px",
      mb: "4.5%",
      mx: "1%",
      filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
      py: ["1.6em", "2em", "2em"],
      px: ["1.6em", "2em", "2em"],
      width: ["98%", "98%", "31%"],
    },

    plainPPProviderCard: {
      backgroundColor: "white",
      borderRadius: "10px",
      mb: "4.5%",
      mx: "1%",
      filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
      py: ["1.6em", "2em", "2em"],
      px: ["1.6em", "2em", "2em"],
      width: ["98%", "48%", "48%"],

      h2: {
        mt: 0,
        fontSize: 2,
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },

      h3: {
        mt: 0,
        fontSize: 2,
        fontWeight: "heading",
        lineHeight: "heading",
        fontFamily: "heading",
      },

      p: {
        fontSize: 1,
      },
    },

    ratingIndicator: {
      fontFamily: "Ubuntu",
      fontSize: [1, 2, 2],
      color: "#00553B",
      height: "100%",
      fontWeight: "normal",
      width: "100px",
      textAlign: "center",
      margin: "0px 10px",
      px: "1em",
      py: "0.2em",
      borderRadius: "10px",
      minWidth: "7em",
    },
    ratingIndicatorWide: {
      fontFamily: "Ubuntu",
      fontSize: [1, 2, 2],
      color: "#00553B",
      height: "auto",
      fontWeight: "normal",
      width: "20%",
      minWidth: "160px",
      display: "block",
      verticalAlign: "middle",
      textAlign: "center",
      margin: "0px 10px",
      px: "1em",
      py: "0.2em",
      borderRadius: "10px",
    },

    subscribeForm: {
      width: "auto",
      margin: "30px auto 0",
      pt: "1em",
      px: ["2%", "10%", "15%"],
      backgroundColor: "white",
      borderRadius: "10px",
      textAlign: "center",
      field: {
        overflow: "visible",
        mt: "3.5em",
        mb: "0em",
        textAlign: "center",
        "> input": {
          padding: "4px 6px",
          width: "100%",
          fontSize: 1,
          border: 0,
          boxSizing: "border-box",
          color: "black",
          borderBottom: "3px solid #CBCBCB",
        },
        "> input:focus": {
          outline: "none",
          borderBottom: "3px solid #222",
        },
      },
      fieldSet: {
        mb: "4em",
      },

      legal: {
        fontSize: 0,
        marginTop: "4em",
      },

      submit: {
        button: {
          color: "black",
          fontSize: "18px",
          fontWeight: 600,
          display: "inline-block",
          px: "2.4em",
          py: "0.8em",
          backgroundColor: "primaryCta",
          borderRadius: "30px",
          opacity: 1,
          textDecoration: "none",
          border: "none",
          textTransform: "lowercase",

          "&:hover": {
            cursor: "pointer",
            backgroundColor: "primaryCta",
            border: "none",
            color: "black",
          },
        },
      },

      status: {
        fontWeight: "bold",
        color: "gray",
      },
    },
    sectionNextSteps: {
      padding: ["2% 4% 4%", "4% 6% 6%", "6% 8% 8%"],
      backgroundColor: "secondaryBg",
    },
    sectionPrimary: {
      padding: ["2% 4% 4%", "4% 6% 6%", "6% 8% 8%"],
    },
    sectionSecondary: {
      padding: ["2% 4% 4%", "4% 6% 6%", "6% 8% 8%"],
      backgroundColor: "secondaryBg",
    },
    sectionInOverlay: {
      padding: ["2em 1em 4em", "2em 3em 4em", "2em 4em 4em"],
    },
  },
};

export default Theme;
