/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import { Component } from "react";

class PicCard extends Component {
  render() {
    return (
      <div
        sx={{
          width: ["auto", "100%", "100%"],
          backgroundColor: "white",
          marginLeft: [0, "1%", "2%"],
          marginRight: [0, "1%", "2%"],
          marginBottom: [20, 30, 0],

          "&:first-of-type": {
            marginLeft: 0,
          },
          "&:last-of-type": {
            marginRight: 0,
          },

          borderRadius: "30px",
          padding: "1.7em 1.7em 5em",
          textAlign: "center",
          position: "relative",
        }}
      >
        <div
          sx={{
            paddingBottom: "1.8em",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <img
            src={this.props.imageSrc}
            alt={this.props.imageSrc}
            sx={{
              maxWidth: "100%",
              width: "100%",
            }}
          />
        </div>
        <h3
          sx={{
            fontSize: "26px",
            fontWeight: "bold",
            margin: "0.3em 0",
            padding: "0",
          }}
        >
          {this.props.title}
        </h3>
        <div
          sx={{
            fontSize: "20px",
            letterSpacing: "0",
            mb: this.props.linkHref ? "1.6em" : "-1.6em",
          }}
        >
          {this.props.text}
        </div>
        {this.props.linkHref && (
          <div
            sx={{
              textAlign: "center",
              padding: "1.7em 0 3em",
              width: "100%",
              left: 0,
              position: "absolute",
              bottom: 0,
            }}
          >
            <Link
              sx={{
                backgroundColor: "#00e8a1",
                borderRadius: "50px",
                height: "36px",
                border: 0,
                textAlign: "center",
                fontSize: 1,
                fontWeight: "bold",
                lineHeight: 0,
                padding: "1.2em 1.8em",
                display: "inline-block",
                textDecoration: "none",
                color: "black",
              }}
              href={this.props.linkHref}
            >
              {this.props.linkLabel}
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default PicCard;
