/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Layout(props) {
  return (
    <div sx={{ minHeight: "100vh", position: "relative" }}>
      <Navbar {...props} />
      <main sx={{ pb: "100px" }}>
        <div sx={{ mx: "auto", pt: 0, pb: 0 }}>{props.children}</div>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
