/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";
import { Themed } from "theme-ui";
import FAQAccordian from "./FAQAccordian";

class FAQ extends Component {
  render() {
    return (
      <div>
        <p sx={{ textAlign: "center" }}>
          <Themed.h3 as="span">FAQ</Themed.h3>
        </p>
        <FAQAccordian />
      </div>
    );
  }
}

export default FAQ;
