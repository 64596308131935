/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import { useCallback, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { Themed } from "theme-ui";
import PickYourPension from "../../components/workplacePensions/PickYourPension.jsx";
import heroImage from "../../assets/workplacePensions/forest-growth.jpg";
import touchApp from "../../assets/workplacePensions/touch_app.svg";
import personSignpost from "../../assets/workplacePensions/person_signpost.svg";
import thumbsUpDown from "../../assets/workplacePensions/thumbs_up_down.svg";
import worldInMyHand from "../../assets/workplacePensions/world_in_my_hand.jpg";

import BtnDownArrow from "../../components/BtnDownArrow";
import SubscribeForm from "../../components/subscribeForm/SubscribeForm";

const WorkplacePensionsHome = ({ handleMinScrollValue }) => {
  // const goToPensions = () => (window.location = "/workplace-pensions/home");
  const params = useParams();
  const handleScroll = useCallback(value => {
    window.scrollTo({ top: value });
  }, []);

  useEffect(() => {
    if (params.pension) {
      return window.scrollTo({ top: 426 });
    } else if (!params.pension) {
      handleScroll(0);
      handleMinScrollValue(720);
    }
  }, [handleScroll, handleMinScrollValue, params.pension]);

  if (params.pension) return <Outlet />;
  if (!params.pension) {
    return (
      <div
        sx={{
          position: "relative",
          top: 0,
        }}
      >
        {/* Hero */}
        <div sx={{ variant: "components.hero" }}>
          <div
            sx={{
              variant: "components.hero.background",
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div
              sx={{
                pt: ["16%", "none"],
                margin: "0 10%",
                textAlign: "center",
              }}
            >
              <h1
                sx={{
                  variant: "components.hero.h1Secondary",
                }}
              >
                Find out where the money in your pension is going
              </h1>
              <h2
                sx={{
                  variant: "components.hero.h2Secondary",
                }}
              >
                Go Invest Green shows how green your pension is and the simple
                actions you can take to make it greener.
              </h2>
              <div
                sx={{
                  mt: "0.3em",
                  textAlign: "center",
                }}
              >
                <BtnDownArrow anchorUrl="#shape-our-world" />
                {/* < */}
                {/* <Link
                sx={{
                  variant: "links.ctaPrimary",
                }}
                href="#shape-our-world"
              >
                Get Started
              </Link> */}
              </div>
            </div>
          </div>
        </div>
        {/* Shape our World */}
        <div id="shape-our-world"></div>
        <div
          sx={{
            variant: "components.columnedSection",
            margin: "10% 0",
          }}
        >
          <div
            sx={{
              width: ["auto", "auto", "60%"],
              margin: "0 3% 0 5%",
            }}
          >
            <Themed.h1>Our savings shape our world</Themed.h1>
            <Themed.p>
              Do you know just how powerful your pension can be in driving the
              transition to a green economy?
            </Themed.p>
            <Themed.p>
              Money we think of as sitting quietly in the bank, or accumulating
              patiently in a pension fund, is actually hyperactive, invested and
              reinvested by the finance professionals who manage it.
            </Themed.p>
            <Themed.p>
              Unless we take the time to understand how our savings are being
              used they may well be working against rather than for a green
              future.
            </Themed.p>
          </div>
          <div
            sx={{
              width: ["auto", "auto", "40%"],
              margin: "0 5% 0 3%",
            }}
          >
            <img
              src={worldInMyHand}
              alt={worldInMyHand}
              sx={{
                maxWidth: "100%",
              }}
            />
          </div>
        </div>
        {/* How does it work? */}
        <div sx={{ variant: "components.sectionSecondary" }}>
          <div
            sx={{
              mb: "4em",
              textAlign: "center",
            }}
          >
            <Themed.h1 sx={{ marginTop: "0" }}>How does it work?</Themed.h1>
            <Themed.p>
              Assess how green your pension is in 3 simple steps.
            </Themed.p>
          </div>
          <div
            sx={{
              marginTop: "10%",
              marginBottom: "5%",
              marginLeft: ["3%", "3%"],
              marginRight: ["3%", "3%"],
            }}
          >
            <div
              sx={{
                variant: "components.plainCard",
                display: ["block", "flex", "flex"],
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                  mb: "1.6em",
                }}
              >
                <img src={touchApp} alt={touchApp}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Picking your pension
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  You will choose your personal or workplace pension based on
                  the list that we have provided.
                </Themed.p>
              </div>
            </div>
            <div
              sx={{
                variant: "components.plainCard",
                display: ["block", "flex", "flex"],
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                  mb: "1.6em",
                }}
              >
                <img src={thumbsUpDown} alt={thumbsUpDown}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Rating your pension
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  We will then provide an assessment regarding how green the
                  pension is based on 2 rating systems: Transparency and Green
                  Rating.
                </Themed.p>
                <div sx={{}}>
                  <div
                    sx={{
                      variant: "components.indicator",
                      mx: "2.7%",
                    }}
                  >
                    Green
                  </div>
                  <div
                    sx={{
                      variant: "components.indicator",
                      backgroundColor: "ratingAmber",
                      mx: "2.7%",
                    }}
                  >
                    Amber
                  </div>
                  <div
                    sx={{
                      variant: "components.indicator",
                      backgroundColor: "ratingRed",
                      mx: "2.7%",
                    }}
                  >
                    Red
                  </div>
                </div>
              </div>
            </div>
            <div
              sx={{
                variant: "components.plainCard",
                display: ["block", "flex", "flex"],
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                  mb: "1.6em",
                }}
              >
                <img src={personSignpost} alt={personSignpost}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Next Steps
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  After having assessed your pension, we provide advice and
                  suggestions on your next actions to make your pension greener.
                </Themed.p>
              </div>
            </div>
          </div>
        </div>
        {/* Pick Your Pension */}
        <div
          sx={{
            marginTop: "5%",
            marginBottom: "5%",
            marginLeft: [0, "1%", "1%"],
            marginRight: [0, "1%", "1%"],
          }}
        >
          <div sx={{ textAlign: "center", marginBottom: "4em" }}>
            <Themed.h1>Select your Pension Provider</Themed.h1>
            <Themed.p>
              Find out how green your pension provider is, and steps you can
              take to make your pension greener.
            </Themed.p>
          </div>

          <div
            sx={{
              background: "#F7F8F9",
              padding: ["5% 0", "5% 4vw", "5% 8vw"],
              boxSizing: "content-box",
              boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.15)",
              borderRadius: "5px",
              width: "94%",
              maxWidth: 572,
              margin: "0 auto",
            }}
          >
            <PickYourPension />
          </div>
        </div>
        {/* Guidance */}
        <div sx={{ variant: "components.sectionSecondary" }}>
          <div
            sx={{
              textAlign: "center",
            }}
          >
            <Themed.h1 sx={{ marginTop: "0" }}>Additional Guidance</Themed.h1>
            <Themed.p>
              Our website contains various information and resources that you
              can have a look and read if you do not understand the information
              you see here.
            </Themed.p>
            <div sx={{ lineHeight: 4.2 }}>
              <Link
                sx={{ variant: "links.ctaSecondary" }}
                href="/workplace-pensions/methodology"
              >
                Our Methodology
              </Link>
              <Link
                sx={{
                  variant: "links.ctaTerciary",
                }}
                href="https://insights.goinvestgreen.com/resources/"
              >
                Useful Resources
              </Link>
            </div>
          </div>
        </div>
        {/* Contact Us */}
        <div sx={{}}>
          <SubscribeForm header="Keep In Touch" />
        </div>
      </div>
    );
  }
};

export default WorkplacePensionsHome;
