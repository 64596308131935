/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import ProviderHero from "./ProviderHero";
import { Component } from "react";
import { Link } from "theme-ui";
import "../../stylesheets/PersonalPensions.css";
import SubscribeForm from "../../components/subscribeForm/SubscribeForm";
import "../../stylesheets/TextBlocks/SectionText.css";
import "../../stylesheets/ContentPage.css";
import ReactMarkdown from "react-markdown";
import MarkdownStandardLife from "./markdowns/StandardLife.md";
import MarkdownAviva from "./markdowns/Aviva.md";
import MarkdownLegalAndGeneral from "./markdowns/LegalAndGeneral.md";
import MarkdownAjBell from "./markdowns/AjBell.md";
import MarkdownBarclays from "./markdowns/Barclays.md";
import MarkdownBestInvest from "./markdowns/BestInvest.md";
import MarkdownCharlesStanley from "./markdowns/CharlesStanley.md";
import MarkdownInteractiveInvestor from "./markdowns/InteractiveInvestor.md";
import MarkdownNutmeg from "./markdowns/Nutmeg.md";
import MarkdownPensionBee from "./markdowns/PensionBee.md";
import MarkdownVanguard from "./markdowns/Vanguard.md";
import MarkdownWealthify from "./markdowns/Wealthify.md";
import MarkdownWealthsimple from "./markdowns/Wealthsimple.md";
import AdditionalGuidance from "../../components/workplacePensions/AdditionalGuidance";
import PensionsData from "./PensionsData.jsx";
import { useParams } from "react-router-dom";

//==> HOC to retrieve params
function withRouter(Child) {
  return props => {
    const params = useParams();
    return <Child {...props} params={params} />;
  };
}
// <== HOC to retrieve params

class ProviderDetails extends Component {
  constructor(props) {
    super(props);
    const provider = this.props.params.personalPensionProvider;
    this.state = {
      provider: provider,
      providerName: PensionsData[provider].title,
      providerStyle: PensionsData[provider].style,
      markdown: "",
    };
  }

  StyleCaptions = {
    diy: "DIY Provider",
    pensionProvider: "Pension Provider",
    robo: "Robo Provider",
  };

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    var providerMarkdown;
    switch (this.state.provider) {
      case "standardLife":
        providerMarkdown = MarkdownStandardLife;
        break;
      case "aviva":
        providerMarkdown = MarkdownAviva;
        break;
      case "legalAndGeneral":
        providerMarkdown = MarkdownLegalAndGeneral;
        break;
      case "ajBell":
        providerMarkdown = MarkdownAjBell;
        break;
      case "barclaysSmartInvestor":
        providerMarkdown = MarkdownBarclays;
        break;
      case "bestInvest":
        providerMarkdown = MarkdownBestInvest;
        break;
      case "charlesStanley":
        providerMarkdown = MarkdownCharlesStanley;
        break;
      case "interactiveInvestor":
        providerMarkdown = MarkdownInteractiveInvestor;
        break;
      case "nutmeg":
        providerMarkdown = MarkdownNutmeg;
        break;
      case "pensionBee":
        providerMarkdown = MarkdownPensionBee;
        break;
      case "vanguard":
        providerMarkdown = MarkdownVanguard;
        break;
      case "wealthify":
        providerMarkdown = MarkdownWealthify;
        break;
      case "wealthsimple":
        providerMarkdown = MarkdownWealthsimple;
        break;
      default:
        break;
    }

    fetch(providerMarkdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;
    const subscribingTo = (
      <span>
        Subscribe to receive updates from <strong>Go Invest Green</strong>.
      </span>
    );

    return (
      <div sx={{ variant: "components.main" }} className="ProviderDetails">
        <ProviderHero
          text={this.state.providerName}
          text2={`${this.StyleCaptions[this.state.providerStyle]} Assessment`}
        />
        <div sx={{ variant: "components.sectionNextSteps" }}>
          <div sx={{ variant: "components.nextStepsContainer" }}>
            <ReactMarkdown children={markdown} className="theSectionTextLeft" />
          </div>
          <div sx={{ textAlign: "center", pt: "1em" }}>
            <div sx={{ variant: "", mt: "20px", pb: "30px" }}>
              <Themed.p>
                Please note, <b>Go Invest Green</b> can only provide
                information, not advise you on what to do personally. Please
                read our <a href="/disclaimer">disclaimer</a> before making any
                decisions.
              </Themed.p>
            </div>
            <Link
              sx={{
                variant: "links.ctaPrimary",
              }}
              href="/personal-pensions/home"
            >
              Go Back to View Other Pensions
            </Link>
          </div>
        </div>

        <AdditionalGuidance block="Primary" />

        <div sx={{}}>
          <SubscribeForm
            origreqValue={`provider - ${this.props.usersPension}`}
            subscribingTo={subscribingTo}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ProviderDetails);
