/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { Themed } from "theme-ui";
import heroImage from "../assets/heroImages/bernd-dittrich-NKBum-xJ0UI-unsplash.jpg";
import BtnDownArrow from "../components/BtnDownArrow.jsx";

// import "../stylesheets/insights/InsightsHome.css";
function About({ handleMinScrollValue }) {
  useEffect(() => {
    handleMinScrollValue(630);
    window.scrollTo({ top: 0 });
  }, [handleMinScrollValue]);
  return (
    <div
      className="About"
      sx={{
        position: "relative",
        top: 0,
      }}
    >


      {/* Hero */}
      <div sx={{ variant: "components.hero" }}>
        <div
          sx={{
            variant: "components.hero.background",
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div
            sx={{
              pt: ["16%", "none"],
              margin: "0 10%",
              textAlign: "center",
            }}
          >
            <h1
              sx={{
                variant: "components.hero.h1Secondary",

              }}
            >
              About Go Invest Green
            </h1>
          </div>

          <div
            sx={{
              mt: "1.6em",
              textAlign: "center",
            }}
            className="Homepage-down-arrow-container"
          >
            <BtnDownArrow anchorUrl={"#get-started"} />
          </div>

        </div>
      </div>

      {/* <HeroComponent
        pathname={pathname}
        data={{ title: "About Go Invest Green " }}
      /> */}
      <div id="get-started"></div>

      <div
        id="primary"
        sx={{
          variant: "components.simpleSection",
          // width: "75%",
          // marginRight: "auto !important",
          // marginLeft: "auto !important",
        }}

      >
        <div
        >

          <div

          >

            <div sx={{ marginTop: "3em" }}>
              <Themed.p>
                Do you know just how powerful your savings can be in driving
                the transition to a green economy?
              </Themed.p>

              <Themed.p>
                Whether we know how our money is used or not, our savings
                shape our world. Money we think of as sitting quietly in the
                bank, or accumulating patiently in a pension fund, is actually
                hyperactive, invested and reinvested by the finance
                professionals who manage it.
              </Themed.p>

              <Themed.p>
                Unless we take the time to understand how our savings are
                being used they may well be working against rather than for a
                green future.
              </Themed.p>

              <h2>Helping you see where your money is going</h2>

              <Themed.p>
                Go Invest Green is a UK-based voluntary organisation
                highlighting the power of investment to build the green
                economy of the future. We a diverse group of financial and
                communications professionals, business writers, and web
                developers with a keen interest in green finance. We can’t
                give you advice about how where to put your money, but we can
                pass on what we’ve learned in the course of greening our own
                savings.{" "}
              </Themed.p>

              <Themed.p>
                The first version of our first app helps you assess how green
                your pension is. We’ve taken a careful look at the UK’s most
                common pension plans to assess just how green they are, to
                make it easier for you to decide whether you’re happy where
                your money is going, or whether you want to make a change –{" "}
                <Themed.a
                  href="https://goinvestgreen.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  try it out today
                </Themed.a>
                .
              </Themed.p>

              <Themed.p>
                You’ll see that many workplace pensions still invest more than
                you’d like in fossil fuels and other high-carbon sectors. But
                more and more are investing in the new technologies and
                industries that are building a new zero carbon economy.
              </Themed.p>

              <h2>Following Go Invest Green</h2>

              <Themed.p>
                Follow us here, on{" "}
                <Themed.a href="https://twitter.com/goinvestgreen">Twitter</Themed.a> and{" "}
                <Themed.a href="https://www.linkedin.com/company/go-invest-green/">LinkedIn</Themed.a>{" "}
                for updates on our pensions assessment app, and posts on all
                aspects of green investment.
              </Themed.p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default About;

// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { jsx } from "theme-ui";
// import { useEffect } from "react";
// import { Themed } from "theme-ui";
// import methodologyPerson from "../assets/home/methodology-person-p.svg";
// import LeafNumber from "../components/pageComponents/LeafNumber.jsx";
// import SubscribeForm from "../components/subscribeForm/SubscribeForm";

// const About = ({ handleMinScrollValue }) => {
//   window.localStorage.setItem("personal-pension-style", null);

//   useEffect(() => {
//     handleMinScrollValue(200, "bg-only");
//     window.scrollTo(0, 0);
//   }, [handleMinScrollValue]);

//   return (
//     <div
//       sx={{
//         paddingTop: "111px",
//       }}
//       className="About"
//     >
//       {/* Hero */}

//       <div
//         sx={{
//           variant: "components.columnedSection",
//           margin: "0 0",
//         }}
//       >
//         <div
//           sx={{
//             width: ["auto", "auto", "60%"],
//             margin: ["0 5% 0 5%", "0 5% 0 3%", "0 5% 0 3%"],
//           }}
//         >
//           <Themed.h1>What is Go Invest Green</Themed.h1>
//           <Themed.p>
//             Go Invest Green is a UK voluntary organisation offering easy-to-use
//             tools to help you make sure your pension, mortgage and savings are
//             funding the development of a net zero economy rather than the carbon
//             intensive industries of the past.
//           </Themed.p>
//         </div>
//       </div>

//       {/* Methodology Section */}
//       <div
//         sx={{
//           margin: "10% 0",
//         }}
//       >
//         <div sx={{ variant: "components.columnedSection" }}>
//           <div
//             sx={{
//               width: ["auto", "auto", "40%"],
//               margin: "0 3% 0 5%",
//             }}
//           >
//             <img
//               src={methodologyPerson}
//               alt={methodologyPerson}
//               sx={{
//                 maxWidth: "100%",
//                 width: "100%",
//               }}
//             />
//           </div>
//           <div
//             sx={{
//               width: ["auto", "auto", "60%"],
//               margin: "0 5% 0 3%",
//             }}
//           >
//             <Themed.h1>How We Work</Themed.h1>
//             <Themed.p>
//               We’re not fund managers with a product to sell. It’s not our
//               business to tell you how to save your money. But we are a group of
//               financially literate individuals who have taken steps to green our
//               own savings, and want to pass on what we’ve learned.
//             </Themed.p>
//           </div>
//         </div>
//       </div>

//       <div
//         sx={{
//           marginTop: "10%",
//           marginBottom: "10%",
//           marginLeft: ["3%", "12%"],
//           marginRight: ["3%", "12%"],
//         }}
//       >
//         <div
//           sx={{
//             display: "flex",
//           }}
//         >
//           <LeafNumber text="1"></LeafNumber>
//           <div>
//             <Themed.h4
//               sx={{
//                 fontWeight: "500",
//                 marginBottom: "0.3em",
//                 marginTop: "-0.3em",
//               }}
//             >
//               Passing on what we’ve found out
//             </Themed.h4>
//             <Themed.p sx={{ marginTop: "0" }}>
//               We’ve studied the best research on the UK’s leading pension
//               providers, and looked carefully at them ourselves
//             </Themed.p>
//           </div>
//         </div>
//         <div
//           sx={{
//             height: "5em",
//             width: "100%",
//             position: "relative",
//             display: "block",
//           }}
//         >
//           &nbsp;
//         </div>
//         <div
//           sx={{
//             display: "flex",
//           }}
//         >
//           <LeafNumber text="2"></LeafNumber>
//           <div>
//             <Themed.h4
//               sx={{
//                 fontWeight: "500",
//                 marginBottom: "0.3em",
//                 marginTop: "-0.3em",
//               }}
//             >
//               Give insights based on assessments
//             </Themed.h4>
//             <Themed.p sx={{ marginTop: "0" }}>
//               We rank each pension according to how green the underlying funds
//               are, and how open providers are about where money is invested
//             </Themed.p>
//           </div>
//         </div>
//         <div
//           sx={{
//             height: "5em",
//             width: "100%",
//             position: "relative",
//             display: "block",
//           }}
//         >
//           &nbsp;
//         </div>
//         <div
//           sx={{
//             display: "flex",
//           }}
//         >
//           <LeafNumber text="3"></LeafNumber>
//           <div>
//             <Themed.h4
//               sx={{
//                 fontWeight: "500",
//                 marginBottom: "0.3em",
//                 marginTop: "-0.3em",
//               }}
//             >
//               We aim to help you understand
//             </Themed.h4>
//             <Themed.p sx={{ marginTop: "0" }}>
//               We maintain a blog passing on what we have learned about the world
//               of green finance, and highlighting our plans for new services
//             </Themed.p>
//           </div>
//         </div>
//       </div>

//       {/* Contact Us */}
//       <div>
//         <SubscribeForm header="Keep In Touch" />
//       </div>
//     </div>
//   );
// };

// export default About;
