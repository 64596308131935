import React, { Component } from "react";
import "../../stylesheets/NextSteps.css";
import PageHeader from "../TextBlocks/PageHeader";
import SectionText from "../TextBlocks/SectionText";
import SectionHeader from "../TextBlocks/SectionHeader";
import pensionActivistImg from "../../assets/gigImages/Cartoon Images/Pension Activist.png";
import NSTable from "./NSTable";
import SubscribeForm from "../subscribeForm/SubscribeForm";
import AdditionalGuidance from "../../components/workplacePensions/AdditionalGuidance";

class NextSteps extends Component {
  render() {
    const subscribingTo = (
      <span>
        Subscribe to receive updates from <strong>Go Invest Green</strong>.
      </span>
    );

    return (
      <div>
        <PageHeader text={"Next Steps to Go Invest Green"} />
        <img
          src={pensionActivistImg}
          alt="pension activist"
          className="beAnActivistImg"
        />
        <SectionText
          text={
            "If you agree that your pension is not green enough, it’s time to take some action. Think first about whether to do this alone or to get together with others to build the case for change - this is your money, and you all have every right to ask for something better!"
          }
        />
        <SectionHeader text={"Next Steps"} />
        <NSTable />
        <SectionHeader text={"Email Me"} />
        <SectionText
          text={
            "If you want to receive this information via email, share your email below."
          }
        />
        <AdditionalGuidance block="Secondary" />
        <SubscribeForm
          origreqValue={`provider - ${this.props.usersPension}`}
          subscribingTo={subscribingTo}
        />
      </div>
    );
  }
}

export default NextSteps;
