/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Component } from "react";
import { jsx, Themed } from "theme-ui";
import { Link } from "theme-ui";
import { Button } from "theme-ui";
// import { Embed } from "theme-ui";

import heroImage from "../../assets/heroImages/green-hills.jpg";
// import touchApp from "../../assets/workplacePensions/touch_app.svg";
// import personSignpost from "../../assets/workplacePensions/person_signpost.svg";
// import thumbsUpDown from "../../assets/workplacePensions/thumbs_up_down.svg";
import greenback from "../../assets/guideMeImages/greenback.jpg";

import SubscribeForm from "../../components/subscribeForm/SubscribeForm";

class GuideMe extends Component {
  constructor() {
    super();
    this.state = {
      questionNum: 1,
      viewAs: "question",
    };
    this.scrollRef = React.createRef();
    this.allToolsRef = React.createRef();
  }

  numQuestions = 3;
  questionText = [
    "Do you have a current workplace pension?",
    "Are you interested in personal pensions?",
    "Would you like to learn about Self-Invested Personal Pensions (SIPP)?",
  ];

  toolText = [
    "Getting your current pension investments in order is a great place to start, and can be surprisingly simple. To get started, let's see how green your pension is.",
    "Want to find some ideas for a good, green personal pension, based on your financial style?",
  ];

  toolHrefs = [
    "/workplace-pensions",
    "/personal-pensions/home",
    "https://insights.goinvestgreen.com/2021/05/02/can-i-design-my-own-pension/",
  ];

  infoText = [
    <div>
      <h1>Workplace Pensions</h1>
      <p>There are two types of workplace pensions.</p>
      <p>
        The most common are <b>defined contributions</b> schemes. You pay
        regular payments into a fund, topped up by your employer. The
        government’s auto-enrollment rules require employers to ensure all
        employees are entitled to a pension. Your final pension will depend on
        your contributions.
      </p>
      <p>
        <b>Defined benefits</b> schemes - often called <b>final salary</b>{" "}
        pensions - pay you a consistent, known income based on your final or
        average wage, and how long you have worked for the company paying it.
        These are now closed to most new employees.
      </p>
    </div>,
    <div>
      <h1>Personal Pensions</h1>
      <p>
        If you have a <b>personal pension</b> - also known as a{" "}
        <b>private pension</b> - you may be able to design how it is constructed
        by choosing from a selection of green funds presented by your provider.
      </p>
      <p>
        Think of these as building blocks the provider’s investment advisers
        think will work well together. One of the <i>Go Invest Green</i> team,
        for example, has customised their Aviva pension by choosing from a set
        of well-regarded green funds from Liontrust, Jupiter, and other
        respected names.
      </p>
    </div>,
    <div>
      <h1>Self-Invested Personal Pensions (SIPP)</h1>
      <p>
        If you want complete freedom over the funds that comprise your private
        pension you can open a <b>Self-Invested Personal Pension (SIPP)</b>.
      </p>
      <p>
        This frees you to mix and match funds, trusts, bonds and direct holdings
        in individual companies as you wish. You will be responsible for
        ensuring your portfolio is calibrated with the right mix of assets
        according to your position in your own journey towards retirement.
      </p>
      <p>
        You’ll no longer benefit from the <b>lifestyle strategy</b> built into a
        professionally managed pension, designed to move along a ‘glidepath’ as
        you move closer to retirement, gradually moving from shares to safer
        assets like bonds.
      </p>
    </div>,
  ];

  info() {
    return this.infoText[this.state.questionNum - 1];
  }

  question() {
    return (
      <div>
        <p>
          Question {this.state.questionNum} of {this.numQuestions}
        </p>
        {this.state.viewAs === "question" ? (
          <div>
            <h1>{this.questionText[this.state.questionNum - 1]}</h1>
            <p>
              <Button
                sx={{ variant: "components.guideMeButtonYes" }}
                onClick={this.clickedYes}
              >
                Yes
              </Button>
              <Button
                sx={{ variant: "components.guideMeButtonNo" }}
                onClick={this.clickedNo}
              >
                No
              </Button>
            </p>
          </div>
        ) : (
          <div>
            <p>{this.toolText[this.state.questionNum - 1]}</p>
            <p>
              <Link
                sx={{ variant: "components.guideMeStart" }}
                href={this.toolHrefs[this.state.questionNum - 1]}
              >
                Get Started
              </Link>
            </p>
            <p>
              <Button
                sx={{ variant: "components.guideMeContinue" }}
                onClick={this.clickedNo}
              >
                Not Yet
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }

  clickedYes = () => {
    this.setState({
      viewAs: "tool",
    });
  };

  clickedNo = () => {
    if (this.state.questionNum < this.numQuestions) {
      this.setState({
        questionNum: this.state.questionNum + 1,
        viewAs: "question",
      });
      this.scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      this.allToolsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  render() {
    return (
      <div
        sx={{
          position: "relative",
          top: 0,
        }}
      >
        {/* Hero */}
        <div sx={{ variant: "components.hero" }}>
          <div
            sx={{
              variant: "components.hero.background",
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div
              sx={{
                pt: ["16%", "none"],
                margin: "0 10%",
                textAlign: "center",
              }}
            >
              <h1
                sx={{
                  variant: "components.hero.h1Secondary",
                }}
              >
                Don’t know where to start?
              </h1>
              <h2
                sx={{
                  variant: "components.hero.h2Secondary",
                }}
              >
                Answer a few questions so we can guide you...
              </h2>
              <div
                sx={{
                  mt: "0.3em",
                  textAlign: "center",
                }}
              >
                <Link
                  sx={{
                    variant: "links.ctaPrimary",
                  }}
                  href="#start-guiding"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="start-guiding" ref={this.scrollRef}></div>
        {/* Start Guiding */}
        <div
          sx={{
            padding: "0",
            display: "flex",
            flexDirection: ["column", "column", "row"],
          }}
        >
          <div
            sx={{
              variant: "components.guideMeInfo",
              backgroundImage: `url(${greenback})`,
            }}
          >
            {this.info()}
          </div>

          <div
            sx={{
              variant: "components.guideMeQuestion",
            }}
          >
            {this.question()}
          </div>
        </div>

        <div id="all-tools" ref={this.allToolsRef}></div>
        {/* Tools */}
        <div
          sx={{ variant: "components.sectionSecondary", textAlign: "center" }}
        >
          <Themed.h1>View all our tools</Themed.h1>
          <div sx={{ lineHeight: 4.2 }}>
            <Link
              sx={{ variant: "links.ctaSecondary" }}
              href="/investment-tools"
            >
              Investment Tools
            </Link>
            <Link
              sx={{
                variant: "links.ctaTerciary",
              }}
              href="https://insights.goinvestgreen.com/resources/"
            >
              Useful Resources
            </Link>
          </div>
        </div>

        {/* Contact Us */}
        <div sx={{ variant: "components.sectionPrimary" }}>
          <SubscribeForm header="Keep In Touch" />
        </div>
      </div>
    );
  }
}

export default GuideMe;
