/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { Themed } from "theme-ui";
import BtnDownArrow from "../components/BtnDownArrow.jsx";
import LeafCard from "../components/pageComponents/LeafCard.jsx";
import LeafNumber from "../components/pageComponents/LeafNumber.jsx";
import PicCard from "../components/pageComponents/PicCard.jsx";
import heroImage from "../assets/heroImages/cityscape.jpg";
import providerLogos from "../assets/home/provider-logos.png";
import methodologyPerson from "../assets/home/methodology-person-p.svg";
import guideMePerson from "../assets/home/guide-me-person-p.svg";
import iKnowPerson from "../assets/home/i-know-person-p.svg";
import knowledgePerson from "../assets/home/knowledge-person-p.svg";

import SubscribeForm from "../components/subscribeForm/SubscribeForm";

const HomePage = ({ handleMinScrollValue }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    handleMinScrollValue(620);
  }, [handleMinScrollValue]);

  // const goToPensions = () => (window.location = "/workplace-pensions/home");

  window.localStorage.setItem("personal-pension-style", null);

  return (
    <div
      sx={{
        position: "relative",
        top: 0,
      }}
      className="Homepage"
    >
      {/* Hero */}
      <div sx={{ variant: "components.hero" }}>
        <div
          sx={{
            variant: "components.hero.background",
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div
            sx={{
              pt: ["33%", "20%", "16%"],
              ml: "10%",
              width: "80%",
              textAlign: "center",
            }}
          >
            <h1 sx={{ variant: "components.hero.h1Primary" }}>
              Use the power of your savings to build the green economy of the
              future
            </h1>
          </div>
          <div
            sx={{
              mt: "1.6em",
              textAlign: "center",
            }}
            className="Homepage-down-arrow-container"
          >
            <BtnDownArrow anchorUrl={"#get-started"} />
          </div>
        </div>
      </div>

      <div id="get-started"></div>
      {/* What is GIG? */}
      <div
        sx={{
          variant: "components.columnedSection",
          margin: "10% 0",
        }}
      >
        <div
          sx={{
            width: ["auto", "auto", "40%"],
            margin: ["0 5% 0 5%", "0 3% 0 5%", "0 3% 0 5%"],
          }}
        >
          <img
            src={providerLogos}
            alt={providerLogos}
            sx={{
              maxWidth: "100%",
            }}
          />
        </div>
        <div
          sx={{
            width: ["auto", "auto", "60%"],
            margin: ["0 5% 0 5%", "0 5% 0 3%", "0 5% 0 3%"],
          }}
        >
          <Themed.h1>What is Go Invest Green</Themed.h1>
          <Themed.p>
            Go Invest Green is a UK voluntary organisation offering easy-to-use
            tools to help you make sure your pension, mortgage and savings are
            funding the development of a net zero economy rather than the carbon
            intensive industries of the past.
          </Themed.p>
        </div>
      </div>
      {/* Get started */}
      <div sx={{ variant: "components.sectionSecondary" }}>
        <div
          sx={{
            mb: "4em",
            textAlign: "center",
          }}
        >
          <Themed.h1 sx={{ marginTop: "0" }}>Where Next?</Themed.h1>
        </div>
        <div sx={{ variant: "components.columnedCards" }}>
          <PicCard
            imageSrc={guideMePerson}
            title="Help me get started"
            text="I know nothing about green saving, investing or borrowing."
            linkLabel="Guide me..."
            linkHref="/guide-me-greener"
          ></PicCard>

          <PicCard
            imageSrc={iKnowPerson}
            title="Check my pension"
            text="Go straight to our green pensions tool"
            linkLabel="Get Started!"
            linkHref="/workplace-pensions"
          ></PicCard>

          <PicCard
            imageSrc={knowledgePerson}
            title="Green investment information"
            text="Go to our blog for friendly insights on green investment"
            linkLabel="Start Exploring."
            linkHref="https://insights.goinvestgreen.com"
          ></PicCard>
        </div>
      </div>
      {/* Why go Green? */}
      <div sx={{ variant: "components.sectionSecondary" }}>
        <div
          sx={{
            textAlign: "center",
            mb: "4em",
          }}
        >
          <Themed.h1 sx={{ marginTop: "0" }}>Greening your Pension</Themed.h1>
          <Themed.p>
            You may be surprised - and disappointed - to learn just where your
            pension savings are going. Our green pensions tool makes it easy for
            you to find where your pension is invested and how you can change
            it.
          </Themed.p>
        </div>

        <div sx={{ variant: "components.columnedCards" }}>
          <LeafCard
            title="Where is your money invested?"
            text="The majority of UK pensions still have substantial investments in fossil fuels and other polluting industries"
            linkLabel="Read more"
            linkHref="https://insights.goinvestgreen.com/2021/05/02/light-and-dark-green-pensions-and-everything-in-between/"
          ></LeafCard>

          <LeafCard
            title="Do green investments perform well?"
            text="A pension managed sustainably can perform as well - and better - than carbon heavy pensions"
            linkLabel="Read more"
            linkHref="https://insights.goinvestgreen.com/2021/05/02/could-i-lose-out-by-switching-to-a-greener-pension/"
          ></LeafCard>

          <LeafCard
            title="A pension is just a pension, right?"
            text="There are more good green pension options than ever before - including opportunities to design your own pension"
            linkLabel="Read more"
            linkHref="https://insights.goinvestgreen.com/2021/05/02/can-i-design-my-own-pension/"
          ></LeafCard>
        </div>
      </div>

      {/* Scary Image */}
      {/* <div sx= {{          
          width: '100%',
          position: 'relative'
        }}
      >
        <img src={factoryInset} sx={{ width: '100%'}} />
        <div sx={{position: 'absolute', top: '0', width: '100%', height: '100%',
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  paddingLeft: '6%'}}>
          <div sx={{}}>
            <Themed.h4 sx={{
                color: "white",
                textShadow: "2px 3px 1px #404040",
                fontSize: [4,4,5],
                marginTop: "0em",
                fontWeight: 'bold',
                marginBottom: '0.6em'
              
            }}>
            Is more of this<br/>the future you want?
            </Themed.h4>
            <Themed.p
              sx={{
                color: "white",
                textShadow: "2px 3px 1px #404040",
                fontSize: ["24px", "28px", "32px"],
                marginTop: "0em"

              }}
            >Your investments shouldn’t fund companies<br/>
              destroying the environment.
            </Themed.p>
          </div>
        </div>
      </div> */}

      {/* Methodology Section */}
      <div
        sx={{
          margin: "10% 0",
        }}
      >
        <div sx={{ variant: "components.columnedSection" }}>
          <div
            sx={{
              width: ["auto", "auto", "40%"],
              margin: "0 3% 0 5%",
            }}
          >
            <img
              src={methodologyPerson}
              alt={methodologyPerson}
              sx={{
                maxWidth: "100%",
                width: "100%",
              }}
            />
          </div>
          <div
            sx={{
              width: ["auto", "auto", "60%"],
              margin: "0 5% 0 3%",
            }}
          >
            <Themed.h1>How We Work</Themed.h1>
            <Themed.p>
              We’re not fund managers with a product to sell. It’s not our
              business to tell you how to save your money. But we are a group of
              financially literate individuals who have taken steps to green our
              own savings, and want to pass on what we’ve learned.
            </Themed.p>
          </div>
        </div>
      </div>

      <div
        sx={{
          marginTop: "10%",
          marginBottom: "10%",
          marginLeft: ["3%", "12%"],
          marginRight: ["3%", "12%"],
        }}
      >
        <div
          sx={{
            display: "flex",
          }}
        >
          <LeafNumber text="1"></LeafNumber>
          <div>
            <Themed.h4
              sx={{
                fontWeight: "500",
                marginBottom: "0.3em",
                marginTop: "-0.3em",
              }}
            >
              Passing on what we’ve found out
            </Themed.h4>
            <Themed.p sx={{ marginTop: "0" }}>
              We’ve studied the best research on the UK’s leading pension
              providers, and looked carefully at them ourselves
            </Themed.p>
          </div>
        </div>
        <div
          sx={{
            height: "5em",
            width: "100%",
            position: "relative",
            display: "block",
          }}
        >
          &nbsp;
        </div>
        <div
          sx={{
            display: "flex",
          }}
        >
          <LeafNumber text="2"></LeafNumber>
          <div>
            <Themed.h4
              sx={{
                fontWeight: "500",
                marginBottom: "0.3em",
                marginTop: "-0.3em",
              }}
            >
              Give insights based on assessments
            </Themed.h4>
            <Themed.p sx={{ marginTop: "0" }}>
              We rank each pension according to how green the underlying funds
              are, and how open providers are about where money is invested
            </Themed.p>
          </div>
        </div>
        <div
          sx={{
            height: "5em",
            width: "100%",
            position: "relative",
            display: "block",
          }}
        >
          &nbsp;
        </div>
        <div
          sx={{
            display: "flex",
          }}
        >
          <LeafNumber text="3"></LeafNumber>
          <div>
            <Themed.h4
              sx={{
                fontWeight: "500",
                marginBottom: "0.3em",
                marginTop: "-0.3em",
              }}
            >
              We aim to help you understand
            </Themed.h4>
            <Themed.p sx={{ marginTop: "0" }}>
              We maintain a blog passing on what we have learned about the world
              of green finance, and highlighting our plans for new services
            </Themed.p>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div>
        <SubscribeForm header="Keep In Touch" />
      </div>
    </div>
  );
};

export default HomePage;
