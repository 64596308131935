import { Link } from "react-router-dom";

export const postsContent = (data, idx) => {
  return [
    //post 0
    <>
      <p>
        As readers will know, the&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://goinvestgreen.org/"
          target="_blank"
        >
          Go Invest Green
        </a>
        &nbsp;team is developing an app to help users channel their pension
        towards funding the emerging green economy.
      </p>
      <p id="9e06">
        But pensions are just one kind of investment that can speed the energy
        transition.&nbsp;
        <a
          href="https://www.penguin.co.uk/books/319/319100/investing-to-save-the-planet/9780241457238.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          <em>Investing to Save the Planet</em>
        </a>
        , a new book by Financial Times journalist Alice Ross, offers a valuable
        introduction to the wider world of green finance. There are already
        plenty of guides introducing the basics of investment, but this is one
        of the first written for specifically for those interested in making
        their money work for a sustainable future.
      </p>
      <p id="3a71">
        Ross offers a lucid, measured, and up-to-date overview that takes
        account of how the pandemic has opened further opportunities for green
        investment. The book gathers the essentials of the voluminous green
        finance advice dispersed through financial journals and websites into a
        single 200 page paperback, introducing the kinds of investment avenues
        most accessible to private investors — shares, bonds and pensions — and
        discussing their most urgent questions: How do returns from a green
        portfolio compare with standard investments? Should green investors
        avoid carbon-generating companies completely? Just what counts as a
        green investment anyway?
      </p>
      <h2>What is and what isn’t green</h2>
      <p id="1f2b">
        As the opening chapter notes, the concept of using investment to
        encourage economic and social change isn’t new. The world’s great
        religions and wisdom traditions have always urged those with money to
        use it wisely: divestment by Quakers from shipping and plantation
        businesses helped bring an end to the slave trade; and the withholding
        of investment from banks and other companies entangled in the South
        African economy contributed to the collapse of apartheid.
      </p>
      <p id="f253">
        The first big wave of investment in the green economy in the 2000s, when
        billions were poured into solar, wind and other renewables, was
        interrupted by the financial crash. The current wave was spurred by the
        publication in 2015 of the Paris Agreement and the UN sustainable
        development goals, and has continued to gather momentum: in 2019 US
        investors channeled four times as much and European investors twice as
        much into sustainable funds as they had in the previous year.
      </p>
      <p id="cae6">
        The surge in green investment is not just due to increasing concern over
        climate change but also sustained evidence that it secures good returns.
        Green shares have outperformed their peers on the world’s stock markets
        over the past five years, and a commitment to sustainability is
        increasingly considered an indication of a well managed enterprise
        attractive to all investors, green or otherwise.
      </p>
      <p id="216c">
        The trend has generated an ever more extensive green investment lexicon
        used by companies, trusts and funds to burnish their environmental
        credentials. Ross spends much of the book seeking to guide the reader
        through the confusion of labels that have attached themselves to
        investment products.
      </p>
      <p id="8ee0">
        A ‘sustainable’ fund, for example, does not necessarily consist solely
        of what we might normally think of as green companies. Indeed they may
        have none, being made up instead of ‘best in class’ businesses fund
        managers consider to be making good environmental progress relative to
        their peers. Many funds for example have holdings in tech giants like
        Microsoft or Apple that have made well publicised commitments to
        greening their day-to-day operations, and might even include those
        fossil fuel companies deemed to have better environmental records than
        their competitors.
      </p>
      <p id="6d82">
        The now ubiquitous ‘ESG’ label can also confuse. Often misunderstood as
        referring exclusively to environmentally sound companies, it actually
        applies to companies that score well according to ‘environmental’,
        ’social’&nbsp;<em>or</em>
        &nbsp;‘governance’ criteria. A company might have a good ESG rating
        because of a commitment to social responsibility — it may treat its
        workers and the communities in which it operates well — or because it is
        considered to practice good governance, observing modest pay
        differentials, or good financial management.
      </p>
      <p id="6172">
        Caution must be exercised even when a firm is deemed to be ‘green’:
        there are no established industry standards for how to measure
        sustainability. The most direct way might be to index companies
        according to their carbon emissions, and a convention has emerged for
        identifying the different kinds of emissions firms generate. As Ross
        puts it: ‘Scope 1 emissions are direct emissions from things that
        companies own. Scope 2 covers indirect emissions from the electricity or
        other power used by the company. Scope 3 covers other indirect emissions
        in a company’s supply chain and also, in the case of an oil and gas
        company, for example, the emissions caused by consumers when they use
        the energy.’ But currently most companies disclose at most their scope 1
        and scope 2 emissions — and analysts estimate that 90 per cent fall
        within the scope 3 category.
      </p>
      <p id="7bfd">
        Better metrics are evolving: this year the European Union published
        guidelines for a sustainable finance taxonomy that will include
        requirements for all companies to disclose scope 3 emissions, and
        central banks are beginning to ask companies to undertake climate change
        ‘stress tests’ requiring them to report on their status relative to the
        Paris Agreement targets.
      </p>
      <h2>Exercising judgement</h2>
      <p id="69f6">
        But Ross notes that even if some kind of carbon index does establish
        itself, true green investment will still require the investor to
        exercise their own judgement. Some industries may generate relatively
        high levels of carbon and yet form part of a thoughtfully constructed
        green portfolio.
      </p>
      <p id="5e35">
        Mining, for example, is a carbon intensive industry — with often dubious
        labour practices — that is nevertheless critical to the energy
        transition, extracting lithium, cobalt, and other metals and rare earths
        used to make renewable technologies like storage batteries and solar
        photovoltaic cells. Other sectors seem unambiguously green but score
        badly on other criteria: tech companies may have set groundbreaking net
        zero targets but they produce a relentless churn of products that
        generate mountains of e-waste. And electric vehicle manufacturers
        consume formidable amounts of raw materials and are notorious for the
        demands they make on their employees. Ross asks, with — I’m sure —
        nobody particular in mind, that ‘If a company was aiming to do great
        things for the environment by creating electric cars, would you still
        want to invest in it if it were run by a person who seemed a little
        unhinged and if it treated its workers badly?’
      </p>
      <p id="344f">
        Investors must also consider the possibility that effective green
        investment might be achieved by taking a stake in companies in the
        process of making — sometimes labyrinthine — journeys towards becoming
        green, and not just those pioneering sustainable technologies.
      </p>
      <p id="399d">
        Most of the leading oil and gas companies, for example, are actually
        well placed to make formidable contributions to the energy transition.
        With their engineering expertise, established infrastructures, and
        financial reserves, they have the firepower to lead the development of
        new sustainable fuels such as green hydrogen, and the carbon capture
        techniques necessary for drawing down and storing carbon already
        accumulated in the atmosphere.
      </p>
      <p id="13e7">
        The record of the oil majors so far certainly leaves much to be desired:
        to date they have invested less than one per cent of their total capital
        spending on renewable energy. But Orsted, Denmark’s national oil company
        turned green energy powerhouse, shows what is possible. In the space of
        just a few years the corporation has turned itself into the world’s
        largest renewables business, a multi-billion programme of investment in
        wind and solar making it a favourite with investors, boosting its value
        to nearly half that of BP. And BP itself may be gradually following
        suit. Earlier this year the corporation announced plans to cut its
        carbon emissions to net zero by 2050, the most ambitious climate target
        yet set by a major oil company.
      </p>
      <p id="eea6">
        So investing in the energy transition can mean investing in those
        companies making the transition, as well as those leading the field.
        Indeed Ross is sceptical about the effectiveness of simply divesting
        altogether from carbon-intensive sectors. The hard logic of the markets
        is that shares which are sold always have a buyer: if a company’s share
        price falls low enough it will entice investors less concerned about
        sustainability to step in and take advantage of a bargain. This year,
        when fossil fuel companies have been left exposed by the drastic fall in
        oil prices caused by the pandemic, may be an ideal time for activist
        investors to turn the screw by taking or retaining their stakes and
        ramping up demands for change.
      </p>
      <h2>The expanding green finance universe</h2>
      <p id="094f">
        The book’s central chapters offer something of a whirlwind tour of the
        rapidly growing range of opportunities available for investing in
        emerging green businesses. These include not just those in the now
        familiar renewable sector, but new fuels like the aforementioned
        hydrogen, critical for greening modes of transport like shipping and
        flight that cannot be easily be electrified; storage batteries that
        allow the energy generated by wind and solar to be used when the air is
        still and the sun has gone down; alternative meats and milks cultivated
        in labs rather than through carbon-intensive cattle farming; new methods
        for growing food indoors that cut the need for pesticides and ease the
        burden on land (in a fascinating aside Ross notes that one such
        ‘vertical farming’ company is growing food in abandoned London
        underground tunnels); and innovations for optimising energy efficiency,
        including a new generation of low-power air conditioners and electric
        boilers.
      </p>
      <p id="a63a">
        Discussing how investors might take stakes in these enterprises Ross
        pays equal attention to bonds as well as shares. Investors with long
        time horizons — spanning decades rather than years — will want to put
        most of their savings into shares, the value of which are more likely to
        accumulate significantly over time. But bonds issued by companies and
        governments to finance projects, traditionally held by investors as a
        store of value to guard against periods when share prices fall, can be
        particularly effective for holding companies to account. Ross observes
        that ‘One criticism of equities is that they are simply a sliver of a
        share in a company: owning them doesn’t direct your money within that
        company. But corporate bonds can be used for specific things, and thus
        can give you greater clarity over how your money is being used … There
        is an immediacy to the way a company needs a bond to be refinanced that
        can focus the mind.’ ‘Green’ or ‘transition’ bonds earmarked
        specifically for sustainable projects are being issued more frequently.
      </p>
      <p id="751e">
        Ross also discusses pensions, offering similar advice to that given by
        Go Invest Green. Those with workplace pensions should note that they may
        well have the option of an ethical as well as a default fund: if they
        have not been given the option or are unimpressed by what it offers then
        it is worth their taking up the matter with their HR department or
        writing a letter to the company’s pension trustees — a little action can
        go a long way.
      </p>
      <p id="dca7">
        Given that pensions are the only investment that most people will
        currently or ever hold the book might have devoted more pages to
        consideration of the green merits of the most common workplace pensions,
        and the possibilities that self-invested pensions — SIPPs — can afford
        for taking full control of how savings are directed.
      </p>
      <p id="07f8">
        But the rest of the book provides valuable tools for interpreting how
        green a pension might be and, through its extensive coverage of green
        shares, bonds, trusts and funds, many suggestions as to how a
        self-invested pension might be constructed. If you’re interested in
        using the Go Invest Green app and going on to find out more about the
        wider world of green investment we can certainly recommend&nbsp;
        <a
          href="https://www.penguin.co.uk/books/319/319100/investing-to-save-the-planet/9780241457238.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          <em>Investing to Save the Planet</em>
        </a>
        &nbsp;as an excellent starting point.
      </p>
      <p id="2b53">
        For a sampler of the book see Alice Ross’s&nbsp;
        <a
          href="https://www.ft.com/investingtosavetheplanet"
          target="_blank"
          rel="noreferrer noopener"
        >
          introductory article on the Financial Times website
        </a>
        &nbsp;and a recent interview on the&nbsp;
        <a
          href="https://www.youtube.com/watch?v=kfnxWZe9uEo"
          target="_blank"
          rel="noreferrer noopener"
        >
          Money to the Masses YouTube channel
        </a>
        .
      </p>
      <p id="7e49">
        <a
          href="https://www.penguin.co.uk/books/319/319100/investing-to-save-the-planet/9780241457238.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          <em>Investing to Save the Planet</em>
        </a>
        <em>&nbsp;by Alice Ross is published by Penguin.</em>
      </p>
      <footer className="entry-meta">
        <span className="cat-links">
          <span className="gp-icon icon-categories">
            <svg
              viewBox="0 0 512 512"
              aria-hidden="true"
              role="img"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1em"
              height="1em"
            >
              <path
                d="M0 112c0-26.51 21.49-48 48-48h110.014a48 48 0 0 1 43.592 27.907l12.349 26.791A16 16 0 0 0 228.486 128H464c26.51 0 48 21.49 48 48v224c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112z"
                fillRule="nonzero"
              ></path>
            </svg>
          </span>
          <span className="screen-reader-text">Categories </span>
          <a
            href="https://insights.goinvestgreen.com/category/pensions/"
            rel="category tag"
          >
            Pensions
          </a>
          ,
          <a
            href="https://insights.goinvestgreen.com/category/reviews/"
            rel="category tag"
          >
            Reviews
          </a>
          ,{" "}
          <a
            href="https://insights.goinvestgreen.com/category/stocks-and-shares/"
            rel="category tag"
          >
            Stocks and shares
          </a>
        </span>{" "}
        <nav id="nav-below" className="post-navigation">
          <span className="screen-reader-text">Post navigation</span>
          <div className="nav-next">
            <span className="gp-icon icon-arrow-right">
              <svg
                viewBox="0 0 192 512"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path
                  d="M178.425 256.001c0 2.266-1.133 4.815-2.832 6.515L43.599 394.509c-1.7 1.7-4.248 2.833-6.514 2.833s-4.816-1.133-6.515-2.833l-14.163-14.162c-1.699-1.7-2.832-3.966-2.832-6.515 0-2.266 1.133-4.815 2.832-6.515l111.317-111.316L16.407 144.685c-1.699-1.7-2.832-4.249-2.832-6.515s1.133-4.815 2.832-6.515l14.163-14.162c1.7-1.7 4.249-2.833 6.515-2.833s4.815 1.133 6.514 2.833l131.994 131.993c1.7 1.7 2.832 4.249 2.832 6.515z"
                  fillRule="nonzero"
                ></path>
              </svg>
            </span>
            <span className="next" title="Next">
              <a
                href="https://insights.goinvestgreen.com/2020/12/20/go-invest-green-how-we-can-make-our-savings-work-for-a-sustainable-world/"
                rel="next"
              >
                Go Invest Green: how we can make our savings work for a
                sustainable world
              </a>
            </span>
          </div>
        </nav>
      </footer>
    </>,
    //post 1
    <>
      <p id="acb9">
        There are two broad paths to forcing the transition to an ecologically
        sustainable global economy.
      </p>
      <p id="a0bd">
        One is to rewire the system. It’s happening — gradually. We’ve known
        since the 1960s that carbon buildup is heating the atmosphere. As the
        science has become clearer and the political will has been slowly
        summoned, governments and international institutions are engineering
        incentives and toughening laws to shift the world towards more
        sustainable forms of economic production.
      </p>
      <p id="b68a">
        It isn’t yet clear how profound the restructuring will have to be. Can a
        competitive global marketplace premised on constant exponential growth
        ever allow long-term sustainability? Perhaps in the future some other
        economic model might emerge — maybe a circular economy with AI and
        machine learning technologies playing a greater role in the allocation
        of resources.
      </p>
      <p id="e938">
        The other path to change is to work with what we’ve got: whatever
        mechanisms the current system affords for moving towards sustainability.
        In a world driven by the exchange of money, that means redirecting where
        money goes, away from extractive and towards green economics. Just as we
        can use our vote to elect politicians we hope will change the way our
        economies are structured, we can use our money to vote for the way we
        want our goods and services to be supplied. The trend towards green
        consumerism over the past few decades illustrates the power we can
        exercise through our spending choices. We can also force change through
        what we don’t spend: our savings.
      </p>
      <h2 id="e1dd">Where our money goes</h2>
      <p id="7822">
        Whether we know how our money is used or not — and most of us don’t —
        our savings shape the structure of the world economy. Money that we
        might think is sitting quietly in the bank, or accumulating patiently in
        a pension fund, is in fact hyperactive, invested and re-invested
        according to criteria set by the finance professionals who manage it.
        Unless we take the time to understand how our savings are being used it
        is very likely being employed in ways that might disturb us.
      </p>
      <p id="7222">
        Most of the money we put away in savings accounts or pensions is
        invested in the world’s financial markets. The colossal importance of
        those markets for shaping the world economy has been vividly illustrated
        by their extraordinary performance during the pandemic. We are suffering
        the worst global economic downturn since the Great Depression, against
        the backdrop of geopolitical tensions between the US and China, the
        world’s largest economies. And yet
        <a
          href="https://www.bbc.co.uk/news/business-52239979"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;stocks and shares are soaring
        </a>
        . The Dow Jones 500, the index listing the largest publicly traded
        companies in the US, has risen by a fifth since April, delivering its
        highest quarterly increase for more than 20 years.
      </p>
      <p id="93f9">
        That’s partly due to a sense of invincibility amongst investors
        confident that, as with the 2008 financial crisis, governments will
        always come to the rescue of the markets in times of strife. It’s also
        because, quite simply in a world in which interest rates are low in most
        of the major economies, investors seeking a return have nowhere else to
        put their money other than to pile it into stocks.
      </p>
      <p id="fc5f">
        The investment decisions they make give them enormous influence over
        what the world produces. Boardrooms are at least as preoccupied with
        attracting and keeping shareholders as with the economic fundamentals of
        their businesses. And unfortunately some of the world’s carbon producers
        have traditionally offered the most attractive investment vehicles.
      </p>
      <p id="4b26">
        Fossil fuel equities have long been at the heart of most portfolios,
        including mainstream pension funds, because of the world’s dependence on
        oil and gas and the attractive dividend payments such shares offer. The
        dependence of the savings of so many ordinary voters on hydrocarbons
        stocks is one of the less discussed reasons why governments have not
        been tougher on regulating emissions. But when investors themselves move
        against those carbon stocks, things can change — rapidly.
      </p>
      <h2 id="493d">The new green focus on finance</h2>
      <p id="4e42">
        Green campaign groups are increasingly focused on finance, targeting the
        investment patterns of big institutional investors like banks, asset
        managers and insurers.
        <a
          href="https://www.ft.com/content/6dceb82a-1084-40bf-91cb-7cb6100f3992"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;Climate-related resolutions have been passed
        </a>
        &nbsp;at shareholder meetings of big names including JP Morgan, Goldman
        Sachs, BlackRock, Bank of America and Barclays. In response to pressure
        from campaigners, governments and — crucially — ordinary investors, fund
        managers are demanding more of the businesses in which they invest.
      </p>
      <p id="fb3e">
        The
        <a
          href="http://www.climateaction100.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;Climate Action 100+
        </a>
        &nbsp;initiative, for example, a group of 370 fund managers and asset
        owners representing $35tn is urging some of the most profligate emitters
        of greenhouse gases to reduce their environmental impact. And earlier
        this summer the
        <a
          href="https://www.iigcc.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;Institutional Investors Group on Climate Change
        </a>
        , representing more than 230 asset managers, welcomed a recent
        <a
          href="https://www.iea.org/reports/global-energy-review-2020"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;International Energy Agency report
        </a>
        &nbsp;advocating Green New Deal-style stimulus packages for a green
        transition.
      </p>
      <p id="ca76">
        A new market of for investment products ranked according to conformance
        with&nbsp;
        <a
          href="https://www.investopedia.com/terms/e/environmental-social-and-governance-esg-criteria.asp"
          target="_blank"
          rel="noreferrer noopener"
        >
          Environmental, Social and Governance
        </a>
        &nbsp;(ESG) criterias has emerged to cater for investors concerned to
        ensure their money serves more sustainable businesses. They can choose
        from hundreds of ‘sustainable’ or ‘socially responsible’ funds offering
        exposure to every trend within the renewables sector — wind, solar,
        batteries, electric cars. Or they can simply select ‘ethical’ funds that
        do not include fossil fuels and other ’sin stocks’. The value of energy
        companies has fallen as more investors take their money elsewhere:
        energy companies now comprise less than 5% of the Dow Jones 500 index of
        big US companies, down from 11% a decade ago.
      </p>
      <p id="3988">
        Investor pressure is putting pressure on all companies — not just the
        oil and gas producers themselves — to reduce their carbon footprint. All
        manner of businesses have begun making so-called net zero commitments,
        pledging to cut or offset emissions by taking an equivalent amount out
        of the atmosphere through carbon capture and other technologies. The
        ‘FAANGs’ — Facebook, Amazon, Apple, Netflix and Google — and other tech
        giants have all made
        <a
          href="https://www.wired.co.uk/article/corporate-greenwashing"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;eye-catching pledges to achieve net zero
        </a>
        &nbsp;within the next few years, with other corporations like Barclays,
        Nestlé, and the mining and airline groups Vale and IAG following suit,
        at varying degrees of speed.
      </p>
      <h2 id="db11">Supercharging oil’s decline</h2>
      <p id="9746">
        The Covid-19 pandemic has supercharged the trend towards green
        investment. The oil and gas industry has been hit particularly hard by
        the economic downturn, with plunging demand for oil sending prices to
        the floor. If the structural changes to the economy forced by the
        lockdown — the increase in teleworking, reduced commuting, more
        localised supply chains — become entrenched, the damage to the fossil
        fuel sector may be permanent, as some of the oil and gas majors
        themselves have acknowledged. BP, for example, has warned investors it
        has had to write down the value of its assets by $17.5bn (some 20% of
        company’s value) conceding that permanently lower oil prices mean that
        much of the hydrocarbon reserve it had earmarked for exploration will
        have to be kept in the ground.
      </p>
      <p id="858f">
        BP’s announcement earlier this year that it intends to become
        <a
          href="https://www.greenbiz.com/article/bp-announces-net-zero-2050-ambition"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;a net zero carbon company by 2050
        </a>
        &nbsp;was met with scepticism and not a little amusement. But that
        commitment is now looking much more serious. Indeed the oil majors are
        well positioned to become leading green energy providers: their
        accumulated engineering expertise makes them well equipped to develop
        major wind and solar projects, and they own sprawling networks of gas
        pipelines that could be repurposed for hydrogen and other low-carbon
        fuels.
      </p>
      <p id="900e">
        Orsted, the offshore wind giant, is an example of one such corporation
        that has made the transition to renewables. Once Denmark’s national oil
        company, Orsted became
        <a
          href="https://www.ft.com/content/74b377c8-4435-11ea-abea-0c7a29cd66fe"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;the first fossil fuel producer to move away from its traditional
          business to green energy
        </a>
        . It is now worth $46bn, making it nearly half as big as BP, showing the
        value of being invested in companies ahead of the curve.
      </p>
      <h2 id="014d">What we can do</h2>
      <p id="f973">
        Ordinary investors like us can speed the accelerating energy transition
        by ensuring our savings are invested in new technologies and industries,
        rather than propping up old ones. We can do as much or as little as we
        are able. The simplest thing we could do is move our cash savings or —
        if we have one — mortgage to an ethical bank. If we want to commit to
        taking full control of our savings there are many investment platforms
        that allow us to invest in exactly those companies, sectors and
        countries we want to support. But the most powerful and practical step
        for many of us would be to check what our pension is going towards, and,
        if we don’t like it, to switch to a greener fund or explore whether a
        change in the pension’s investment policy is possible.
      </p>
      <p id="b93d">
        One lingering concern about doing so is the still widespread perception
        that green pensions are luxuries for those who are already financially
        secure. But as green investment options have matured there is ample
        evidence that environmentally-sensitive pension funds are financially as
        well as ethically sound. A major study published earlier this year, for
        example, found that sustainable funds have over the past decade actually
        outperformed and outlasted equivalent conventional funds.
      </p>
      <p id="dfb9">
        Another issue is the difficulty of navigating the huge amount of often
        conflicting information available about green investment. The world of
        finance is notoriously opaque. And as companies and fund managers rush
        to assert their green credentials, it can be very difficult to know who
        to trust. As ethical investment has become more popular the problem of
        ‘greenwashing’ has become ever more perplexing.
      </p>
      <p id="b39e">
        Put simply, methodologies for assessing a company or fund’s ESG
        credentials are still evolving. In the absence of agreed standards it’s
        possible to present all kinds of misleading environmental metrics. Most
        of the available data about companies’ carbon footprints, for example,
        is self-reported, and not independently audited. Well publicised
        scandals such as the
        <a
          href="https://www.bbc.co.uk/news/business-34324772"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;Volkswagen diesel emissions fraud
        </a>
        &nbsp;demonstrate how firms can flaunt their supposed green credentials
        even as they seek to game the system.
      </p>
      <p id="4174">
        Things are getting better. The industry is developing environmental
        reporting and assessment processes comparable to the sophisticated
        (though still imperfect) metrics it has refined for reporting financial
        performance. One major initiative, the
        <a
          href="https://www.fsb-tcfd.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          &nbsp;Task Force on Climate-Related Financial Disclosures
        </a>
        &nbsp;led by Mark Carney, the former governor of the Bank of England,
        has signed up more than a thousand organisations. All financial
        institutions are under increasing pressure to disclose the carbon
        emissions of their loans and investments.
      </p>
      <h2 id="0902">
        Go Invest Green — a Climate Venture Collective initiative
      </h2>
      <p id="f674">
        If you are interested in investigating the use of your savings, the
        Climate Venture Collective’s&nbsp;
        <Link to="/" rel="noreferrer noopener">
          Go Invest Green
        </Link>
        &nbsp;initiative can help. The project is developing a tool to give you
        an idea of how green your pension is by making it easy for you to
        compare it with the UK’s most common pension plans. You may be
        pleasantly surprised — your pension may well be perfectly good enough.
        Or the comparison check might encourage you to check out other pension
        options.
      </p>
      <p id="698f">
        Visit&nbsp;
        <Link to="/" target="_blank" rel="noreferrer noopener">
          Go Invest Green
        </Link>
        &nbsp;to sign-up for email notification when the tool goes live, and to
        indicate whether you would like to trial the tool. You can also follow
        us&nbsp;
        <a
          href="https://twitter.com/goinvestgreen"
          target="_blank"
          rel="noreferrer noopener"
        >
          @goinvestgreen
        </a>
        . We’ve picked out some of the most useful online resources currently
        available below.
      </p>
      <h2 id="1cbb">Further information</h2>
      <ul>
        <li>
          <a
            rel="noreferrer noopener"
            href="https://switchit.money/"
            target="_blank"
          >
            Earth Day Switch
          </a>
          &nbsp;— Consider switching your bank account here
        </li>
        <li>
          <a
            rel="noreferrer noopener"
            href="https://makemymoneymatter.co.uk/"
            target="_blank"
          >
            Make My Money Matter
          </a>
          &nbsp;— a new campaign fighting for a world where we all know where
          our pension money goes
        </li>
        <li>
          <a
            rel="noreferrer noopener"
            href="https://shareaction.org/pensions/"
            target="_blank"
          >
            ShareAction
          </a>
          &nbsp;— Extensive information on responsible pensions and actions you
          can take if yours needs to be better
        </li>
        <li>
          <a
            rel="noreferrer noopener"
            href="https://good-with-money.com/2019/08/20/the-good-guide-to-pensions/"
            target="_blank"
          >
            The Good Guide to Pensions
          </a>
          &nbsp;— excellent ‘how to’ information from the Good With Money
          website
        </li>
      </ul>
      <p></p>
      <footer className="entry-meta">
        <span className="cat-links">
          <span className="gp-icon icon-categories">
            <svg
              viewBox="0 0 512 512"
              aria-hidden="true"
              role="img"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1em"
              height="1em"
            >
              <path
                d="M0 112c0-26.51 21.49-48 48-48h110.014a48 48 0 0 1 43.592 27.907l12.349 26.791A16 16 0 0 0 228.486 128H464c26.51 0 48 21.49 48 48v224c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112z"
                fillRule="nonzero"
              ></path>
            </svg>
          </span>
          <span className="screen-reader-text">Categories </span>
          <a
            href="https://insights.goinvestgreen.com/category/go-invest-green/"
            rel="category tag"
          >
            Go Invest Green
          </a>
          <a
            href="https://insights.goinvestgreen.com/category/pensions/"
            rel="category tag"
          >
            Pensions
          </a>
        </span>{" "}
        <nav id="nav-below" className="post-navigation">
          <span className="screen-reader-text">Post navigation</span>
          <div className="nav-previous">
            <span className="gp-icon icon-arrow-left">
              <svg
                viewBox="0 0 192 512"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path
                  d="M178.425 138.212c0 2.265-1.133 4.813-2.832 6.512L64.276 256.001l111.317 111.277c1.7 1.7 2.832 4.247 2.832 6.513 0 2.265-1.133 4.813-2.832 6.512L161.43 394.46c-1.7 1.7-4.249 2.832-6.514 2.832-2.266 0-4.816-1.133-6.515-2.832L16.407 262.514c-1.699-1.7-2.832-4.248-2.832-6.513 0-2.265 1.133-4.813 2.832-6.512l131.994-131.947c1.7-1.699 4.249-2.831 6.515-2.831 2.265 0 4.815 1.132 6.514 2.831l14.163 14.157c1.7 1.7 2.832 3.965 2.832 6.513z"
                  fillRule="nonzero"
                ></path>
              </svg>
            </span>
            <span className="prev" title="Previous">
              <a
                href="https://insights.goinvestgreen.com/2020/12/08/investing-to-save-the-planet-a-review-of-a-new-guide-to-the-world-of-green-finance/"
                rel="prev"
              >
                Investing to Save the Planet: a review of a new guide to the
                world of green finance
              </a>
            </span>
          </div>
          <div className="nav-next">
            <span className="gp-icon icon-arrow-right">
              <svg
                viewBox="0 0 192 512"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path
                  d="M178.425 256.001c0 2.266-1.133 4.815-2.832 6.515L43.599 394.509c-1.7 1.7-4.248 2.833-6.514 2.833s-4.816-1.133-6.515-2.833l-14.163-14.162c-1.699-1.7-2.832-3.966-2.832-6.515 0-2.266 1.133-4.815 2.832-6.515l111.317-111.316L16.407 144.685c-1.699-1.7-2.832-4.249-2.832-6.515s1.133-4.815 2.832-6.515l14.163-14.162c1.7-1.7 4.249-2.833 6.515-2.833s4.815 1.133 6.514 2.833l131.994 131.993c1.7 1.7 2.832 4.249 2.832 6.515z"
                  fillRule="nonzero"
                ></path>
              </svg>
            </span>
            <span className="next" title="Next">
              <a
                href="https://insights.goinvestgreen.com/2021/01/08/a-new-year-a-greener-you/"
                rel="next"
              >
                A new year, a greener you
              </a>
            </span>
          </div>
        </nav>
      </footer>
    </>,
    //post 2
    <>
      <p>
        Have you made a New Year’s resolution to invest in yourself? To get
        fitter, change your diet, or pursue some other goal? Are you keeping it?
        Here’s something else you might not have thought of, but could start
        doing today: putting your savings to use for the benefit of the planet.
      </p>

      <p>
        As well as aiming for a ‘dry January’ or ‘Veganuary’ you could look into
        how you could make your money work for the energy transition. Our
        pension assessment app will help you get started.
      </p>

      <h2>Making your money work for a green future</h2>

      <p>
        You may think finance is complicated and a little dull. But the money we
        are setting aside for our futures can help make a big difference to the
        kind of economy we’ll live in when we’re ready to retire. Our
        investments power economic change, and used wisely can make for a
        greener future.
      </p>

      <p>
        One of the most effective things we can do to invest in the emerging
        green economy is to direct our pension to environmentally sensitive
        funds. More and more pension providers are offering green investment
        options that can help fund a sustainable world as well as your financial
        future: the two can most certainly go hand-in-hand.
      </p>

      <p>
        Our pension app will help you find out how green your current pension
        is, and what actions you can take to change it. You can use it to find
        out what your pension is funding and whether you want to make a change.
      </p>

      <h2>Cutting through financial jargon</h2>

      <p>
        We’ve taken a close look at the UK’s top 10 pension funds, cutting
        through financial jargon to give you an idea of where your money is
        really going. It isn’t always possible to get a clear idea – our
        pensions are not as transparent as they should be (although things are
        getting better). But we’ve done our best to find out, and to present
        what we’ve found in clear language. We’re a team of volunteers, some
        with many years experience in the financial sector, who’ve taken steps
        to green our own savings.
      </p>

      <p>
        Our pensions tool will be ready soon. In the meantime you can follow us
        here, and on <a href="http://twitter.com/goinvestgreen">Twitter</a> and{" "}
        <a href="https://www.linkedin.com/company/go-invest-green/about/">
          LinkedIn
        </a>
        <em>.</em>
      </p>

      <p>
        <em>
          Photo by{" "}
          <a href="https://unsplash.com/@brookecagle?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Brooke Cagle
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/office?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
          .
        </em>
      </p>
    </>,
    //post 3
    <>
      <p id="c111">
        Perhaps I’ve read too much science fiction, but I have long been
        fascinated by the future, and impatient for its arrival. I want to see
        the advanced, ecologically sustainable world that technological
        innovation indicates we could have: cars, trains, ships and airplanes
        powered by batteries and hydrogen fuel cells; gleaming solar and wind
        farms; artificial proteins cultivated without animal slaughter; garden
        cities; the automation of humdrum work and more free time.
      </p>

      <p id="a00d">
        We’re getting there, but it could be happening faster. I’ve participated
        in some of the usual political channels for trying to make it happen,
        but it took me years to realise there’s another, powerful way we as
        individuals can help force change: investing our savings directly in
        companies pioneering green technologies. Investment works with the grain
        of a world driven, whether we like it or not, by finance and global
        markets.
      </p>

      <h2>Taking control with ISAs and SIPPs</h2>

      <p>
        I got started with investment by putting what I could afford into
        an&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://www.moneysupermarket.com/investments/stocks-shares-isas/"
          data-type="URL"
          data-id="https://www.moneysupermarket.com/investments/stocks-shares-isas/"
          target="_blank"
        >
          Individual Savings Account
        </a>{" "}
        — usually known as an ISA — that allowed me to invest up to £20,000 a
        year tax free in stocks and shares, or just keep it as cash. Choosing
        the investments that went into it proved to be much more interesting
        than I had expected. If you look into it there are a huge range of
        options out there for specifying exactly where your money goes: not just
        shares in particular companies, but literally thousands of funds and
        trusts dedicated to every sector of the global economy, and every shade
        of green technology.
      </p>

      <p id="659c">
        Following advice about investing cautiously — which I’ll discuss in a
        future article — I picked out a few funds and started seeing some
        returns, on the green investments just as much as the more mainstream
        choices. So, being rather unhappy with what had been painstakingly
        accumulating in the private pension I had started quite a few years ago,
        I started looking into a less well known investment vehicle, the&nbsp;
        <a
          href="https://www.pensionsadvisoryservice.org.uk/about-pensions/pensions-basics/contract-based-schemes/self-invested-personal-pensions-sipp"
          target="_blank"
          rel="noreferrer noopener"
        >
          Self-Invested Personal Pension
        </a>
        , usually referred to as a SIPP.
      </p>

      <p id="6b3a">
        A SIPP combines the flexibility of an stocks and shares ISA with the
        long-term outlook of pension plan. You might call it a DIY pension
        scheme: instead of handing your money over to a pension fund manager to
        invest on your behalf you choose your own shares, funds and trusts, just
        like a stocks and shares ISA.
      </p>

      <p id="a12c">
        A SIPP has the tax benefits that apply to other kinds of pensions, with
        the Government paying 20% tax relief on your contributions. You can
        contribute up to 100% of your annual income, or up to £2,880 a year if
        you have no UK earnings. It’s quite a way off for most of us, but it’s
        worth knowing you can add up to £40,000 a year to it, and up to
        £1,073,100 over a lifetime. And it is all tax free — as with an ISA you
        won’t be taxed on any of the money that builds up in it.
      </p>

      <p id="0ce9">
        A SIPP can run alongside, or even replace, an occupational pension
        scheme. You can ask your employer to pay some or all of your workplace
        pension into it. If you are self-employed it can run alongside or
        replace a personal pension scheme. Your savings have to stay in your
        SIPP until you are 55 (rising to 57 from 2028) when you can use some or
        all of them to buy a pension annuity, or just start withdrawing the
        money.
      </p>

      <p id="d189">
        After my positive experience with the ISA I decided to go ahead and
        transfer my personal pension to a SIPP. There are different ways of
        doing it.&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://www.moneysavingexpert.com/savings/stocks-shares-isas/"
          target="_blank"
        >
          I use an investor platform for managing my ISA
        </a>
        , which like most platforms has an option for starting a self-invested
        pension. They made it quite easy to set up. I just gave the platform the
        basic information about my personal pension and they handled the
        transfer process in a couple of weeks or so. I pay them a monthly fee of
        a few pounds.
      </p>

      <h2>Choosing with care</h2>

      <p id="1cbe">
        I can’t say I didn’t hesitate. There’s a lot to be said for just handing
        over something as important as your pension to a fund manager. But if
        you value the freedom of selecting your own investments, and are
        prepared to do&nbsp;
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://medium.com/go-invest-green/investing-to-save-the-planet-a-review-of-a-new-guide-to-the-world-of-green-finance-13bdcb8517e1"
        >
          a bit of reading about what’s worth putting your money into
        </a>
        , a SIPP can work very well. Remember that no-one cares more about your
        savings than you do yourself. Financial professionals will only do so
        much for you.
      </p>

      <p id="3c16">
        I really value the freedom of choosing between the huge range of
        investment options available. I’ve gradually built up a portfolio that
        blends a wide range of sustainable technologies, including solar
        photovoltaic panels, wind turbines, hydrogen fuel cells, flow batteries,
        public transport, lab grown foods, and electric vehicles. And I’ve
        indulged in a few rather more speculative investments. I coudn’t resist
        putting a little bit into space exploration, for example. Things like
        satellites that deliver global broadband connections and offer new
        insights in weather forecasting, global logistics, crop harvesting,
        insurance pricing and disaster response data. And one or two
        zero-gravity manufacturing technologies investigating new drug
        structures, possibilities for growing organs for transplant, and strong
        ultra-thin new materials.
      </p>

      <p id="56a8">
        As we’ll discuss in future articles, you do need to be careful: there’s
        a lot of highly speculative stuff that sounds great but won’t yield much
        of a return for some time, if ever. You can tread cautiously by
        investing in funds and trusts rather than directly in companies. These
        allow you to invest in a particular sector without having to pick the
        individual shares yourself: you entrust those decisions to the fund
        manager. You can also invest in&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://www.investopedia.com/terms/e/etf.asp"
          target="_blank"
        >
          Exchange Traded Funds
        </a>
        , usually known as ETFs, which track a certain industry sector without
        an active fund manager: battery technologies, say, or solar power.
      </p>

      <h2>Everyday futurism</h2>

      <p>
        Investing your pension yourself isn’t for everyone. You do need to spend
        a bit of time looking into what stocks might make for good returns. And
        you need to keep an eye on your choices and be prepared to change things
        if one isn’t working out. But for me starting a SIPP has made the rather
        dry business of building up savings rather addictive. Choosing where to
        put my money among the funds showcasing tomorrow’s economy is a kind of
        everyday futurism that helps make the world I hope to see a little more
        tangible.
      </p>

      <p>
        <em>
          Photo by{" "}
          <a href="https://unsplash.com/@jamie452?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Jamie Street
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/stocks-market?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
          .
        </em>
      </p>
    </>,
    //post 4
    <>
      <p>
        Our pension assessments app will make it as easy as we can for you to
        check whether your current pension is green or not. So if it tells you
        your pension could be invested more sustainably, how do you make a
        change?
      </p>

      <p>
        To give you an example, here’s how I made my move to a greener workplace
        pension, shifting from the default to the green option within the Aviva
        pension scheme provided by my last employer. Not every scheme will work
        in the same way, but the process you would need to follow to adjust your
        pension should be quite similar.
      </p>

      <h2>Accessing your pension</h2>

      <p>
        First things first, you need to get access to your pension’s online
        account! That can take a little time. My access to my account had lapsed
        and I had to wait for new credentials to arrive in the post. This can
        take a while – I had to wait a few weeks, but hopefully you will get
        your details more quickly.
      </p>

      <p>
        Like many providers Aviva allows you to select from a set of
        pre-designed plans – investment strategies designed by fund
        professionals based on the number of years you have before retirement –
        or to choose your own funds. Aviva lets you construct your pension from
        a selection of some 230 funds, some managed by Aviva, some by
        third-parties.
      </p>

      <h2>Selling your existing funds</h2>

      <p>
        If you want to go ahead and make a change you’ll need to sell your
        holdings in your existing funds. This releases the money you will put in
        the funds that will comprise your new, greener pension. You don’t need
        to sell all the money in all of your existing funds, but you do need to
        sell something in order to free up sufficent resources to set up your
        new pension. The screenshot below shows the options Aviva provides –
        your provider will offer similar options.
      </p>

      <figure className="wp-block-image size-large">
        <img
          width="1500"
          height="1089"
          src={data[4].imgUrls[1]}
          alt=""
          className="wp-image-66"
          srcSet={`${data[4].imgUrls[1]} 1500w, ${data[4].imgUrls[1]} 480w, ${data[4].imgUrls[1]} 768w, ${data[4].imgUrls[1]} 1536w, ${data[4].imgUrls[1]} 1723w`}
          sizes="(max-width: 1500px) 100vw, 1500px"
        />
        <figcaption>
          Aviva options for selling the funds that comprise your existing
          pension
        </figcaption>
      </figure>

      <h2>Choosing the funds you want</h2>

      <p>
        Once you’ve sold some or all of your existing funds, you to choose the
        funds you want to switch to. Your options will look something like the
        screenshot below, with a search box and filter options allowing you to
        create a custom table of potential funds to review.
      </p>

      <figure className="wp-block-image size-large">
        <img
          loading="lazy"
          width="1500"
          height="985"
          src={data[4].imgUrls[1]}
          alt=""
          className="wp-image-67"
          srcSet={`${data[4].imgUrls[1]} 1500w,${data[4].imgUrls[1]} 480w, ${data[4].imgUrls[1]} 768w, ${data[4].imgUrls[1]} 1536w,${data[4].imgUrls[1]} 1923w`}
          sizes="(max-width: 1500px) 100vw, 1500px"
        />
        <figcaption>Aviva options for selecting funds</figcaption>
      </figure>

      <p>
        You’ll have to do a little bit of work to assess which funds are green –
        they won’t be highlighted in bright green text unfortunately! Start by
        opening the fund’s factsheet. This will list its top 10 holdings – the
        main stocks in which the fund is invested. Some of these companies will
        be familiar, but others not so much.{" "}
      </p>

      <p>
        So to get a better sense of the greenest options available to me I
        looked at what fund managers with good green reputations are doing: I
        searched for the names of green funds I was aware of, like BMO
        Responsible, Jupiter Ecology, Lionstrust and Aviva’s own Stewardship,
        and looked at their fund factsheets. Once I’d taken a note of that I was
        better able to choose the most sustainable Aviva funds. I selected four
        funds that I thought offered solid green options, and that had something
        like the geographic spread I was looking for.
      </p>

      <p>
        In following this process I’m well aware that I had the advantage of
        knowing how to compare the options that Aviva gave me with the funds
        that I know to be sustainable through past experience – as someone who
        works in finance and has become comfortable making my own financial
        decisions, I have made fund selections on other platforms before.
      </p>

      <p>
        So what do you do if you don’t know how to make that comparison? One
        thing you could do would be to list all 230 funds available on Aviva and
        then pick out those whose titles include green investment buzzwords like
        ‘ethical’, ‘sustainable’, ‘stewardship’, or ‘responsible’. You can also,
        of course, ask a professional adviser – your pension company should be
        able to give you some guidance. We plan to provide information on
        individual funds in a later version of the app so stay in touch!
      </p>

      <p>
        Once you have decided on your chosen funds, you will be asked to confirm
        your choices and your ‘allocation’ – the percentage of your savings you
        want assign to each fund. Your pension provider will take a few days to
        execute your choices and then send you a simple confirmation statement.
      </p>

      <h2>Checking your pension</h2>

      <p>
        You can login to your account any time to check your pension’s status.
        You will also get an annual statement through the post. Aviva’s
        statement lists my chosen funds and how much I have in each one.
        Unfortunately they aren’t labelled as clearly as they could be. ‘Series
        6 AvLionSFAbsolGrthS6’, for example, appears on mine: it’s not at all
        obvious what this is! One of our goals at Go Invest Green is to
        highlight the need for funds to be much more transparent. Things are
        slowly getting better though. Aviva for example offers a{" "}
        <a
          href="https://library.aviva.com/tridion/documents/view/in90132.pdf"
          data-type="URL"
          data-id="https://library.aviva.com/tridion/documents/view/in90132.pdf"
        >
          Stewardship Lifestyle Strategy
        </a>{" "}
        which presents a reasonably straightforward green alternative to their
        default pension plan.
      </p>

      <p>
        I hope this little summary of my experience with one of the big
        providers has helped indicate what’s involved in changing your pension
        within a typical workplace pension framework. Our pension assessment app
        looks at Aviva and nine other of the UK’s best known workplace pensions.
        But just to be clear, we can only provide information, not advise you on
        what to do personally.
      </p>

      <p>
        <em>
          Photo by{" "}
          <a href="https://unsplash.com/@dylu?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Jacek Dylag
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/crowd?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
          .
        </em>
      </p>
    </>,
    //post 5
    <>
      <p>
        Have you ever thought of yourself as an investor? You may not realise
        that every time you make a pension contribution you are actually
        investing in the stock market.{" "}
        <a href="https://retirement.fidelity.co.uk/employers-consultants/news-insights/global-retirement-survey/">
          A recent survey by Fidelity International
        </a>{" "}
        found that around half of UK respondents in the 20 to 34 age range don’t
        realise their retirement savings are invested.&nbsp;
      </p>

      <p>
        Thinking about your pension when you’re young is important. Retirement
        seems a long way off, and many of us think we’re never going to retire,
        but regardless of when you stop working your pension is the biggest
        investment of your life. And you can use it to help invest in an
        environmentally sustainable future as well as your financial
        security.&nbsp;
      </p>

      <p>
        Here are three reasons why you should use our forthcoming pensions
        assessment app to check if your pension is invested sustainably.
      </p>

      <h2>1. Setting goals for your future</h2>

      <p>
        Think of the reason why you’re investing and saving. You may be saving
        for an emergency fund, or planning for a house deposit. When it comes to
        retirement savings you actually may not know how much is being put aside
        by yourself, or your employer. That’s ok – a lot of people are not sure
        about this: around half of the 20 to 34 year olds surveyed by Fidelity
        International for example.
      </p>

      <p>
        You might want to ask yourself what sort of future your money is helping
        to create. You may want to use it travel the world some day – but what
        kind of world will be there for you to see? You may want to use it to
        spend more time gardening – but in what kind of weather will you be
        doing it? Our app will help you understand your options for ensuring
        your pension helps make a future world you will want to live in.
        <br />
        &nbsp;
      </p>

      <h2>2. Thinking like an investor</h2>

      <p>
        Let’s think about what you’re currently investing. When you were
        automatically enrolled into a pension plan by your employer you were
        probably put into what is called a ‘default investment’ scheme. These
        investments are very unlikely to be green.
      </p>

      <p>
        You could arrange a meeting with a financial advisor, or think about
        choosing your own funds. But this can be confusing for those who don’t
        know where to start.&nbsp;
      </p>

      <p>
        Go Invest Green can tell you what is the state of your current pension
        fund, and some of the green funds you could switch to. You may get a
        nice surprise: your pension may be good enough, with the default fund
        aligned to your values.
      </p>

      <h2>3. Securing good returns for you and the planet&nbsp;</h2>

      <p>
        Investing in sustainable assets secures returns at least as&nbsp; good
        as stocks that aren’t green. In fact, funds with good ‘Environmenal,
        Social and Governance’ (ESG) ratings{" "}
        <a href="https://www.theguardian.com/money/2020/jun/13/ethical-investments-are-outperforming-traditional-funds">
          have continually outperformed the market
        </a>{" "}
        over the past 10 years.
      </p>

      <p>
        To take one example, the value of the Danish energy company Orsted,
        which has switched to renewables from oil and gas, has soared in recent
        years. Think about the amazing growth prospects of all the new
        technologies being developed to power the transition to a sustainable
        world. If you choose your investments wisely you can fund a secure
        retirement and help green the planet.
      </p>

      <p>
        Go Invest Green can’t guarantee you’ll be rich – the value of shares can
        go up as well as down – but we’re here to help.&nbsp;Thinking about
        where your money is going is important. We are constantly tracking our
        spend and limits via online banking. So why wouldn’t we as Millennials
        or Gen Z think like this with our pension? Our pension app will be ready
        soon to help.
      </p>

      <p>
        <em>
          Photo by{" "}
          <a href="https://unsplash.com/@rominaveliz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Romina veliz
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/cafe?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
            Unsplash
          </a>
          .
        </em>
      </p>
    </>,
    //post 6
    <>
      <p>
        We’re delighted to announce that the first version of our first app is
        now available. Our workplace pensions assessment tool takes a careful
        look at the UK’s most common pension plans to assess just how green they
        are, to make it easier for you to decide whether you’re happy where your
        money is going, or whether you want to make a change –&nbsp;
        <a
          rel="noreferrer noopener"
          href="https://goinvestgreen.com/"
          target="_blank"
        >
          try it out today
        </a>
        !
      </p>

      <p>
        You’ll see that many workplace pensions still invest more than we would
        like in fossil fuels and other high-carbon sectors. But more and more
        are investing in the new technologies and industries that are building a
        new zero carbon economy.
      </p>

      <p>We’ve prepared a short video introducing the app:</p>

      <figure className="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
        <div className="wp-block-embed__wrapper">
          <iframe
            style={{ marginBottom: "1.5em" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/S6SqNLuK_Tw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            alloFullScreen
          ></iframe>
        </div>
      </figure>

      <p>
        As well as the extensive information about green pensions in the app
        we’re posting new content about all aspects of green finance on this
        blog, and our&nbsp;
        <a href="https://twitter.com/goinvestgreen">Twitter</a>&nbsp;and&nbsp;
        <a href="https://www.linkedin.com/company/go-invest-green/">LinkedIn</a>
        &nbsp;accounts.
      </p>

      <p>
        Thank you for your interest in Go Invest Green –{" "}
        <a
          href="https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&amp;form_id=b612b097043fe4f7cd3d6a0abf9c3079"
          target="_blank"
          rel="noreferrer noopener"
        >
          please let us know what you think about the app
        </a>
        ! This is just the first stage in our journey.
      </p>
    </>,
    //post 7
    <>
      <p>
        As a new member of the
        <Link to="/"> Go Invest Green</Link> team I was intrigued and eager to
        take a deep dive into the world of pensions.
      </p>

      <p>
        As a recent graduate I saw it as a great opportunity to get to grips
        with the different providers in the market, their offerings and how they
        match up to one another, before I start my career and choose a pension
        myself.&nbsp;
      </p>

      <p>
        Part of my degree included a year in industry, which offered a chance to
        gain insight into how organisations operate. The job included a
        workplace pension, which I paid into at the time, not really
        understanding where it was going or what it was being used for. So I
        decided to look into it.
      </p>

      <h2>Changing expectations</h2>

      <p>
        Research from the
        <a
          href="https://www.ons.gov.uk/peoplepopulationandcommunity/personalandhouseholdfinances/incomeandwealth/bulletins/pensionwealthingreatbritain/april2016tomarch2018"
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          Office for National Statistics
        </a>{" "}
        found that between 2016 and 2018 total private pension wealth in Great
        Britain was £6.1trn, accounting for 42% of all wealth in the country.
        Aggregate private pension wealth is an estimate of the value of all
        private pension wealth. These figures underline the power our pensions
        have, in terms of which organisations we invest in and the movement of
        financial capital.&nbsp;
      </p>

      <p>
        Many members of my generation want to act against the existential threat
        of climate change. This takes many forms, including changing food habits
        by reducing meat intake, limiting flights, and buying electric cars. A
        consumer study by
        <a
          href="https://www.conecomm.com/2017-cone-gen-z-csr-study-pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          Cone Communications
        </a>{" "}
        found Generation Z is the generation most likely (94%) to believe
        companies should address urgent social and environmental issues, with
        millennials narrowly behind (87%).
      </p>

      <p>
        So we should expect to see consumers demanding more from companies on
        how they’re tackling these urgent problems: those exhibiting
        transparency on where their customers’ money is going will attract
        interest from the growing customer base.
      </p>

      <p>
        The change in workforce mobility is another shift which may impact the
        future competitive landscape. The
        <a
          href="https://www.bls.gov/news.release/nlsoy.nr0.htm"
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          Bureau of Labour Statistics
        </a>{" "}
        in the US stated the average number of jobs held in a lifetime is 12.5
        for men and 12.1 for women. Many employees will have quite a few
        workplace pensions, or may choose to open a Self Invested Personal
        Pension, usually known as a SIPP. This allows users to consolidate
        multiple pensions onto one platform, aligned with their investment
        goals.
      </p>

      <h2>Making information transparent</h2>

      <p>
        My research has looked into ‘traditional’ pension providers, and some of
        the newer FinTech offerings. And there’s no clear path for customers
        seeking into investing sustainably.&nbsp; The constant mention of ESG
        funds was a common theme throughout.{" "}
        <a href="https://insights.goinvestgreen.com/2020/12/08/investing-to-save-the-planet-a-review-of-a-new-guide-to-the-world-of-green-finance/">
          But as we’ve discussed on this blog
        </a>
        , ESG is by no means synonymous with green investing.
      </p>

      <p>
        I found a clear difference in the user experience between the more
        traditional and FinTech pension providers. The latter’s websites are
        often better designed and more usable, usually minimising the amount of
        content, making their proposition easier to navigate. They promise a
        well-defined route for customers with clear socially responsible
        investing goals. On the other hand, long standing large workplace
        pension providers do often provide real depth and breadth of
        information. But information about their commitment to sustainable
        investment can be hard to find.
      </p>

      <p>
        The recent success of disrupters in the banking sector, like Starling,
        Monzo and Revolut, has forced high-street banks to offer more innovative
        products. Pension providers looking to ride the big wave of
        Environmental, Social and Governance (ESG) and green investments must
        make a clear and transparent proposition to customers.&nbsp;
      </p>

      <h2>Taking the time to do your own research</h2>

      <p>
        Right now ESG is something of a free for all. As we’ve discussed on this
        blog, there are no established industry standards in measuring
        sustainability. Recent research from
        <a
          href="https://www.hl.co.uk/news/articles/ftse-100-the-5-highest-esg-rated-companies"
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          Hargreaves Lansdown
        </a>{" "}
        includes British American Tobacco and Coca Cola among the top ESG
        providers in the FTSE 100. This is because they are considered ‘best in
        class’ companies in their field, with higher ESG scores than their
        peers. That’s something to be aware of when you’re checking out ESG
        investments.
      </p>

      <p>
        The rush to embrace ESG across all sectors is encouraging. But it really
        is essential to research each investment option to go beyond the spin
        and check it aligns with your goals.
      </p>

      <p>
        <em>
          Photo by{" "}
          <a
            href="https://unsplash.com/@micheile?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer noopener"
          >
            Micheile Henderson
          </a>{" "}
          on{" "}
          <a
            href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank"
            rel="noreferrer noopener"
          >
            Unsplash
          </a>
          .
        </em>
      </p>
    </>,
    //post 8
    <>
      <p>
        Just what is a ‘green’ pension? Simply put it is a pension that follows
        a green or sustainable investment strategy. In other words, your money
        is being put to productive purpose over destructive, short term uses. As
        green investment has gathered momentum over the past few years it has
        generated a confusing range of labels: sustainable, ethical,
        responsible, impact, positive. Then there are the acronyms: ESG
        (environmental, social and governance), SRI (socially responsible
        investment) , GBS (green bullshit) … ok, we made that last one up.
      </p>

      <p>
        To keep things clear we stick to the terms ‘green’ and ‘sustainable’.
        ‘Ethical’ investment is a wider term that can refer to laudable aims
        like improving conditions for workers, or avoiding tobacco or the arms
        trade. But our particular focus is to encourage pensions that will help
        speed the transition to a net zero economy, either by financing the
        green technologies of the future, or by avoiding the polluting
        industries of the past. These are what we call green pensions, as
        contrasted with ‘brown’ pensions, maintained with little or no
        consideration given to the sustainability of the companies in which they
        are invested.
      </p>

      <h2>Light green pensions</h2>

      <p>
        Some pensions are greener than others. ‘Light green’ pensions avoid
        investments in certain companies, but still hold investments across all
        economic sectors. A light green pension, for example, may well include
        holdings in some oil companies and other high carbon emitting firms. But
        those investments will be in what fund managers call ‘best in class’
        companies: those whose carbon footprint compares well with that of their
        peers. Many ESG funds take this approach, especially ‘passive’ ETFs
        (exchange traded funds) that automatically track a particular set of
        companies (in contrast to ‘active’ funds, managed by fund managers
        personally picking investments to buy and sell).
      </p>

      <p>
        Managers of these lighter green funds argue that by holding shares in
        carbon-generating companies they can exert pressure to make them go
        greener. But you should look into these claims carefully: many funds are
        guilty of ‘greenwashing’, claiming they are doing more than they
        actually are to encourage change. Look at their shareholder voting
        record as one sign of actions meeting nice words.
      </p>

      <h2>Dark green pensions</h2>

      <p>
        Dark green funds avoid carbon-generating sectors altogether, and
        prioritise impact investing: financing businesses developing green
        technologies and solutions. These include the now familiar sources of
        renewable energy like wind and solar farms, but also innovations like
        battery storage, cultivated meat, regenerative agriculture, and
        AI-driven energy efficiency solutions. Many of these companies are still
        establishing their commercial viability, so dark green investments tend
        to be speculative, best suited for professional or wealthy investors
        willing to take some losses across a portfolio. Once they survive and
        grow for a while, they are more likely to be available as market listed
        investments to pension fund managers.
      </p>

      <h2>A balanced green pension</h2>

      <p>
        Most green pensions, therefore, tend to be lighter in shade: after all
        fund managers have a primary responsibility to ensure the investments
        they manage secure a sufficient return for their customers in the long
        term. But the best green pensions are flexible, expanding to encompass
        green innovations as they mature, and their managers use their clout as
        significant shareholders to pressure the firms in which they hold a
        stake to continually go greener.
      </p>

      <p>
        Only a few of the workplace pensions we looked at offer a decent
        selection of green options, although, as we suggest in the tool, there
        are things you can do to push your pension towards an investment
        strategy that is at least light green.
      </p>

      <p>
        But right now the only way you can be sure your pension is dark green is
        by choosing the funds that comprise it yourself. If you have a pension
        from a previous job, or are starting your own personal pension, you can
        pick one of the greenest providers. Or you can start a Self-Invested
        Personal Pension – usually called a SIPP – that allows you to actually
        pick the funds that constitute your pension.
      </p>
    </>,
    //post 9
    <>
      <p>
        It’s important to know exactly what kind of pension you have if you want
        to change it. Here we look at the different types of workplace pensions,
        putting industry jargon into everyday language.
      </p>

      <p>
        There are two types of workplace pensions: ‘defined benefits’ (DB)
        pensions, where you know what you will get at the end, and ‘defined
        contributions’ (DC) pensions, where you know what you are putting in but
        not what your final pension will be.
      </p>

      <h3>Defined benefit (DB) pensions</h3>

      <p>
        If you have a defined benefits scheme, often referred to as a ‘final
        salary’ pension, you are one of the lucky ones. You will get a
        consistent, known income once you retire based on your final or average
        wage, and how long you worked for the company providing the pension.
      </p>

      <p>
        DB pensions were once common but are now closed to most new employees.
        If you have started working for a company within the last twenty years
        you are very unlikely to have a DB pension – companies say they are too
        expensive to fund.
      </p>

      <p>
        Despite these advantages, though, the majority of DB pensions were set
        up well before climate change became such a pressing political issue,
        and are very unlikely to be green. You can check out just how
        sustainable your DB pension is by contacting your HR department or the
        pension trustees (these people run the pension on behalf of all members
        and will include some employees).
      </p>

      <p>
        So if you want change you’ll have to ask for it. Unless, that is, you
        have a public sector DB pension. These don’t accumulate (build up your
        money) in a fund like private sector DB pensions – they are just paid
        for through general taxation.
      </p>

      <h3>Defined contributions (DC) pensions</h3>

      <p>
        With a DC scheme regular payments – or ‘contributions’ – are paid into
        your pension fund. The size of the pension you receive when you are
        entitled to draw it will depend on how much money has built up in the
        fund. This is why you need to keep an eye on how your pension is
        performing throughout your working life.
      </p>

      <p>
        Your employer helps you, topping up your own contributions to your fund,
        and the government gives tax relief on the payments you make – see
        ‘Could I lose out by switching to a green pension’ in the FAQ for more.
      </p>

      <h3>‘Master trust’ and ‘contract based’ DC pensions</h3>

      <p>
        Your inclusion in a DC pension scheme may well be due to the
        government’s auto-enrollment rules for workplace pensions, which have
        been gradually introduced since 2012 to ensure all employees are
        entitled to a pension. These are sometimes called ‘master trust’
        pensions because all participating companies are in the same basic plan.
        Other companies have their own ‘contract based’ pensions, although these
        tend to be quite similar to each other, usually ‘off the shelf’ schemes
        picked from a narrow range of big pension providers like Standard Life,
        Aviva, and Legal and General.
      </p>

      <p>
        If you started work recently, particularly for a small or new business,
        you are probably in a master trust scheme. Another sign is if you are
        paying 4% of your salary every month into your pension. Check by looking
        for the term ‘master trust’ in your pension documentation, or by asking
        your pension provider. Once you know that, you can ask what your pension
        is invested in, and what options you have for moving to greener options.
      </p>

      <h3>Other DC pensions</h3>

      <p>
        Other types of DC pensions, especially older ones with big corporate
        employers, don’t fall into the master trust schemes, but are often on a
        similar big pension brand platform. The problem is that there are a lot
        of different plans created over many years and it is confusing to work
        out which one you have. Others were created independently by the
        employer, usually now being administered by one of a few big third party
        providers, most often Towers Watson or JLT.
      </p>

      <p>
        For nearly all these pensions, you can get some access through an online
        portal. However you may still find it difficult to see where your money
        is invested. Often you will need to make a specific request to the
        pension trustees or the provider for this information. And when you do,
        unfortunately you may not like it much and find you don’t have good
        green options, especially if it is an older pension scheme. If so, ask
        them to consider investing in more sustainable funds. And like all
        employees you have the right to put yourself forward to be a trustee
        yourself!
      </p>
    </>,
    //post 10
    <>
      <p>
        If you’re not happy with your workplace pension you can start your own:
        but make sure you understand that you’ll be giving up the benefit of
        employer contributions.
      </p>

      <p>
        If having checked the status of your workplace pension, you’re not that
        impressed by how green it is, or whatever ‘greener’ alternative funds
        the provider may offer, why not just take control and switch to one of
        the leading green pension providers?
      </p>

      <p>
        Well you can. But it’s very important you realise that if you go ahead
        and make this major change you could be giving up the contributions to
        your current pension that your employer is making.
      </p>

      <p>
        Warning: If you have a{" "}
        <a
          href="https://insights.goinvestgreen.com/2021/05/02/working-out-what-kind-of-workplace-pension-you-have/"
          data-type="post"
          data-id="139"
        >
          Defined Benefits
        </a>{" "}
        pension, you will also miss out on other very important benefits. You
        will need to get advice from a qualified financial advisor if you want
        to proceed.
      </p>

      <h2>About employer contributions</h2>

      <p>
        Your employer has a legal obligation to contribute to your pension to
        ensure that 8% of your salary is paid into it each month. Your company
        must pay at least 3% of your monthly contribution – you pay the
        remainder, with the government helping a little as well through tax
        relief. Indeed some employers will put in more, topping up your
        contribution by more than 3%, or matching any additional contributions
        you decide to make. You can read the details on the&nbsp;
        <a href="https://www.gov.uk/workplace-pensions/what-you-your-employer-and-the-government-pay">
          Government’s Workplace Pensions website
        </a>
        .
      </p>

      <h2>Greening your workplace pension</h2>

      <p>
        Even if you’re not too impressed by the green credentials of your
        workplace pension there are things you can do to improve it without
        giving up on the advantages of employer contributions.
      </p>

      <p>
        You can switch to a greener option offered by the same provider – if
        available. Your employer will continue to contribute in exactly the same
        way as with your current option.
      </p>

      <p>
        Or you can open a personal pension and start paying into it alongside
        your workplace pension, thereby giving you access to the full range of
        sustainable pension plans on the market (but bear in mind personal
        pensions come with their own, sometimes higher, fees). You may even be
        able to transfer money from your workplace pension every so often –
        perhaps every three months – to your personal pension. You will need to
        check whether your workplace pension allows this, and, if so, whether
        there are any withdrawal fees. But if so, you can steadily take control
        of the pension money generated through your current job.
      </p>

      <p>
        Even if you’re really not at all happy with the green options your
        workplace pension offers, bear in mind that the momentum towards green
        investment is now so strong that even the slowest moving pension
        providers are recognising that they must change. So why not take action
        as we propose on this site to argue that they should move faster, before
        making a big switch away to another provider.
      </p>

      <p>
        Whatever you decide, before making a move we strongly suggest contacting
        the free&nbsp;
        <a href="https://www.pensionsadvisoryservice.org.uk/">
          Government Pensions Advisory Service
        </a>
        &nbsp;for good impartial advice.
      </p>
    </>,
    //post 11
    <>
      <p>
        Some workplace pensions give you some choice over the funds that
        comprise it: but you can also open up a pension of your own if you want
        complete freedom.
      </p>

      <h2>Greening a workplace pension</h2>

      <p>
        Some – although currently too few – pension providers allow you to
        choose from a selection of decent green funds within the terms of your
        current pension plan. Think of these as building blocks the provider’s
        investment advisers think will work well together. You may find it
        daunting to pick your own funds but they will provide guides and often a
        team to talk to. These curated funds tend to come from the same small
        group of established sustainable fund managers. They typically have
        similar holdings and offer similar returns, so you won’t lose out too
        much if you happen to choose one of the funds that performs slightly
        less well than the others.
      </p>

      <p>
        One of the Go Invest Green team, for example, has customised their Aviva
        pension by choosing from a set of well-regarded green funds from
        Liontrust, Jupiter, their own Stewardship range and other respected
        names.
      </p>

      <h2>Self-Invested Personal Pensions (SIPPs)</h2>

      <p>
        You can open a Self-Invested Personal Pension – a SIPP – to select the
        funds that make up your pension. This frees you to mix and match funds,
        trusts, bonds and direct holdings in individual companies as you wish.
      </p>

      <p>
        With great power comes great responsibility of course: you will be
        responsible for ensuring your portfolio is calibrated appropriately,
        with the right mix of assets according to your position in your own
        journey towards retirement. You’ll no longer benefit from the ‘lifestyle
        strategy’ built into a professionally managed pension, designed to move
        along a ‘glidepath’ as you move closer to retirement, gradually moving
        from shares to safer assets like bonds.
      </p>

      <p>
        That said, there’s plenty of advice out there to help you make your own
        decisions. If you’re prepared to do your own research you can often do
        better than the relatively cautious approach offered by off-the-shelf
        products: after all, no-one cares as much about your pension as you! For
        example, if retirement is a long way off, you can take control of your
        pension now, and possibly move it back to a managed lifestyle plan in
        the future, when most or all of them have been thoroughly greened.
      </p>

      <h2>Investment self-education</h2>

      <p>
        Whether you are choosing funds within the parameters of a professional
        managed pension, or making your own selections within the context of
        SIPP, you should look carefully at its past performance and its
        holdings. Funds are obliged by law to make available a factsheet listing
        the top 10 stocks of which it is comprised, its asset allocation (the
        relative mix of shares and bonds), its performance relative to the
        benchmark, and other useful information. The top 10 holdings will tell
        you a lot about just how green it is. You’ll find the factsheet on your
        pension platform, or just by looking for the fund name through a search
        engine.
      </p>

      <p>
        If you are serious about managing your own pension you will of course be
        motivated to do your own research on what funds to buy, and what mix of
        assets you should hold. You can find out a lot, for example, just by
        searching on terms like ‘best asset allocation for my age’. And by
        reading the excellent&nbsp;
        <a href="https://good-with-money.com/category/guides/">
          Good Investment Reviews on the Good with Money website
        </a>
        .
      </p>

      <p>
        Like the many others who do it, you will learn a lot about personal
        investment over time. You could start by taking control of just a
        proportion of your current pension funds as you take your first steps.
      </p>
    </>,
  ];
};
