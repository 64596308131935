/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import { Component } from "react";

class LeafCard extends Component {
  render() {
    return (
      <div
        sx={{
          width: ["auto", "100%", "100%"],
          backgroundColor: "green",
          borderRadius: "0 70px 0 70px",
          padding: "1.7em 1.7em 4em",
          color: "white",
          position: "relative",
          marginRight: [0, "1.5%", "3%"],
          marginLeft: [0, "1.5%", "3%"],
          "&:first-of-type": {
            marginLeft: 0,
          },
          "&:last-of-type": {
            marginRight: 0,
          },
          marginBottom: [20, 30, 0],
        }}
      >
        <h3
          sx={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            margin: "0.3em 0",
            padding: "0",
          }}
        >
          {this.props.title}
        </h3>
        <div
          sx={{
            fontSize: 1,
            letterSpacing: "0",
          }}
        >
          {this.props.text}
        </div>

        {this.props.linkHref && (
          <Link
            sx={{
              height: "40px",
              backgroundColor: "primaryCta",
              width: ["50%", "70%", "50%"],
              position: "absolute",
              bottom: "0",
              right: "0",
              borderRadius: "80px 0 0 0",
              color: "black",
              fontSize: "18px",
              textAlign: "center",
              lineHeight: "40px",
              verticalAlign: "middle",
              display: "block",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            href={this.props.linkHref}
          >
            {this.props.linkLabel}
          </Link>
        )}
      </div>
    );
  }
}

export default LeafCard;
