/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import { Themed } from "theme-ui";
import React, { Component } from "react";

import PensionsData from "./PensionsData.jsx";

import heroImage from "../../assets/heroImages/green-hills2.jpg";
import greenback from "../../assets/guideMeImages/greenback.jpg";

import SubscribeForm from "../../components/subscribeForm/SubscribeForm";
// import { navigate } from "@reach/router";
import BtnDownArrow from "../../components/BtnDownArrow.jsx";

class PersonalPensionsHome extends Component {
  constructor(props) {
    super(props);
    const storedState = window.localStorage.getItem("personal-pension-style");
    if (storedState) {
      var style = storedState;
    }

    // console.log(style);

    this.state = {
      style: style,
      styleCaption: null,
      pensionsOfStyle: [],
      personalPensionProvider: null,
    };

    if (this.state.style && !this.StyleCaptions[this.state.style])
      this.state.style = null;

    this.pensions = PensionsData;
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.style) {
      this.setStyle(this.state.style);
    }
    this.props.handleMinScrollValue(1300);
  }

  StyleCaptions = {
    diy: "DIY Provider",
    pensionProvider: "Pension Provider",
    robo: "Robo Provider",
  };

  setStyle = style => {
    const pos = this.pensionsOfStyle(style);
    const scs = this.StyleCaptions[style];
    if (!scs) return;

    this.setState({
      style: style,
      styleCaption: scs,
      pensionsOfStyle: pos,
    });

    window.localStorage.setItem("personal-pension-style", style);

    // navigate("#pension-style");
    this.scrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  setProvider = provider => {
    this.setState(...this.state, {
      style: this.style,
      styleCaption: this.StyleCaptions[this.style],
      personalPensionProvider: provider,
    });
  };

  showLogo = name => {
    return <img src={this.pensions[name].logo} alt={name} />;
  };

  showFees = name => {
    if (!this.pensions[name].fees) return;

    const res = [];
    for (const [key, value] of Object.entries(this.pensions[name].fees)) {
      res.push(
        <p key={key}>
          <b>{key}</b> = £{value}{" "}
        </p>
      );
    }
    return res;
  };

  showProviderCard = name => {
    return (
      <div
        key={name}
        sx={{
          variant: "components.plainPPProviderCard",
        }}
      >
        <div sx={{ variant: "components.personalPensionLogo" }}>
          {this.showLogo(name)}
        </div>
        <h2>{this.pensions[name].title}</h2>
        <p>{this.pensions[name].summary}</p>
        <h3>Fees at £10k and £100k</h3>
        <div>
          <p>{this.pensions[name].feeSummary}</p>
          {this.showFees(name)}
          <p>{this.pensions[name].feeNotes}</p>
        </div>
        <p>
          <Link
            onClick={() => this.setProvider(name)}
            href={this.providerLink(name)}
            sx={{ variant: "links.ctaPrimary" }}
          >
            Read More
          </Link>
        </p>
      </div>
    );
  };

  providerLink = name => {
    // console.log(this.state);
    return `/personal-pensions/${name}`;
  };

  pensionsOfStyle = style => {
    const ps = [];
    for (const [key, value] of Object.entries(this.pensions)) {
      if (value.style === style) {
        ps.push(this.showProviderCard(key));
      }
    }
    return ps;
  };

  styleIntro = style => {
    if (style === "robo")
      return (
        <div>
          <Themed.p>
            New digital services that use financial technology to manage your
            investments. They offer simple set-up and will help identify the
            level of investment risk that you are comfortable with. You then
            leave it to them to make investment decisions for you. However, not
            many Robo’s offer both a pension plan and a reasonable green or
            sustainable investment option.
          </Themed.p>
          <Themed.p>
            <strong>Good</strong> if you want ease of use, easy to understand
            costs and want to leave investment decisions to ‘the experts’
          </Themed.p>
          <Themed.p>
            <strong>Not so good</strong> if you want to take control of
            investments so that your money is invested exactly where you want it
            to be. Also not as cheap as going DIY. Or you may want the
            familiarity that comes with the big brand pension providers.
          </Themed.p>
        </div>
      );

    if (style === "diy")
      return (
        <div>
          <Themed.p>
            Investment platforms provided by a broad range of specialist online
            brokers, big financial institutions and established wealth managers.
            Some offer the lowest total cost for having your own pension - but
            you have to make the investment selections yourself. However we have
            included some that make this easier with decent tools and there are
            a lot of good online resources to help you do this.
          </Themed.p>
          <Themed.p>
            <strong>Good</strong> if you want to be able to choose investments
            that are more aligned with your goals and principles - for example
            high impact funds or specialist investments in say renewable energy,
            cleantech or sustainable agriculture. Often a great way to lose less
            of your pension pot to fees and other costs.
          </Themed.p>
          <Themed.p>
            <strong>Not so good</strong> if you are not comfortable or willing
            to learn how to make your own investment decisions. You will also
            need to set aside time to regularly review and tune up your
            portfolio.
          </Themed.p>
        </div>
      );

    if (style === "pensionProvider")
      return (
        <div>
          <Themed.p>
            Some big, long established firms of the UK Insurance and Pensions
            Sector now offer decent green investment plans and the costs can
            compare well to the new providers. We have also included one new
            challenger that should not be ignored.
          </Themed.p>
          <Themed.p>
            <strong>Good</strong> if you want to know your money is with well
            capitalised financial industry names that offer a simple investment
            plan that is green enough in our view to make this list. All-in
            costs from some providers are surprisingly competitive.
          </Themed.p>
          <Themed.p>
            <strong>Not so good</strong> if you want the control that comes with
            being able to make your own investments or are looking for ease of
            use or simple to understand information.
          </Themed.p>
        </div>
      );
  };

  render() {
    return (
      <div
        sx={{
          position: "relative",
          top: 0,
        }}
      >
        {/* Hero */}
        <div sx={{ variant: "components.hero" }}>
          <div
            sx={{
              variant: "components.hero.background",
              backgroundImage: `url(${heroImage})`,
            }}
          >
            <div
              sx={{
                pt: ["16%", "none"],
                margin: "0 10%",
                textAlign: "center",
              }}
            >
              <h1
                sx={{
                  variant: "components.hero.h1Secondary",
                }}
              >
                Personal Pensions
              </h1>
              <h2
                sx={{
                  variant: "components.hero.h2Secondary",
                }}
              >
                Want to find some ideas for a good, green personal pension,
                based on your financial style? Read on!
              </h2>
              <div
                sx={{
                  mt: "0.3em",
                  textAlign: "center",
                }}
              >
                <BtnDownArrow anchorUrl="#get-started" />
              </div>
            </div>
          </div>
        </div>

        {/* get Started */}
        <div id="get-started"></div>
        <div
          sx={{
            padding: "0",
            display: "flex",
            flexDirection: ["column", "column", "row"],
          }}
        >
          <div
            sx={{
              variant: "components.personalPensionInfo",
              backgroundImage: `url(${greenback})`,
            }}
          >
            <div>
              <h1>Finding a green persional pension</h1>
              <p>
                You may want to find a greener home for an existing personal
                pension. Or you may be thinking of starting one because you are
                self-employed or want to transfer an old workplace pension
                that’s up to no good. The good news is that choosing a green
                personal pension can ensure your money is working hard for your
                future and for our common sustainable future.
              </p>
              <p>
                But with a lot of different options out there, how can you find
                one that suits you? We guide you to some good personal pensions,
                based on factors like your financial confidence, desire for
                control, preference for big brands vs. new digital services, all
                at different cost levels.
              </p>
            </div>
          </div>

          <div
            sx={{
              variant: "components.personalPensionInfo2",
            }}
          >
            <div>
              <h1>Why does my pension matter?</h1>
              <p>
                Are you wondering how on earth your pension can affect the
                climate?
              </p>
              <p>
                Simply, the money in your pension is invested in companies and
                too often they are the companies with high carbon emissions and
                other damaging environmental practices.
              </p>
              <p>
                By choosing a pension provider that picks companies that
                practice sustainable business, you are using your pension
                savings to help build the new green economy. Nice and quite
                easy! Research from Nordea, EQ Investors and Make My Money
                Matter shows that this is a way to maximise our personal
                contribution to reducing carbon, with an even greater impact
                than reducing meat, car journeys and fossil fuel based home
                energy use.
              </p>
            </div>
          </div>
        </div>

        <div id="pension-styles"></div>
        {/* How does it work? */}
        <div sx={{ variant: "components.sectionSecondary" }}>
          <div
            sx={{
              mb: "4em",
              variant: "components.plainCard",
            }}
          >
            <Themed.h1 sx={{ marginTop: "0", textAlign: "center" }}>
              How to pick a personal pension
            </Themed.h1>
            <Themed.p>
              We all find making financial decisions difficult and we think it
              helps to find providers that match your ‘financial style’ -
              covering factors like big brand comfort versus new fintech user
              experience, prioritising cost or choice, and your interest and
              confidence to be actively involved in managing your investments.
            </Themed.p>
            <Themed.p>
              We have put our pension providers into three groups that we think
              fit different styles. Which style suits you?
            </Themed.p>
          </div>
          <div
            sx={{
              marginTop: "5%",
              marginBottom: "5%",
              marginLeft: ["0%", "0%"],
              marginRight: ["0%", "0%"],
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              sx={{
                mb: "1.5%",
                mx: "1%",
                filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
                py: ["1.6em", "2em", "2em"],
                px: ["0", "0", "0"],
                width: ["98%", "41%", "41%"],
              }}
            >
              <div
                sx={{
                  variant: "components.plainCard",
                }}
              >
                <div
                  sx={{
                    margin: "4%",
                  }}
                >
                  <Themed.h3
                    sx={{
                      fontWeight: "800",
                      marginBottom: "0.3em",
                      marginTop: "-0.3em",
                    }}
                  >
                    Robo Adviser
                  </Themed.h3>
                  <Themed.p sx={{ marginTop: "0" }}>
                    Simple pensions from the new automated investment managers.
                  </Themed.p>

                  <Themed.p>
                    <Link
                      sx={{ variant: "links.ctaPrimary" }}
                      onClick={() => this.setStyle("robo")}
                    >
                      Learn More
                    </Link>
                  </Themed.p>
                </div>
              </div>
              <div
                sx={{
                  variant: "components.plainCard",
                }}
              >
                <div
                  sx={{
                    margin: "4%",
                  }}
                >
                  <Themed.h3
                    sx={{
                      fontWeight: "800",
                      marginBottom: "0.3em",
                      marginTop: "-0.3em",
                    }}
                  >
                    DIY Providers
                  </Themed.h3>
                  <Themed.p sx={{ marginTop: "0" }}>
                    Use these platforms to take control of your pension and save
                    money
                  </Themed.p>

                  <Themed.p>
                    <Link
                      sx={{ variant: "links.ctaPrimary" }}
                      onClick={() => this.setStyle("diy")}
                    >
                      Learn More
                    </Link>
                  </Themed.p>
                </div>
              </div>

              <div
                sx={{
                  variant: "components.plainCard",
                }}
              >
                <div
                  sx={{
                    margin: "4%",
                  }}
                >
                  <Themed.h3
                    sx={{
                      fontWeight: "800",
                      marginBottom: "0.3em",
                      marginTop: "-0.3em",
                    }}
                  >
                    Pension Provider
                  </Themed.h3>
                  <Themed.p sx={{ marginTop: "0" }}>
                    Stick with well known pension brands with decent green plans
                  </Themed.p>
                  <Themed.p>
                    <Link
                      sx={{ variant: "links.ctaPrimary" }}
                      onClick={() => this.setStyle("pensionProvider")}
                    >
                      Learn More
                    </Link>
                  </Themed.p>
                </div>
              </div>
            </div>
            <div
              sx={{
                mb: "4.5%",
                mx: "1%",
                filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))",
                py: ["1.6em", "2em", "2em"],
                px: ["0", "0", "0"],
                width: ["98%", "55%", "55%"],
              }}
            >
              {/* Pick Your Pension */}
              <div id="pension-styles" ref={this.scrollRef}></div>
              <div sx={{ variant: "components.plainCard" }}>
                <Themed.h1
                  sx={{ paddingTop: "20px", mt: 0, textAlign: "center" }}
                >
                  {this.state.style
                    ? ` ${this.state.styleCaption}s`
                    : "Pick a style"}
                </Themed.h1>
                {this.styleIntro(this.state.style)}
                {this.state.style ? (
                  <Themed.p sx={{ textAlign: "center" }}>
                    <Link
                      sx={{ variant: "links.ctaPrimary" }}
                      href="#top-picks"
                    >
                      Top Picks
                    </Link>
                  </Themed.p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div id="top-picks"></div>
          {this.state.style ? (
            <div>
              <Themed.h1
                sx={{ mt: "0", pt: "0", pb: "0px", textAlign: "center" }}
              >
                Top Picks
              </Themed.h1>
              <div sx={{ variant: "components.pensionProviders" }}>
                {this.state.pensionsOfStyle}
              </div>

              <Themed.p sx={{ textAlign: "center", mb: "0" }}>
                <Link
                  sx={{ variant: "links.ctaPrimary" }}
                  href="#pension-styles"
                >
                  Back Up to Pension Styles
                </Link>
              </Themed.p>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {this.state.style ? (
          <div>
            <div sx={{ variant: "components.sectionPrimary" }}>
              <div>
                <Themed.h1 sx={{ textAlign: "center" }}>Our Approach</Themed.h1>
                <Themed.p>
                  We have looked at a range of pension providers offering
                  personal pension investing. Our approach is to only suggest
                  options that meet a minimum threshold for green investment
                  options, and to suggest larger, well known providers, as well
                  as less well known providers with particularly green options
                  or high impact approaches.
                </Themed.p>
                <Themed.p>
                  It's always important to consider the costs of any given
                  pension as high costs (also called fees and charges) can
                  dramatically reduce the amount of money you will have
                  available when you retire. However comparing these costs is
                  not easy as some are charging an ‘all-in’ fee while others
                  charge just for the platform and the actual investments have
                  their own costs. Some also have other costs e.g. a pension
                  admin fee. To try to make this simple, we show the cost at two
                  levels of investment, 10k and 100k and state whether this
                  includes the cost of the actual funds or investments.
                </Themed.p>
                <Themed.p>
                  <strong>
                    Finally, please bear in mind we are not providing advice or
                    recommending any of these pensions.
                  </strong>
                  We are simply trying to help you quickly find some good
                  options for a greener personal pension. Please make sure you
                  are comfortable that any pension you select meets your needs.
                  They all offer telephone / chat support if you are not sure
                  about any part of their service.
                </Themed.p>
              </div>
            </div>
            {/* Guidance */}
            <div sx={{ variant: "components.sectionSecondary" }}>
              <div
                sx={{
                  textAlign: "center",
                }}
              >
                <Themed.h1 sx={{ marginTop: "0" }}>
                  Additional Guidance
                </Themed.h1>
                <Themed.p>
                  Our website contains various information and resources that
                  you can have a look and read if you do not understand the
                  information you see here.
                </Themed.p>
                <div sx={{ lineHeight: 4.2 }}>
                  <Link
                    sx={{ variant: "links.ctaSecondary" }}
                    href="/workplace-pensions/methodology"
                  >
                    Our Methodology
                  </Link>
                  <Link
                    sx={{
                      variant: "links.ctaTerciary",
                    }}
                    href="https://insights.goinvestgreen.com/resources/"
                  >
                    Useful Resources
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Contact Us */}
        <div sx={{ variant: "components.sectionPrimary" }}>
          <SubscribeForm header="Keep In Touch" />
        </div>
      </div>
    );
  }
}

export default PersonalPensionsHome;
