import React from "react";

export default function PostAuthorHeader({ data }) {
  return (
    <header className="entry-header">
      <div className="entry-meta">
        <span className="byline">
          by{" "}
          <span
            className="author vcard"
            itemProp="author"
            itemType="https://schema.org/Person"
            itemScope=""
          >
            <a
              className="url fn n"
              href="https://insights.goinvestgreen.com/author/justin/"
              title="View all posts by Justin Reynolds"
              rel="author"
              itemProp="url"
            >
              <span className="author-name" itemProp="name">
                {data && data.author}
              </span>
            </a>
          </span>
        </span>
      </div>
    </header>
  );
}
