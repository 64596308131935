import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FaqHG from "./FaqHG";
import FaqWhatType from "./FaqWhatType";
import FaqCouldILose from "./FaqCouldILose";
import FaqCanIChoose from "./FaqCanIChoose";
import FaqIsItEasy from "./FaqIsItEasy";

//how do I add font styles to the accordion?

const Accordion = styled({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    color: "#3a3b3b",
    width: "90%",
    display: "block",
    margin: "0px auto",
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = styled({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = styled(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function FAQAccordian() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>How green can or should my pension be?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <FaqHG />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>What type of pension do I have?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <FaqWhatType />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            Could I lose out by switching to a greener pension?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <FaqCouldILose />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            Can I choose the funds that make up my pension myself?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <FaqCanIChoose />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Is it easy to manage your own savings?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <FaqIsItEasy />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
