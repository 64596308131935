/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import NextStepsHero from "../workplacePensions/NextStepsHero";
import { Component } from "react";
// import pensionActivistImg from "../../assets/gigImages/Cartoon Images/Pension Activist.png";
import SubscribeForm from "../subscribeForm/SubscribeForm";
import ReactMarkdown from "react-markdown";
import AppMarkdown from "../../data/markdowns/NestNS.md";
import AdditionalGuidance from "../../components/workplacePensions/AdditionalGuidance";

class NestNS extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;

    const subscribingTo = (
      <span>
        Subscribe to receive updates from <strong>Go Invest Green</strong>.
      </span>
    );

    return (
      <div sx={{ variant: "components.main" }}>
        <NextStepsHero text={"Nest"} text2="What next?" />
        <div sx={{ variant: "components.sectionNextSteps" }}>
          <div sx={{ variant: "components.nextStepsContainer" }}>
            <ReactMarkdown children={markdown} className="theSectionTextLeft" />
          </div>
        </div>

        <AdditionalGuidance block="Secondary" />
        <SubscribeForm
          origreqValue={`provider - ${this.props.usersPension}`}
          subscribingTo={subscribingTo}
        />
      </div>
    );
  }
}

export default NestNS;
