/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";

import ReactMarkdown from "react-markdown";
import AppMarkdown from "../../data/markdowns/ProviderUnknown.md";
import SubscribeForm from "../../components/subscribeForm/SubscribeForm";
import ContentHero from "../../components/pageComponents/ContentHero";

class ProviderUnknown extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;
    return (
      <div sx={{ variant: "components.main" }}>
        <ContentHero text={"Not sure who your pension provider is?"} />
        <div sx={{ variant: "components.sectionNextSteps" }}>
          <div sx={{ variant: "components.nextStepsContainer" }}>
            <ReactMarkdown children={markdown} className="theSectionTextLeft" />
          </div>
        </div>
        {/* Contact Us */}
        <div>
          <SubscribeForm header="Keep In Touch" />
        </div>
      </div>
    );
  }
}

export default ProviderUnknown;
