/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Component } from "react";
// import PageHeader from "../../components/TextBlocks/PageHeader";
// import SectionText from "../../components/TextBlocks/SectionText";
// import SectionHeader from "../../components/TextBlocks/SectionHeader";
import SubscribeForm from "../../components/subscribeForm/SubscribeForm";
import ContentHero from "../../components/pageComponents/ContentHero";

class ProviderNotListed extends Component {
  constructor(props) {
    super(props);
    this.state = { otherProviderName: null };
    this.otherProviderChanged = this.otherProviderChanged.bind(this);
  }

  otherProviderChanged(ev) {
    this.setState({ otherProviderName: ev.target.value });
  }

  render() {
    return (
      <div sx={{ variant: "components.main" }}>
        <ContentHero text={"Provider Not Listed?"} text2="Tell me more..." />
        <div sx={{ variant: "components.sectionPrimary" }}>
          <div
            sx={{
              variant: "components.overlayContainer",
            }}
          >
            <Themed.p>
              As a voluntary organisation we had to focus our efforts where they
              could have the biggest impact. We started by looking into the ten
              biggest workplace pension providers in the UK but of course there
              are many others.
            </Themed.p>

            <div sx={{ variant: "components.plainCard", textAlign: "center" }}>
              <Themed.h1>Keep In Touch</Themed.h1>
              <Themed.p>
                Let us know your pension provider and we'll keep you up to date
                on future developments of our apps and when information about
                your pension provider becomes available.
              </Themed.p>
              <div
                sx={{
                  variant: "components.subscribeForm.field",
                  width: ["auto", "60%", "40%"],
                  mx: "auto",
                }}
              >
                <input
                  type="text"
                  id="other-provider-field"
                  placeholder="pension provider name"
                  onChange={this.otherProviderChanged}
                />
              </div>
              <SubscribeForm
                header=""
                origreqValue={"provider - not listed"}
                otherprovValue={this.state.otherProviderName}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderNotListed;
