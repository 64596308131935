import React, { Component } from "react";
import "../stylesheets/Thankyou.css";
import PageHeader from "./TextBlocks/PageHeader";
import SectionText from "./TextBlocks/SectionText";
import thankyouImg from "../assets/gigImages/Cartoon Images/ThankYou.png";
import linkedin from "../assets/socialMediaIcons/linkedin.png";
import medium from "../assets/socialMediaIcons/medium.png";
import twitter from "../assets/socialMediaIcons/twitter.png";
import youtube from "../assets/socialMediaIcons/youtube.svg";

class Thankyou extends Component {
  render() {
    return (
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <div>
          <PageHeader
            text={"THANK YOU!!"}
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          />
          <SectionText
            text={
              "We will only use your email address to contact you with relevant information to help you green your current pension."
            }
          />
          <img src={thankyouImg} alt="thank you" className="thankyouImg" />
          <SectionText
            text={
              "In the meantime connect with us on social media and be a part of the Go Invest Green movement"
            }
          />
          <div className="socialMediaDiv">
            <a href="https://www.linkedin.com/company/go-invest-green/?viewAsMember=true">
              {" "}
              <img
                src={linkedin}
                className="TYsocialsImages"
                alt="linkedin"
              />{" "}
            </a>
            <a href="https://medium.com/go-invest-green">
              {" "}
              <img src={medium} className="TYsocialsImages" alt="medium" />{" "}
            </a>
            <a href="https://twitter.com/goinvestgreen?lang=en">
              {" "}
              <img
                src={twitter}
                className="TYsocialsImages"
                alt="twitter"
              />{" "}
            </a>
            <a href="https://twitter.com/goinvestgreen?lang=en">
              {" "}
              <img
                src={youtube}
                className="TYsocialsImages"
                alt="youtube"
              />{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Thankyou;
