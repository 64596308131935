/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import downArrowIcon from "../assets/fontawesome/arrow-down-solid.svg";

export default function BtnDownArrow({ anchorUrl }) {
  const style = {
    variant: "links.ctaPrimary",
    height: "2.5rem",
    backgroundColor: "primaryCta",
    width: "10rem",
    borderRadius: "30px",
    color: "black",
    fontSize: "18px",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: "bold",
  };
  return (
    <Link href={anchorUrl} sx={style} id="Homepage-down-arrow">
      <img
        sx={{
          mt: "0.15rem",
        }}
        src={downArrowIcon}
        alt={downArrowIcon}
      />
    </Link>
  );
}
