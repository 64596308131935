import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import "../../stylesheets/Burger.css";

function Burger() {
  const burgerList = [
    { text: "Home", urlCode: "/" },
    { text: "Investment Tools", urlCode: "/investment-tools" },
    { text: "Insights", urlCode: "/insights" },
    { text: "About Us", urlCode: "/about" },
    {
      text: "Contact",
      urlCode:
        "https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079",
    },
  ];
  const anchor = "right";
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <div className="burgerIcon">
        <MenuIcon onClick={toggleDrawer(anchor, true)} />
      </div>
      <Drawer
        sx={{
          zIndex: 30001,
        }}
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        variant="temporary"
      >
        <List className="burgerList">
          {burgerList.map(item => {
            if (item.urlCode[0] === "/") {
              return (
                <Link
                  to={item.urlCode}
                  key={item.urlCode}
                  className="burgerText"
                  onClick={toggleDrawer(anchor, false)}
                >
                  {item.text}
                </Link>
              );
            } else {
              return (
                <a
                  href={item.urlCode}
                  key={item.urlCode}
                  className="burgerText"
                  onClick={toggleDrawer(anchor, false)}
                  target={item.text === "Insights" ? "_blank" : ""}
                  rel="noreferrer"
                >
                  {item.text}
                </a>
              );
            }
          })}
        </List>
      </Drawer>
    </>
  );
}

export default Burger;
