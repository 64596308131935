/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";
import heroImage from "../../assets/heroImages/green-hills2.jpg";

class ProviderHero extends Component {
  render() {
    return (
      <div sx={{ variant: "components.hero" }}>
        <div
          sx={{
            variant: "components.hero.background",
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div
            sx={{
              pt: ["16vh", "24vh", "27vh"],
              margin: "0 10%",
              textAlign: "center",
            }}
          >
            <h1
              sx={{
                variant: "components.hero.h1Secondary",
              }}
            >
              {this.props.text}
            </h1>
            <h2
              sx={{
                variant: "components.hero.h2Secondary",
              }}
            >
              {this.props.text2}
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default ProviderHero;
