import React from "react";

function RightSidebar() {
  return (
    <div
      id="right-sidebar"
      className="widget-area sidebar is-right-sidebar"
      itemType="https://schema.org/WPSideBar"
      itemScope
    >
      <div className="inside-right-sidebar">
        <aside
          id="widget_sp_image-2"
          className="widget inner-padding widget_sp_image"
        >
          <h2 className="widget-title">Try our pensions app</h2>
          <a
            href="https://goinvestgreen.com/"
            target="_blank"
            rel="noreferrer"
            className="widget_sp_image-image-link"
            title="Try our pensions app"
          >
            <img
              width="1354"
              height="677"
              alt="Try our pensions app"
              className="attachment-full"
              style={{ maxWidth: "100%" }}
              srcSet="https://insights.goinvestgreen.com/wp-content/uploads/2021/01/irongate_flowers.jpeg 1354w, https://insights.goinvestgreen.com/wp-content/uploads/2021/01/irongate_flowers-480x240.jpeg 480w, https://insights.goinvestgreen.com/wp-content/uploads/2021/01/irongate_flowers-768x384.jpeg 768w"
              sizes="(max-width: 1354px) 100vw, 1354px"
              src="https://insights.goinvestgreen.com/wp-content/uploads/2021/01/irongate_flowers.jpeg"
            />
          </a>
          <div className="widget_sp_image-description">
            <p>
              Our workplace pensions app makes it easy for you to see how green
              your pension is - we look at ten of the UK's most common pensions
              -{" "}
              <a
                href="https://goinvestgreen.com/"
                target="_blank"
                rel="noreferrer"
              >
                try it today »
              </a>
            </p>
          </div>
        </aside>
        <aside
          id="recent-posts-2"
          className="widget inner-padding widget_recent_entries"
        >
          <h2 className="widget-title">Recent posts</h2>
          <ul>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/05/02/can-i-design-my-own-pension/">
                Can I design my own pension?
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/05/02/could-i-lose-out-by-switching-to-a-greener-pension/">
                Could I lose out by switching to a greener pension?
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/05/02/working-out-what-kind-of-workplace-pension-you-have/">
                Working out what kind of workplace pension you have
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/05/02/light-and-dark-green-pensions-and-everything-in-between/">
                Light and dark green pensions – and everything in between
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/03/30/looking-beyond-green-hype-why-you-should-do-your-own-pensions-research/">
                Looking beyond green hype: why you should do your own pensions
                research
              </a>
            </li>
          </ul>
        </aside>
        <aside id="archives-3" className="widget inner-padding widget_archive">
          <h2 className="widget-title">Posts by month</h2>
          <ul>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/05/">May 2021</a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/03/">
                March 2021
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/02/">
                February 2021
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2021/01/">
                January 2021
              </a>
            </li>
            <li>
              <a href="https://insights.goinvestgreen.com/2020/12/">
                December 2020
              </a>
            </li>
          </ul>
        </aside>
      </div>
    </div>
  );
}

export default RightSidebar;
