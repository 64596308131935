import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import AppMarkdown from "../../../data/markdowns/FaqCouldILose.md";
import "../../../stylesheets/TextBlocks/SectionText.css";

class FaqCouldILose extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;

    return (
      <div style={{ width: "95%" }}>
        <ReactMarkdown children={markdown} className="theSectionTextFAQ" />
      </div>
    );
  }
}

export default FaqCouldILose;
