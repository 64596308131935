import React, { Component } from "react";
import "../../stylesheets/TextBlocks/SectionText.css";

class SectionText extends Component {
  render() {
    return this.props.left === true ? (
      <div className="theSectionTextLeft" style={this.props.style}>
        {this.props.text}
      </div>
    ) : (
      <div className="theSectionTextCenter" style={this.props.style}>
      {this.props.text}
    </div>
    );
  }
}

export default SectionText;
