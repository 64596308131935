/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";

import ReactMarkdown from "react-markdown";
import ContentHero from "../components/pageComponents/ContentHero";
import AppMarkdown from "../data/markdowns/Disclaimer.md";

class Disclaimer extends Component {
  constructor() {
    super();
    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then(res => res.text())
      .then(text => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;

    return (
      <div sx={{ variant: "components.main" }}>
        <ContentHero text={"Disclaimer"} />
        <div sx={{ variant: "components.sectionNextSteps" }}>
          <div sx={{ variant: "components.nextStepsContainer" }}>
            <ReactMarkdown children={markdown} className="theSectionTextLeft" />
          </div>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
