/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Component } from "react";
import { Themed } from "theme-ui";
import SectionText from "../components/TextBlocks/SectionText";
import { Button } from "theme-ui";

class OurAims extends Component {
  render() {
    return (
      <div>
        <p sx={{ textAlign: "center" }}>
          <Themed.h3 as="span">Our Aims</Themed.h3>
        </p>
        <SectionText
          text={
            "Do you know just how powerful your pension can be in driving the transition to a green economy?"
          }
          left={true}
        />
        <SectionText
          text={
            "Whether we know how our money is used or not, our savings shape our world."
          }
          left={true}
        />
        <SectionText
          text={
            "Money we think of as sitting quietly in the bank, or accumulating patiently in a pension fund, is actually hyperactive, invested and reinvested by the finance professionals who manage it."
          }
          left={true}
        />
        <SectionText
          text={
            "Unless we take the time to understand how our savings are being used they may well be working against rather than for a green future."
          }
          left={true}
        />
        <SectionText
          text={"Helping you see where your money is going"}
          style={{ fontWeight: "bold" }}
          left={true}
        />
        <SectionText
          text={
            "But pensions are complicated, and it isn’t easy to know how to make a change."
          }
          left={true}
        />
        <SectionText
          text={
            "Go Invest Green is here to help. We’ve taken a careful look at the UK’s most common pension plans to assess just how green they are, to make it easier for you to decide whether you’re happy where your money is going, or whether you want to make a change."
          }
          left={true}
        />
        <SectionText
          text={
            "You’ll see that many workplace pensions still invest more than you’d like in fossil fuels and other high-carbon sectors. But more and more are investing in the new technologies and industries that are building a new zero carbon economy."
          }
          left={true}
        />
        <SectionText
          text={"Try it out today"}
          style={{ fontWeight: "bold" }}
          left={true}
        />
        <SectionText
          text={
            "Our app gives you the information you need to assess whether you think your pension is green enough, and handy tips if you want to make a switch."
          }
          left={true}
        />
        <SectionText
          text={
            "Visit The Power of Green Pensions to find out more about investing to save the planet. Or, try the Go Invest Green app now:"
          }
          left={true}
        />
        <Button
          sx={{ display: "block", margin: "auto", mt: 3 }}
          onClick={() => (window.location = "/pensions")}
        >
          Get started now
        </Button>
      </div>
    );
  }
}

export default OurAims;
