/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "theme-ui";
import { Link as RouterLink } from "react-router-dom";

import { Themed } from "theme-ui";
import { useEffect } from "react";

import heroImage from "../assets/investmentTools/tools.jpg";
import touchApp from "../assets/workplacePensions/touch_app.svg";
import personSignpost from "../assets/workplacePensions/person_signpost.svg";
import thumbsUpDown from "../assets/workplacePensions/thumbs_up_down.svg";
import guideMeGreener from "../assets/workplacePensions/guide_me_greener.svg";

import SubscribeForm from "../components/subscribeForm/SubscribeForm";

import "../stylesheets/InvestmentTools.css";
const InvestmentTools = ({ handleMinScrollValue }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    handleMinScrollValue(1520);
  }, [handleMinScrollValue]);

  return (
    <div
      className="InvestmentTools"
      sx={{
        position: "relative",
        top: 0,
      }}
    >
      {/* Hero */}
      <div sx={{ variant: "components.hero" }}>
        <div
          sx={{
            variant: "components.hero.background",
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div
            sx={{
              pt: ["16%", "none"],
              margin: "0 10%",
              textAlign: "center",
            }}
          >
            <h1
              sx={{
                variant: "components.hero.h1Secondary",
              }}
            >
              Tools to help you green your investments
            </h1>
          </div>
        </div>
      </div>

      {/* Our Tools */}
      <div sx={{ variant: "components.sectionPrimary" }}>
        <div
          sx={{
            variant: "components.overlayContainer",
          }}
        >
          <div sx={{}}>
            <div
              sx={{
                variant: "components.plainCard",
                display: "flex",
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                }}
              >
                <img src={touchApp} alt={touchApp}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Workplace Pensions
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  Most employees will have a workplace pension. See what you can
                  do to make it green.
                </Themed.p>
                <div sx={{ mt: "0.3em", textAlign: "center" }}>
                  <Link
                    as={RouterLink}
                    sx={{
                      variant: "links.ctaPrimary",
                    }}
                    to="/workplace-pensions"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>

            <div
              sx={{
                variant: "components.plainCard",
                display: "flex",
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                }}
              >
                <img src={thumbsUpDown} alt={thumbsUpDown}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Personal Pensions
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  Currently self employed, or have a group personal pension from
                  a previous employer? See if keeping it or moving it makes most
                  sense.
                </Themed.p>
                <div sx={{ mt: "0.3em", textAlign: "center" }}>
                  <Link
                    as={RouterLink}
                    sx={{
                      variant: "links.ctaPrimary",
                    }}
                    to="/personal-pensions/home"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            {/* Added section for guide me greener link */}
            <div
              sx={{
                variant: "components.plainCard",
                display: "flex",
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                }}
              >
                <img src={guideMeGreener} alt={guideMeGreener}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  Guide me greener
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  Not sure how to go about making your investments more
                  sustainable? Answer a few short questions so we can advise you
                  how best to do this.
                </Themed.p>
                <div sx={{ mt: "0.3em", textAlign: "center" }}>
                  <Link
                    as={RouterLink}
                    sx={{
                      variant: "links.ctaPrimary",
                    }}
                    to="/guide-me-greener"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div
              sx={{
                variant: "components.plainCard",
                display: "flex",
              }}
            >
              <div
                sx={{
                  variant: "components.iconBlock",
                }}
              >
                <img src={personSignpost} alt={personSignpost}></img>
              </div>
              <div
                sx={{
                  marginLeft: "4%",
                }}
              >
                <Themed.h4
                  sx={{
                    fontWeight: "500",
                    marginBottom: "0.3em",
                    marginTop: "-0.3em",
                  }}
                >
                  More coming soon
                </Themed.h4>
                <Themed.p sx={{ marginTop: "0" }}>
                  We have plenty more tools in the pipeline. Let us know if
                  there is something you are looking for.
                </Themed.p>
                <div sx={{ mt: "0.3em", textAlign: "center" }}>
                  <Link
                    sx={{
                      variant: "links.ctaPrimary",
                    }}
                    href="https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div>
        <SubscribeForm header="Keep In Touch" />
      </div>
    </div>
  );
};

export default InvestmentTools;
