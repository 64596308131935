/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Themed, Link } from "theme-ui";
import { Component } from "react";
import ContentHero from "../../components/pageComponents/ContentHero";
import PicCard from "../../components/pageComponents/PicCard.jsx";
import piggyBank from "../../assets/workplacePensions/piggy-bank.svg";
import mountains from "../../assets/workplacePensions/mountains.svg";

class OurMethodology extends Component {
  goToContact() {
    window.location.href =
      "https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079";
    // window.open(
    //   "https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079"
    // )
  }

  render() {
    return (
      <div sx={{ variant: "components.main" }}>
        <ContentHero
          text={"Our Methodology"}
          text2="We want to give you some insight into how we compiled the assessments in this app."
        />
        <div sx={{ variant: "components.sectionPrimary" }}>
          <div
            sx={{
              variant: "components.overlayContainer",
            }}
          >
            <div sx={{ mb: "4em" }}>
              <Themed.p>
                We have started with ten of the most common workplace pensions
                based on the selection in the Good Guide to Pensions from Good
                With Money. Our use of the Guide ensures that our pensions
                assessment could be validated against this is strong independent
                research - we highly recommend that you read it too.
              </Themed.p>
              <Themed.p>
                The Go Invest Green app highlights the same set of pensions as
                the Guide. But we’ve also done our own research, looking
                carefully at the underlying investments that make up each
                pension, where this information is made available. We’ve studied
                the default plans for each pension, and any alternative plans
                they offer.
              </Themed.p>
              <Themed.p>
                We plan to cover more pensions in the future too. Contact us if
                your pension provider is not on the list.
              </Themed.p>
            </div>

            <div sx={{ variant: "components.plainCard" }}>
              <Themed.h2 sx={{ fontWeight: "bold" }}>
                Passing on what we’ve found out
              </Themed.h2>
              <Themed.p>
                We’re not fund managers, but we are financially literate
                individuals who have thought hard about how best to green our
                own pensions and savings, and are glad to share so that others
                might do the same. We’ve used our experience to assess the
                information each of the pension plans we look at makes available
                about where they invest. You can find that information yourself
                on their websites - but it isn’t always easy to interpret!
                That’s why we think you’ll find Go Invest Green useful.
              </Themed.p>
            </div>

            <div sx={{ variant: "components.sectionInOverlay" }}>
              <Themed.h2 sx={{ fontWeight: "bold", textAlign: "center" }}>
                Our Ratings Criteria
              </Themed.h2>
              <Themed.p sx={{ textAlign: "center" }}>
                We’ve categorised our assessments using a simple traffic light
                system that rates each pension according to:
              </Themed.p>
              <div sx={{ variant: "components.columnedCards" }}>
                <PicCard
                  imageSrc={piggyBank}
                  title="Transparency"
                  text="how easy it is to find out where the funds are invested"
                ></PicCard>
                <PicCard
                  imageSrc={mountains}
                  title="Green"
                  text="how sustainable the funds that constitute it are, from a perspective of achieving a net zero economy"
                ></PicCard>
              </div>
            </div>

            <div sx={{ variant: "components.plainCard" }}>
              <Themed.h2 sx={{ fontWeight: "bold" }}></Themed.h2>

              <Themed.h3>Transparency Rating</Themed.h3>

              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingRed",
                    }}
                  >
                    Red
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Provides no meaningful information publicly on their
                  sustainable investment strategy or actual holdings
                </Themed.p>
              </div>
              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingAmber",
                    }}
                  >
                    Amber
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Declares a sustainable investment strategy. Provides
                  information on how this is implemented in the default plan and
                  for any ethical/ sustainable option
                </Themed.p>
              </div>
              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingGreen",
                    }}
                  >
                    Green
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Default plan investment holdings are disclosed or specific
                  information on sustainable investment decisions. Clear
                  information provided on green alternative investment options
                </Themed.p>
              </div>
            </div>

            <div sx={{ variant: "components.plainCard" }}>
              <Themed.h3>Green Rating</Themed.h3>

              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingRed",
                    }}
                  >
                    Red
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Does not screen out companies for environmental reasons",
                </Themed.p>
              </div>
              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingAmber",
                    }}
                  >
                    Amber
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Screens out companies which are not actively working to adjust
                  their business models to progressively reduce climate impact
                  and meet the Paris Agreement goals of Net Zero.
                </Themed.p>
              </div>
              <div sx={{ display: ["block", "flex", "flex"] }}>
                <Themed.p>
                  <span
                    sx={{
                      variant: "components.ratingIndicatorWide",
                      backgroundColor: "ratingGreen",
                    }}
                  >
                    Green
                  </span>
                </Themed.p>
                <Themed.p
                  sx={{ width: ["auto", "auto", "80%"], marginLeft: "3em" }}
                >
                  Only invests in companies actively pursuing a net zero
                  emissions strategy in accordance with the Paris Agreement. Is
                  fossil fuel free or has ensured any included FF company has
                  realistic transition plans in place and being acted on
                </Themed.p>
              </div>
            </div>
          </div>
        </div>
        <div sx={{ variant: "components.sectionSecondary" }}>
          <Themed.h1>Get In Touch</Themed.h1>

          <Themed.p>
            If you would like further information about our analysis and
            assessment of workplace pensions, please send us a request:
          </Themed.p>

          <Themed.p sx={{ textAlign: "center" }}>
            <Link
              sx={{ variant: "links.ctaPrimary" }}
              href="https://us7.list-manage.com/contact-form?u=629a9a0beb810d2f41477d1fd&form_id=b612b097043fe4f7cd3d6a0abf9c3079"
            >
              Contact Us
            </Link>
          </Themed.p>
        </div>
      </div>
    );
  }
}

export default OurMethodology;
