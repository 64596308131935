import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import theme from "./theme";
import HomePage from "./pages/HomePage.jsx";
import About from "./pages/About.jsx";
import WorkplacePensionsHome from "./pages/workplacePensions/WorkplacePensionsHome.jsx";
import GuideMe from "./pages/guideMe/GuideMe.jsx";
import InvestmentTools from "./pages/InvestmentTools.jsx";
import PersonalPensionsHome from "./pages/personalPensions/PersonalPensionsHome.jsx";
import PersonalPensionDetails from "./pages/personalPensions/ProviderDetails.jsx";
import ThatPensionPage from "./pages/workplacePensions/ThatPensionPage.jsx";
import NextSteps from "./components/nextSteps/NextSteps.jsx";
import Thankyou from "./components/Thankyou.jsx";
import ProviderNotListed from "./pages/workplacePensions/ProviderNotListed.jsx";
import ProviderUnknown from "./pages/workplacePensions/ProviderUnknown.jsx";
import OurAims from "./pages/OurAims.jsx";
import Disclaimer from "./pages/Disclaimer.jsx";
import FAQ from "./components/burgerComponents/FaqComponents/FAQ.jsx";
import UsefulResources from "./pages/UsefulResources";
import OurMethodology from "./pages/workplacePensions/OurMethodology.jsx";
import WhyGoGreener from "./pages/WhyGoGreener.jsx";
import ComingSoon from "./components/burgerComponents/ComingSoon.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import AvivaNS from "./components/nextSteps/AvivaNS";
import NestNS from "./components/nextSteps/NestNS";
import NowNS from "./components/nextSteps/NowNS";
import RoyalLondonNS from "./components/nextSteps/RoyalLondonNS";
import TppNS from "./components/nextSteps/TppNS";
import PrudentialNS from "./components/nextSteps/PrudentialNS";
import ScottishWidowsNS from "./components/nextSteps/ScottishWidowsNS";
import LegalAndGeneralNS from "./components/nextSteps/LegalAndGeneralNS";
import StandardLifeNS from "./components/nextSteps/StandardLifeNS";
import { pensionData } from "./data/pensionData.js";
import Layout from "./components/Layout";
import InsightsHome from "./pages/insights/InsightsHome";
import { blogData } from "./data/insights/blogData";
import PostsList from "./pages/insights/PostsList";
import PostContent from "./pages/insights/PostContent";
import Resources from "./pages/insights/Resources";

function App() {
  const [usersPension, setUsersPension] = useState("");
  const [scrollValue, setScrollValue] = useState(window.scrollY);
  const [minScrollValue, setMinScrollValue] = useState(720);
  const [minScrollStringVal, setMinScrollStringVal] = useState(null);

  const addScrollEvent = useCallback(() => {
    window.addEventListener("scroll", () => {
      setScrollValue(window.scrollY);
    });
  }, [setScrollValue]);

  const PensionSelector = pension => {
    setUsersPension(pension);
  };

  const handleMinScrollValue = useCallback(
    (min, str) => {
      setMinScrollValue(min);
      setMinScrollStringVal(str);
    },
    [setMinScrollValue, setMinScrollStringVal]
  );

  useEffect(() => {
    addScrollEvent();
  }, [addScrollEvent]);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        scrollValue={scrollValue}
        minScrollValue={minScrollValue}
        minScrollStringVal={minScrollStringVal}
        setMinScrollValue={setMinScrollValue}
      >
        <Routes>
          <Route
            path="/"
            element={<HomePage handleMinScrollValue={handleMinScrollValue} />}
          />
          <Route
            path="/about"
            element={<About handleMinScrollValue={handleMinScrollValue} />}
          />

          <Route
            path="/workplace-pensions"
            element={
              <WorkplacePensionsHome
                handleMinScrollValue={handleMinScrollValue}
              />
            }
          >
            <Route
              path=":pension"
              element={
                <ThatPensionPage
                  pensionData={pensionData}
                  PensionSelector={PensionSelector}
                />
              }
            />
          </Route>

          <Route
            path="/guide-me-greener"
            element={<GuideMe handleMinScrollValue={handleMinScrollValue} />}
          />
          <Route
            path="/investment-tools"
            element={
              <InvestmentTools handleMinScrollValue={handleMinScrollValue} />
            }
          />
          <Route
            path="/workplace-pensions/provider-not-listed"
            element={<ProviderNotListed />}
          />
          <Route
            path="/workplace-pensions/provider-unknown"
            element={<ProviderUnknown />}
          />
          <Route
            path="/workplace-pensions/aviva/next-steps"
            element={<AvivaNS />}
          />
          <Route
            path="/workplace-pensions/nest/next-steps"
            element={<NestNS />}
          />
          <Route
            path="/workplace-pensions/royalLondon/next-steps"
            element={<RoyalLondonNS />}
          />
          <Route
            path="/workplace-pensions/tpp/next-steps"
            element={<TppNS />}
          />
          <Route
            path="/workplace-pensions/now/next-steps"
            element={<NowNS />}
          />
          <Route
            path="/workplace-pensions/prudential/next-steps"
            element={<PrudentialNS />}
          />
          <Route
            path="/workplace-pensions/scottishWidows/next-steps"
            element={<ScottishWidowsNS />}
          />
          <Route
            path="/workplace-pensions/legalAndGeneral/next-steps"
            element={<LegalAndGeneralNS />}
          />
          <Route
            path="/workplace-pensions/standardLife/next-steps"
            element={<StandardLifeNS />}
          />
          <Route
            path={`/workplace-pensions/${usersPension}/next-steps`}
            element={<NextSteps usersPension={usersPension} />}
          />
          <Route path="/workplace-pensions/thank-you" element={<Thankyou />} />
          <Route
            path="/personal-pensions/home"
            element={
              <PersonalPensionsHome
                handleMinScrollValue={handleMinScrollValue}
              />
            }
          />

          <Route
            path="/personal-pensions/:personalPensionProvider"
            element={<PersonalPensionDetails />}
          />
          {/* Stuff that's accessible via Burger Icon */}
          <Route path="/our-aims" element={<OurAims />} />
          <Route element={<Disclaimer path="/disclaimer" />} />
          {/* eslint-disable-next-line */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/useful-resources" element={<UsefulResources />} />
          <Route
            path="/workplace-pensions/methodology"
            element={<OurMethodology />}
          />
          <Route path="/why-go-greener" element={<WhyGoGreener />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<ComingSoon path="coming-soon" />} />

          {/* Go Invest Green Insights */}
          <Route
            path="/insights"
            element={
              <InsightsHome
                handleMinScrollValue={handleMinScrollValue}
                data={blogData()}
              />
            }
          >
            <Route
              path=""
              element={<PostsList data={blogData().reverse()} />}
            />
            <Route
              path="blog/:id"
              element={<PostContent data={blogData()} />}
            />
            <Route
              path="resources"
              element={<Resources data={blogData()} handleMinScrollValue />}
            />
          </Route>
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
