/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import HeroComponent from "../../components/insightsComponents/HeroComponent";
import ContentArea from "../../pages/insights/ContentArea";
import RightSidebar from "../../components/insightsComponents/RightSidebar";

import "../../stylesheets/insights/InsightsHome.css";

function InsightsHome({ handleMinScrollValue, data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams().id;
  useEffect(() => {
    handleMinScrollValue(null, "bg-only");
    window.scrollTo({ top: 0 });
  }, [handleMinScrollValue, navigate, params]);

  return (
    <div
      sx={{
        paddingTop: "150px",
      }}
      className="InsightsHome home blog wp-custom-logo wp-embed-responsive post-image-above-header post-image-aligned-center generate-columns-activated sticky-menu-fade right-sidebar nav-float-right separate-containers header-aligned-left dropdown-hover"
    >
      <HeroComponent params={params} pathname={pathname} data={data} />
      <main id="page" className="site grid-container container hfeed">
        <div id="content" className="site-content">
          <ContentArea />
          <RightSidebar />
        </div>
      </main>
    </div>
  );
}

export default InsightsHome;
